import {
  Box,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography,
  Skeleton,
} from '@mui/material';
import {
  AutoAwesome,
  AutoFixHigh,
  CreditCard,
  Flare,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { blue, indigo, cyan, teal, purple, grey } from '@mui/material/colors';
import {
  useGetSelfQuery,
  useLazyGetCheckoutQuery,
  useLazyGetCustomerPortalQuery,
} from 'graphql/generated';
import { useMemo } from 'react';
import { OrganisationPaymentStatus } from 'types/enums';
import mixpanel from 'mixpanel-browser';
import { useOrganisation } from 'use/organisation';

export default function Billing() {
  const [getCheckout, { isLoading: isLoadingCheckout }] =
    useLazyGetCheckoutQuery();
  const [getCustomerPortal, { isLoading: isLoadingCustomerPortal }] =
    useLazyGetCustomerPortalQuery();
  const { selectedOrganisation } = useOrganisation();
  const { data } = useGetSelfQuery({
    input: { organisationID: selectedOrganisation },
  });
  const user = useMemo(() => data?.getSelf?.user[0]?.user, [data]);
  const organisation = useMemo(
    () => data?.getSelf?.user[0]?.organisation,
    [data],
  );

  const paymentStatus = organisation?.paymentStatus;

  const isCurrent = paymentStatus === OrganisationPaymentStatus.active;
  const isOverdue = paymentStatus === OrganisationPaymentStatus.past_due;
  const isTrial = paymentStatus === OrganisationPaymentStatus.free;
  const { firstName } = user ?? {};
  const loading = !paymentStatus;
  const background = loading
    ? grey[700]
    : isCurrent
      ? `linear-gradient(45deg, ${indigo[700]}, ${blue[800]})`
      : isOverdue
        ? `linear-gradient(45deg, ${purple[700]}, ${indigo[800]})`
        : `linear-gradient(45deg, ${cyan[700]}, ${teal[400]})`;
  const chipLabel = isCurrent
    ? 'Active Subscription'
    : isOverdue
      ? 'Payment Overdue'
      : isTrial
        ? 'Trial Plan'
        : 'Free Plan';
  const chipColor = isCurrent
    ? indigo[200]
    : isOverdue
      ? purple[200]
      : teal[900];
  const message = isCurrent
    ? `You're a pro${firstName ? `, ${firstName}` : ''}`
    : isOverdue
      ? `Your payment is overdue${firstName ? `, ${firstName}` : ''}`
      : isTrial
        ? `Get $10 free when you add a credit card`
        : `Time to level up${firstName ? `, ${firstName}` : ''}`;
  const icon = isCurrent ? (
    <Flare sx={{ fontSize: '48px' }} />
  ) : isOverdue ? (
    <AutoAwesome sx={{ fontSize: '48px' }} />
  ) : (
    <AutoFixHigh sx={{ fontSize: '48px' }} />
  );

  const handleGetCheckout = async () => {
    try {
      const { getCheckout: checkout } = await getCheckout({
        input: {
          organisationID: selectedOrganisation,
        },
      }).unwrap();
      mixpanel.track('get_checkout', {});
      window.location.href = checkout.url;
    } catch (error) {
      console.error('Error fetching checkout data:', error);
    }
  };

  const handleGetCustomerPortal = async () => {
    try {
      const { getCustomerPortal: customerPortal } = await getCustomerPortal({
        input: {
          organisationID: selectedOrganisation,
        },
      }).unwrap();
      console.log(JSON.stringify(customerPortal, null, 2));
      window.location.href = customerPortal.url;
    } catch (error) {
      console.error('Error fetching customer portal data:', error);
    }
  };

  const isLoading = !data || !user;

  return (
    <Box>
      <Card
        sx={{
          height: 200,
          position: 'relative',
          background: background,
        }}
      >
        <CardContent
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            position: 'relative',
          }}
        >
          <Stack
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%',
            }}
            direction="column"
            spacing={2}
            alignItems="flex-start"
          >
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                width={100}
                height={24}
                sx={{ borderRadius: 40 }}
              />
            ) : (
              <Chip
                sx={{
                  fontWeight: 'bold',
                  color: chipColor,
                }}
                label={chipLabel}
              />
            )}
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
            >
              {isLoading ? (
                <>
                  <Skeleton
                    variant="text"
                    width={500}
                    height={48}
                    sx={{ borderRadius: 40 }}
                  />
                  <Skeleton
                    variant="circular"
                    width={48}
                    height={48}
                    sx={{ borderRadius: 40 }}
                  />
                </>
              ) : (
                <>
                  <Typography
                    variant="h1"
                    sx={{ fontSize: 40 }}
                  >
                    {message}
                  </Typography>
                  {icon}
                </>
              )}
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '100%' }}
            >
              <Stack
                direction="row"
                spacing={2}
              >
                {isLoading ? (
                  <>
                    <Skeleton
                      variant="rectangular"
                      width={100}
                      height={24}
                      sx={{ borderRadius: 40 }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={120}
                      height={24}
                      sx={{ borderRadius: 40 }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={130}
                      height={24}
                      sx={{ borderRadius: 40 }}
                    />
                  </>
                ) : (
                  <>
                    <LoadingButton
                      sx={{
                        borderRadius: 40,
                        height: 24,
                        fontWeight: 'bold',
                      }}
                      variant="outlined"
                      size="small"
                      startIcon={<CreditCard />}
                      onClick={handleGetCustomerPortal}
                      loading={isLoadingCustomerPortal}
                    >
                      Manage Billing
                    </LoadingButton>
                    {!isCurrent && (
                      <LoadingButton
                        sx={{
                          borderRadius: 40,
                          height: 24,
                          fontWeight: 'bold',
                        }}
                        variant="contained"
                        size="small"
                        onClick={handleGetCheckout}
                        loading={isLoadingCheckout}
                      >
                        Add a Card
                      </LoadingButton>
                    )}
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}
