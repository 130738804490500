import { CopyAll, DeleteOutline, MoreVert } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useDeleteMemoryMutation,
  useGetMemoryQuery,
  useGetSamplesQuery,
  useGetSelfQuery,
  useLazyGetCheckoutQuery,
  useLazyGetSampleBatchQuery,
  useUpdateMemoryMutation,
} from 'graphql/generated';
import { MemoryStep, OrganisationPaymentStatus } from 'types/enums';
import { useMemory } from 'use/memory';
import { useSnackbar } from 'use/snackbar';
import { useOrganisation } from 'use/organisation';

export default function EndAction() {
  const navigate = useNavigate();
  const { ingestionComplete } = useMemory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updateMemory, { isLoading: isUpdatingMemory }] =
    useUpdateMemoryMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteMemory] = useDeleteMemoryMutation();
  const [getSampleBatch, { isLoading: isLoadingSampleBatch }] =
    useLazyGetSampleBatchQuery();
  const { memoryID } = useParams<{ memoryID: string }>();
  const { selectedOrganisation } = useOrganisation();
  const [getCheckout, { isLoading: isLoadingCheckout }] =
    useLazyGetCheckoutQuery();

  const { data: selfData } = useGetSelfQuery({
    input: {
      organisationID: selectedOrganisation,
    },
  });
  const organisation = useMemo(
    () => selfData?.getSelf?.user[0]?.organisation,
    [selfData],
  );

  const { data: sampleData } = useGetSamplesQuery(
    {
      input: {
        memoryID,
      },
    },
    {
      skip: !memoryID,
    },
  );
  const samples = useMemo(() => sampleData?.getSamples?.data, [sampleData]);
  const { showSnackbar } = useSnackbar();

  const handleGetCheckout = useCallback(async () => {
    const res = await getCheckout({
      input: {
        organisationID: selectedOrganisation,
      },
    });
    const stripeURL = res?.data.getCheckout?.url;
    if (stripeURL) {
      window.location.href = stripeURL;
    }
  }, [getCheckout, selectedOrganisation]);

  const {
    data: memoryData,
    isLoading: isLoadingMemory,
    refetch: refetchMemories,
  } = useGetMemoryQuery({
    input: {
      id: memoryID,
    },
  });
  const memory = useMemo(() => memoryData?.getMemory?.data, [memoryData]);
  const handleVoiceDelete = () => {
    setIsDeleting(true);
    deleteMemory({ input: { id: memoryID } });
    setTimeout(() => {
      setIsDeleting(false);
      navigate('/memory');
    }, 5000);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const progressMemory = useCallback(async () => {
    switch (memory?.step) {
      case MemoryStep.select:
        await updateMemory({
          input: {
            id: memoryID,
            step: MemoryStep.ontology,
          },
        }).unwrap();
        await refetchMemories();
        break;
      case MemoryStep.ontology:
        if (organisation?.paymentStatus !== OrganisationPaymentStatus.active) {
          handleGetCheckout();
          return;
        }
        try {
          const timeoutPromise = new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Timeout')), 28000),
          );

          await Promise.race([
            getSampleBatch({
              input: {
                memoryID,
              },
            }).unwrap(),
            timeoutPromise,
          ]).catch((error) => {
            console.warn('getSampleBatch timeout or error:', error);
          });
        } catch (error) {
          console.warn('getSampleBatch failed:', error);
        }

        await updateMemory({
          input: {
            id: memoryID,
            step: MemoryStep.ingest,
          },
        }).unwrap();
        await refetchMemories();
        break;
      case MemoryStep.ingest:
        await updateMemory({
          input: {
            id: memoryID,
            step: MemoryStep.review,
          },
        }).unwrap();
        await refetchMemories();
        break;
      case MemoryStep.review:
        navigate(`/memory`);
        break;
      case MemoryStep.complete:
        navigate(`/memory`);
        break;
    }
  }, [
    memory?.step,
    updateMemory,
    memoryID,
    refetchMemories,
    organisation?.paymentStatus,
    navigate,
    handleGetCheckout,
    getSampleBatch,
  ]);

  const isLoading = isLoadingMemory || isLoadingSampleBatch || isUpdatingMemory;
  const disabled = memory?.step === MemoryStep.ingest && !ingestionComplete;

  const handleCopyMemoryID = useCallback(() => {
    navigator.clipboard.writeText(memoryID);
    showSnackbar('Memory ID copied to clipboard', 'success');
  }, [memoryID, showSnackbar]);

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{ pr: 1 }}
    >
      <Tooltip title="Copy Memory ID">
        <IconButton onClick={handleCopyMemoryID}>
          <CopyAll />
        </IconButton>
      </Tooltip>
      <LoadingButton
        disabled={isLoading || disabled || samples?.length === 0}
        size="small"
        variant="contained"
        color="primary"
        loading={isLoading || isLoadingCheckout}
        sx={{ fontWeight: 'bold', height: 28 }}
        onClick={progressMemory}
      >
        {memory?.step === MemoryStep.review
          ? 'Done'
          : memory?.step === MemoryStep.complete
            ? 'Exit'
            : 'Next'}
      </LoadingButton>

      <Box>
        <IconButton onClick={handleMenuOpen}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              sx: {
                minWidth: 140,
              },
            },
          }}
        >
          <MenuItem onClick={handleVoiceDelete}>
            <ListItemIcon>
              {isDeleting ? (
                <CircularProgress size={20} />
              ) : (
                <DeleteOutline
                  color="error"
                  fontSize="small"
                />
              )}
            </ListItemIcon>
            <ListItemText
              secondaryTypographyProps={{ color: 'error', fontWeight: 'bold' }}
              secondary="Delete"
            />
          </MenuItem>
        </Menu>
      </Box>
    </Stack>
  );
}
