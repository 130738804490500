import {
  Card,
  CardActionArea,
  Grid,
  CardHeader,
  Badge,
  Tooltip,
  Typography,
  Stack,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Call } from '@mui/icons-material';
import { AvatarBot } from 'components/atoms';
import { formatDate } from 'common/utils';

export const CardBot = ({ bot }) => {
  const navigate = useNavigate();

  return (
    <Grid
      item
      xs={12}
      sm={6}
    >
      <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <CardActionArea
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            height: '100%',
          }}
          onClick={() => navigate(`/playground/bot/${bot.id}`)}
        >
          <CardHeader
            avatar={
              <AvatarBot
                size={80}
                bot={bot}
              />
            }
            titleTypographyProps={{
              variant: 'h3',
              sx: { whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
            }}
            subheaderTypographyProps={{
              variant: 'body1',
              sx: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
                display: 'block',
              },
            }}
            action={
              <Tooltip
                placement="top"
                arrow
                title={bot?.unread ? bot?.unread + ' new calls' : null}
              >
                <Badge
                  badgeContent={bot?.unread ? bot?.unread : null}
                  color="info"
                >
                  <Call
                    sx={bot?.unread ? { opacity: 1 } : { opacity: 0.25 }}
                    fontSize="small"
                  />
                </Badge>
              </Tooltip>
            }
            title={bot.name}
            subheader={bot.description}
            sx={{
              width: '100%',
              '& .MuiCardHeader-content': {
                overflow: 'hidden',
              },
            }}
          />
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
            sx={{ px: 1, width: '100%' }}
          >
            <Typography variant="caption">
              {formatDate(bot.updatedAt)}
            </Typography>
          </Stack>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
