import { Cancel, ContentCopy, ToggleOff, ToggleOn } from '@mui/icons-material';
import {
  IconButton,
  Typography,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';
import { Stack, Tooltip } from '@mui/material';
import { formatDate } from 'common/utils';
import { useCallback, useMemo, useState } from 'react';
import {
  useCreateApiKeyMutation,
  useGetApiKeysQuery,
  useUpdateApiKeyMutation,
} from 'graphql/generated';
import { useSnackbar } from 'use/snackbar';
import { DialogAddApiKey } from 'components/molecules';
import { useOrganisation } from 'use/organisation';

interface ApiKeysProps {
  readonly?: boolean;
}

export default function ApiKeys({ readonly }: ApiKeysProps) {
  const { selectedOrganisation } = useOrganisation();
  const { data: apiKeyData, refetch } = useGetApiKeysQuery({
    input: {
      organisationID: selectedOrganisation,
    },
  });
  const apiKeys = useMemo(
    () => apiKeyData?.getApiKeys?.data || [],
    [apiKeyData],
  );
  const [createApiKey, { isLoading: isCreatingApiKey }] =
    useCreateApiKeyMutation();
  const [updateApiKey, { isLoading: isUpdatingApiKey }] =
    useUpdateApiKeyMutation();
  const { showSnackbar } = useSnackbar();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newKeyName, setNewKeyName] = useState('');

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setNewKeyName('');
  };

  const handleCreateApiKey = useCallback(async () => {
    if (!newKeyName.trim()) {
      showSnackbar('API Key name is required', 'error');
      return;
    }
    try {
      await createApiKey({ input: { name: newKeyName.trim() } });
      await refetch();
      showSnackbar('API Key created', 'success');
      handleCloseDialog();
    } catch (error) {
      showSnackbar(`Error creating API Key: ${error.toString()}`, 'error');
    }
  }, [createApiKey, refetch, showSnackbar, newKeyName]);

  const handleCopyKey = useCallback(
    (key: string) => {
      navigator.clipboard.writeText(key);
      showSnackbar('API Key copied to clipboard', 'success');
    },
    [showSnackbar],
  );

  const maskKey = (key: string) => {
    if (key.length <= 8) return '********';
    return `${key.slice(0, 10)}...${key.slice(-10)}`;
  };

  const handleDeleteKey = useCallback(
    async (keyId: string) => {
      try {
        await updateApiKey({ input: { id: keyId, isDisabled: true } });
        await refetch();
        showSnackbar('API Key disabled', 'success');
      } catch (error) {
        showSnackbar(`Error deleting API Key: ${error.toString()}`, 'error');
      }
    },
    [updateApiKey, refetch, showSnackbar],
  );

  const sortedApiKeys = useMemo(() => {
    return [...apiKeys].sort((a, b) => {
      if (a.isDisabled === b.isDisabled) {
        return 0;
      }
      return a.isDisabled ? 1 : -1;
    });
  }, [apiKeys]);

  console.log({ apiKeys });

  return (
    <Box>
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="20%">Name</TableCell>
                <TableCell width="40%">Key</TableCell>
                <TableCell width="5%">Active</TableCell>
                <TableCell width="25%">Created At</TableCell>
                {!readonly && <TableCell width="5%"></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedApiKeys.map((apiKey) => (
                <TableRow
                  key={apiKey.name}
                  sx={{ opacity: apiKey.isDisabled ? 0.5 : 1 }}
                >
                  <TableCell width="20%">{apiKey.name}</TableCell>
                  <TableCell width="40%">
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <Tooltip
                        title="Click to copy full API Key"
                        arrow
                        placement="top"
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            cursor: 'pointer',
                            '&:hover': { textDecoration: 'underline' },
                          }}
                          onClick={() => handleCopyKey(apiKey.id)}
                        >
                          {maskKey(apiKey.id)}
                        </Typography>
                      </Tooltip>
                      <Tooltip
                        title="Copy API Key"
                        arrow
                        placement="top"
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleCopyKey(apiKey.id)}
                        >
                          <ContentCopy fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                  <TableCell width="5%">
                    <Tooltip
                      title={
                        apiKey.isDisabled
                          ? 'API Key is disabled'
                          : 'API Key is active'
                      }
                      arrow
                      placement="top"
                    >
                      {apiKey.isDisabled ? (
                        <ToggleOff fontSize="small" />
                      ) : (
                        <ToggleOn fontSize="small" />
                      )}
                    </Tooltip>
                  </TableCell>
                  <TableCell width="15%">
                    {formatDate(apiKey.createdAt)}
                  </TableCell>
                  {!readonly && (
                    <TableCell width="20%">
                      {!apiKey.isDisabled ? (
                        <Tooltip
                          title="Disable API Key"
                          arrow
                          placement="top"
                        >
                          <IconButton
                            size="small"
                            onClick={() => handleDeleteKey(apiKey.id)}
                          >
                            {isUpdatingApiKey ? (
                              <CircularProgress size={16} />
                            ) : (
                              <Cancel fontSize="small" />
                            )}
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Box width={20} />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <DialogAddApiKey
        isDialogOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
        handleCreateApiKey={handleCreateApiKey}
        isCreatingApiKey={isCreatingApiKey}
        newKeyName={newKeyName}
        setNewKeyName={setNewKeyName}
      />
    </Box>
  );
}
