import { Close } from '@mui/icons-material';
import {
  BubbleChart,
  BubbleChartOutlined,
  Hub,
  HubOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { Memory } from '@duohub/types';
import { useCallback, useState } from 'react';
import { AdapterRepo, MemoryStep, MemoryType } from 'types/enums';
import { v4 as uuid } from 'uuid';
import mixpanel from 'mixpanel-browser';
import { useUpdateMemoryMutation } from 'graphql/generated';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useOrganisation } from 'use/organisation';

export default function NewMemory({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedType, setSelectedType] = useState<MemoryType | null>(null);
  const [memoryName, setMemoryName] = useState('');
  const [memoryDescription, setMemoryDescription] = useState('');
  const navigate = useNavigate();
  const [updateMemory] = useUpdateMemoryMutation();
  const [loading, setLoading] = useState(false);
  const { selectedOrganisation } = useOrganisation();

  const createMemory = useCallback(async () => {
    try {
      setLoading(true);
      const memoryID = uuid();
      const input: Memory = {
        id: memoryID,
        step: MemoryStep.select,
        name: memoryName,
        organisationID: selectedOrganisation,
        description: memoryDescription,
        memoryType: selectedType,
        adapterRepo: AdapterRepo.cultural_rdf_graph,
        adapterVersion: 1,
        graphDBName: uuid(),
        vectorDBCollection: uuid(),
      };
      await updateMemory({ input });
      mixpanel.track('create_memory');
      return memoryID;
    } catch (error) {
      console.error('Error creating memory:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [
    memoryName,
    selectedOrganisation,
    memoryDescription,
    selectedType,
    updateMemory,
  ]);

  const handleMemoryTypeSelect = async () => {
    if (!selectedType || !memoryName.trim()) return;

    const memoryID = await createMemory();
    navigate(`/memory/${memoryID}`);
    setMemoryName('');
    setMemoryDescription('');
    setSelectedType(null);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        <Stack spacing={2}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="space-between"
          >
            <Typography variant="h4">New Memory</Typography>
            <IconButton
              onClick={() => setOpen(false)}
              size="small"
            >
              <Close />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <Stack
        sx={{ px: 2, py: 2 }}
        spacing={2}
      >
        <Stepper activeStep={currentStep}>
          <Step>
            <StepLabel>Select Type</StepLabel>
          </Step>
          <Step>
            <StepLabel>Set Details</StepLabel>
          </Step>
        </Stepper>
      </Stack>
      <Divider />
      <DialogContent>
        <Box>
          {currentStep === 0 ? (
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup
                value={selectedType || ''}
                onChange={(e) => setSelectedType(e.target.value as MemoryType)}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="stretch"
                  sx={{ pt: 1 }}
                >
                  <Box
                    onClick={() => setSelectedType(MemoryType.vector)}
                    sx={{
                      flex: 1,
                      cursor: 'pointer',
                      border: (theme) =>
                        `1px solid ${selectedType === MemoryType.vector ? theme.palette.primary.main : 'transparent'}`,
                      borderRadius: 1,
                      p: 2,
                      '&:hover': {
                        bgcolor: 'action.hover',
                      },
                    }}
                  >
                    <Stack
                      spacing={1}
                      alignItems="center"
                    >
                      <FormControlLabel
                        value={MemoryType.vector}
                        control={
                          <Radio
                            checkedIcon={<BubbleChart fontSize="large" />}
                            icon={<BubbleChartOutlined fontSize="large" />}
                          />
                        }
                        label={
                          <Typography
                            fontWeight={
                              selectedType === MemoryType.vector
                                ? 'bold'
                                : 'normal'
                            }
                          >
                            Vector
                          </Typography>
                        }
                        sx={{ mb: 0 }}
                      />
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        align="center"
                      >
                        Best for a large number of documents
                      </Typography>
                    </Stack>
                  </Box>
                  <Divider
                    orientation="vertical"
                    flexItem
                  />
                  <Box
                    onClick={() => setSelectedType(MemoryType.graph)}
                    sx={{
                      flex: 1,
                      cursor: 'pointer',
                      border: (theme) =>
                        `1px solid ${selectedType === MemoryType.graph ? theme.palette.primary.main : 'transparent'}`,
                      borderRadius: 1,
                      p: 2,
                      '&:hover': {
                        bgcolor: 'action.hover',
                      },
                    }}
                  >
                    <Stack
                      spacing={1}
                      alignItems="center"
                    >
                      <FormControlLabel
                        value={MemoryType.graph}
                        control={
                          <Radio
                            checkedIcon={<Hub fontSize="large" />}
                            icon={<HubOutlined fontSize="large" />}
                          />
                        }
                        label={
                          <Typography
                            fontWeight={
                              selectedType === MemoryType.graph
                                ? 'bold'
                                : 'normal'
                            }
                          >
                            Graph
                          </Typography>
                        }
                        sx={{ mb: 0 }}
                      />
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        align="center"
                      >
                        Best for high precision retrieval
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              </RadioGroup>
            </FormControl>
          ) : (
            <Stack spacing={1}>
              <TextField
                label="Memory Name"
                placeholder="What is the name of this memory?"
                fullWidth
                value={memoryName}
                onChange={(e) => setMemoryName(e.target.value)}
                required
              />
              <TextField
                label="Description (optional)"
                placeholder="What is this memory group about?"
                fullWidth
                multiline
                value={memoryDescription}
                onChange={(e) => setMemoryDescription(e.target.value)}
              />
            </Stack>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          disabled={currentStep === 0}
          onClick={() => setCurrentStep(0)}
          size="small"
          sx={{ height: 28, fontWeight: 'bold' }}
        >
          Back
        </Button>
        {currentStep === 0 && (
          <Button
            variant="contained"
            onClick={() => setCurrentStep(1)}
            disabled={!selectedType}
            size="small"
            sx={{ height: 28, fontWeight: 'bold' }}
          >
            Next
          </Button>
        )}
        {currentStep === 1 && (
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleMemoryTypeSelect}
            disabled={!memoryName.trim()}
            size="small"
            sx={{ height: 28, fontWeight: 'bold' }}
          >
            Create
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
