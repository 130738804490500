import { Add } from '@mui/icons-material';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Chip,
  Stack,
} from '@mui/material';
import { useOrganisation } from 'use/organisation';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetSelfQuery,
  useUpdateOrganisationUserMutation,
} from 'graphql/generated';
import { OrganisationUserStatus } from 'types/enums';
import { useSnackbar } from 'use/snackbar';

export const SelectOrganisation = () => {
  const { showSnackbar } = useSnackbar();
  const {
    selectedOrganisation,
    setSelectedOrganisation,
    setOpenDialogAddOrganisation,
  } = useOrganisation();
  const [updateOrganisationUser] = useUpdateOrganisationUserMutation();
  const { data: selfData, refetch: refetchSelf } = useGetSelfQuery({
    input: {},
  });
  const { id: organisationID } = useParams<{ id: string }>();

  useEffect(() => {
    const availableOrgs =
      selfData?.getSelf.user?.map((ou) => ou.organisation?.id) || [];
    const savedOrgId = localStorage.getItem('selectedOrganisation');

    if (savedOrgId && availableOrgs.includes(savedOrgId)) {
      setSelectedOrganisation(savedOrgId);
    } else if (availableOrgs.length > 0) {
      // If no saved org or saved org not found, select first from list
      setSelectedOrganisation(availableOrgs[0]);
    } else {
      setSelectedOrganisation('');
    }
  }, [selfData, setSelectedOrganisation]);

  useEffect(() => {
    if (selectedOrganisation) {
      setSelectedOrganisation(selectedOrganisation);
      localStorage.setItem('selectedOrganisation', selectedOrganisation);
      // Check if the selected org user is invited before updating status
      const selectedOrgUser = selfData?.getSelf.user?.find(
        (ou) => ou.organisation?.id === selectedOrganisation,
      );
      const setActive = async () => {
        await updateOrganisationUser({
          input: {
            organisationID: selectedOrganisation,
            status: OrganisationUserStatus.active,
          },
        }).unwrap();
        await refetchSelf();
        // Show success message with organization name
        showSnackbar(
          `Joined ${selectedOrgUser?.organisation?.name}`,
          'success',
        );
      };
      if (selectedOrgUser?.status === OrganisationUserStatus.invited) {
        setActive();
      }
    }
  }, [
    selectedOrganisation,
    setSelectedOrganisation,
    updateOrganisationUser,
    selfData,
    refetchSelf,
    showSnackbar,
  ]);

  useEffect(() => {
    if (organisationID) {
      setSelectedOrganisation(organisationID);
      updateOrganisationUser({
        input: {
          organisationID,
          status: OrganisationUserStatus.active,
        },
      }).unwrap();
    }
  }, [setSelectedOrganisation, updateOrganisationUser, organisationID]);

  const handleOrgChange = (event: SelectChangeEvent) => {
    const newOrg = event.target.value;
    setSelectedOrganisation(newOrg);
    localStorage.setItem('selectedOrganisation', newOrg);
    // Check if the selected org user is invited before updating status
    const selectedOrgUser = selfData?.getSelf.user?.find(
      (ou) => ou.organisation?.id === newOrg,
    );
    if (selectedOrgUser?.status === OrganisationUserStatus.invited) {
      updateOrganisationUser({
        input: {
          organisationID: newOrg,
          status: OrganisationUserStatus.active,
        },
      })
        .unwrap()
        .then(() => {
          // Show success message with organization name
          showSnackbar(
            `Joined ${selectedOrgUser?.organisation?.name}`,
            'success',
          );
        });
    }
  };

  const handleAddOrganisation = () => {
    setOpenDialogAddOrganisation(true);
  };

  return (
    <FormControl
      sx={{ m: 2, width: 210 }}
      size="small"
    >
      <InputLabel>Organisation</InputLabel>
      <Select
        value={selectedOrganisation}
        label="Organisation"
        onChange={handleOrgChange}
      >
        <MenuItem onClick={handleAddOrganisation}>
          <Add
            sx={{ mr: 1 }}
            fontSize="small"
          />
          <em>New Organisation</em>
        </MenuItem>
        {selfData?.getSelf.user?.map((ou) => (
          <MenuItem
            key={ou.organisation?.id}
            value={ou.organisation?.id}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              spacing={1}
            >
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flexGrow: 1,
                }}
              >
                {ou.organisation?.name}
              </div>
              {ou.status === OrganisationUserStatus.invited && (
                <Chip
                  label="Invited"
                  size="small"
                  color="info"
                  sx={{
                    height: '14px',
                    fontWeight: 'bold',
                    flexShrink: 0,
                  }}
                />
              )}
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
