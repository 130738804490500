import { Stack, Chip, CircularProgress } from '@mui/material';
import { getMemoryStepindex } from 'common/utils';
import Layout from 'layouts';
import { useParams } from 'react-router-dom';
import {
  useGetMemoryQuery,
  useLazyGetCheckoutQuery,
  useGetSelfQuery,
} from 'graphql/generated';
import { MemoryStep, MemoryType, OrganisationPaymentStatus } from 'types/enums';
import { StartTitle, EndAction, ProgressStepper } from './components';
import { Loading } from 'components/atoms';
import {
  MemoryNewStepSelect,
  MemoryNewStepIngest,
  MemoryNewStepOntology,
  MemoryNewStepSettings,
  MemoryNewStepReviewGraph,
  MemoryNewStepReviewVector,
} from './step';
import { useOrganisation } from 'use/organisation';
import MemoryNewStepWebhook from './step/webhook';
import { useMemo } from 'react';

export default function MemoryNew() {
  const { memoryID } = useParams<{ memoryID: string }>();
  const { data: memoryData, isLoading } = useGetMemoryQuery({
    input: { id: memoryID },
  });
  const { selectedOrganisation } = useOrganisation();
  const { data: selfData } = useGetSelfQuery({
    input: {
      organisationID: selectedOrganisation,
    },
  });

  const memory = memoryData?.getMemory?.data;
  const activeStep = getMemoryStepindex(memory?.step as MemoryStep);
  const memoryType = memory?.memoryType;

  const [getCheckout, { isLoading: isLoadingCheckout }] =
    useLazyGetCheckoutQuery();
  const organisation = useMemo(
    () => selfData?.getSelf?.user[0]?.organisation,
    [selfData],
  );
  const handleGetCheckout = async () => {
    const res = await getCheckout({
      input: {
        organisationID: selectedOrganisation,
      },
    });
    const stripeURL = res?.data.getCheckout?.url;
    if (stripeURL) {
      window.location.href = stripeURL;
    }
  };

  return (
    <Layout
      backArrow
      startTitle={<StartTitle />}
      middleAction={
        activeStep < 2 &&
        organisation?.paymentStatus !== OrganisationPaymentStatus.active && (
          <Chip
            onClick={handleGetCheckout}
            size="small"
            color="warning"
            sx={{ fontWeight: 'bold' }}
            icon={isLoadingCheckout ? <CircularProgress size={16} /> : null}
            label="Payment method required to continue. You will not be charged."
          />
        )
      }
      endAction={<EndAction />}
    >
      <Stack
        direction="column"
        spacing={2}
        sx={{ py: 1 }}
      >
        <ProgressStepper />
        {isLoading && <Loading />}
        {activeStep === 0 && !isLoading && <MemoryNewStepSelect />}
        {activeStep === 1 && !isLoading && memoryType === MemoryType.graph && (
          <MemoryNewStepOntology />
        )}
        {((activeStep === 1 && memoryType === MemoryType.graph) ||
          (activeStep === 2 &&
            memoryType === MemoryType.graph &&
            memory?.webhookUrl)) &&
          !isLoading && <MemoryNewStepWebhook isDisabled={activeStep === 2} />}
        {activeStep === 1 && !isLoading && memoryType === MemoryType.vector && (
          <MemoryNewStepSettings />
        )}
        {activeStep === 2 && !isLoading && <MemoryNewStepIngest />}
        {(activeStep === 3 || activeStep === 4) &&
          !isLoading &&
          memoryType === MemoryType.graph && <MemoryNewStepReviewGraph />}
        {(activeStep === 3 || activeStep === 4) &&
          !isLoading &&
          memoryType === MemoryType.vector && <MemoryNewStepReviewVector />}
      </Stack>
    </Layout>
  );
}
