import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga4';
import LogRocket from 'logrocket';

declare global {
  interface Window {
    zE?: any;
    $zopim?: any;
  }
}

console.log('window.location.hostname', window.location.hostname);

if (window.location.hostname !== 'localhost') {
  LogRocket.init('mmvzdn/duohub');
}

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

const script = document.createElement('script');
script.id = 'ze-snippet';
script.src =
  'https://static.zdassets.com/ekr/snippet.js?key=bcac595b-6fb1-45cd-897f-c026718738fb';
script.async = true;

// Add event listener to wait for script to load
script.addEventListener('load', () => {
  window.zE('messenger', 'hide');
});

document.head.appendChild(script);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
