import { useMemo, useState, useCallback, useEffect } from 'react';
import { Stack, Grid, Box, Divider } from '@mui/material';
import { CardBot } from 'components/molecules';
import { useGetBotsQuery } from 'graphql/generated';
import { Loading, Pagination } from 'components/atoms';
import { useOrganisation } from 'use/organisation';

export default function StudioBots() {
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [tokenStack, setTokenStack] = useState<(string | null)[]>([null]);
  const [totalCount, setTotalCount] = useState(0);
  const { selectedOrganisation } = useOrganisation();
  const {
    data,
    isLoading: isBotLoading,
    refetch,
  } = useGetBotsQuery({
    input: {
      organisationID: selectedOrganisation,
      nextToken: tokenStack[currentPage],
      limit: 20,
    },
  });

  const bots = useMemo(() => data?.getBots.data, [data]);

  useEffect(() => {
    if (data?.getBots) {
      setTotalCount(data.getBots.totalCount || 0);
      setNextToken(data.getBots.nextToken || null);
    }
  }, [data]);

  const handlePageChange = useCallback(
    async (token: string): Promise<{ items: unknown[]; nextToken: string }> => {
      setCurrentPage((prevPage) => {
        const newPage = token === null ? 1 : prevPage + 1;
        setTokenStack((prevStack) => {
          const newStack = [...prevStack];
          newStack[newPage] = token;
          return newStack;
        });
        return newPage;
      });
      const result = await refetch();
      if (result.data && result.data.getBots && result.data.getBots.data) {
        return {
          items: result.data.getBots.data,
          nextToken: result.data.getBots.nextToken || '',
        };
      }
      throw new Error('Failed to fetch files');
    },
    [refetch],
  );

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Pagination
          totalCount={totalCount}
          itemsPerPage={20}
          nextToken={nextToken}
          onPageChange={handlePageChange}
        />
      </Stack>
      <Divider sx={{ borderBottom: '0.1px solid #2e2e2e' }} />
      <Box sx={{ my: 2 }}>
        {isBotLoading ? (
          <Loading />
        ) : (
          <Grid
            container
            spacing={2}
          >
            {bots?.map((bot) => (
              <CardBot
                bot={bot}
                key={bot.id}
              />
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
}
