import { createBrowserRouter } from 'react-router-dom';
import {
  Overview,
  Data,
  Logs,
  Success,
  Call,
  Memory,
  MemoryID,
  SignUp,
  Developers,
  SignIn,
  ConfirmSignUp,
  Studio,
  Team,
  Billing,
  Organisation,
  Invitation,
  Usage,
} from './routes';
import AuthWrapper from 'wrapper';
import Content from 'routes/content';
import StudioBot from 'routes/playground/bot';
import ErrorPage from './routes/error';

export const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: (
          <AuthWrapper>
            <Overview />
          </AuthWrapper>
        ),
      },
      {
        path: '/usage',
        element: (
          <AuthWrapper>
            <Usage />
          </AuthWrapper>
        ),
      },
      {
        path: '/playground/*',
        element: (
          <AuthWrapper>
            <Studio />
          </AuthWrapper>
        ),
      },
      {
        path: '/playground/bot/:botID/*',
        element: (
          <AuthWrapper>
            <StudioBot />
          </AuthWrapper>
        ),
      },
      {
        path: '/data/*',
        element: (
          <AuthWrapper>
            <Data />
          </AuthWrapper>
        ),
      },
      {
        path: '/memory/*',
        element: (
          <AuthWrapper>
            <Memory />
          </AuthWrapper>
        ),
      },
      {
        path: '/memory/:memoryID',
        element: (
          <AuthWrapper>
            <MemoryID />
          </AuthWrapper>
        ),
      },
      {
        path: '/developers',
        element: (
          <AuthWrapper>
            <Developers />
          </AuthWrapper>
        ),
      },
      {
        path: '/content/*',
        element: (
          <AuthWrapper>
            <Content />
          </AuthWrapper>
        ),
      },
      {
        path: '/log/*',
        element: (
          <AuthWrapper>
            <Logs />
          </AuthWrapper>
        ),
      },
      {
        path: '/settings/billing',
        element: (
          <AuthWrapper>
            <Billing />
          </AuthWrapper>
        ),
      },
      {
        path: '/settings/team',
        element: (
          <AuthWrapper>
            <Team />
          </AuthWrapper>
        ),
      },
      {
        path: '/settings/organisation',
        element: (
          <AuthWrapper>
            <Organisation />
          </AuthWrapper>
        ),
      },
      {
        path: '/success',
        element: (
          <AuthWrapper>
            <Success />
          </AuthWrapper>
        ),
      },
      {
        path: '/invitation/:organisationUserID',
        element: (
          // <AuthWrapper>
          <Invitation />
          // </AuthWrapper>
        ),
      },
      {
        path: '/call/:botID',
        element: <Call />,
      },
      {
        path: '/signup',
        element: <SignUp />,
      },
      {
        path: '/signin',
        element: <SignIn />,
      },
      {
        path: '/confirm-signup',
        element: <ConfirmSignUp />,
      },
    ],
  },
]);
