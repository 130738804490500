import {
  Box,
  Card,
  Skeleton,
  Typography,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Grid,
  CardActionArea,
  Collapse,
} from '@mui/material';
import Layout from 'layouts';
import { useMemo, useState } from 'react';
import {
  useGetApiKeysQuery,
  useGetSelfQuery,
  useGetUsageQuery,
} from 'graphql/generated';
import { DateTime } from 'luxon';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  ArrowForward,
  Bolt,
  CreateNewFolder,
  Start,
} from '@mui/icons-material';
import ChartMini from 'components/molecules/chart-mini';
import GitHubIcon from '@mui/icons-material/GitHub';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PeopleIcon from '@mui/icons-material/People';
import HelpIcon from '@mui/icons-material/Help';
import StarIcon from '@mui/icons-material/Star';
import CloudIcon from '@mui/icons-material/Cloud';

import { useOrganisation } from 'use/organisation';
import { useSnackbar } from 'use/snackbar';
import { useNavigate } from 'react-router-dom';
import { useApp } from 'use/app';

const getGreeting = (): string => {
  const hour = DateTime.local().hour;
  if (hour >= 0 && hour < 12) return 'Good morning';
  if (hour >= 12 && hour < 17) return 'Good afternoon';
  return 'Good evening';
};

// New components to extract repeated patterns
const StatCard = ({ title, value, managePath }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Grid
      item
      xs={12}
      md={6}
    >
      <Card>
        <CardActionArea
          sx={{ p: 2 }}
          onClick={() => navigate(managePath)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Stack spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">{title}</Typography>
              <Collapse
                in={isHovered}
                orientation="horizontal"
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '180px',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  >
                    Manage {title}
                  </Typography>
                </Box>
              </Collapse>
            </Stack>
            <Typography variant="h1">{value}</Typography>
          </Stack>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const UsageCard = ({ title, dataKey, usageData }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Grid
      item
      xs={12}
      md={4}
    >
      <Card>
        <CardActionArea
          sx={{ p: 2, height: '200px' }}
          onClick={() => navigate(`/usage`)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="h5">{title}</Typography>
            <Collapse
              in={isHovered}
              orientation="horizontal"
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '140px',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                >
                  View Usage Details
                </Typography>
              </Box>
            </Collapse>
          </Stack>
          <ChartMini
            data={usageData?.getUsage?.data || []}
            dataKey={dataKey}
          />
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const ExampleCard = ({ title, icon, link, type, showStar = false }) => {
  const getIcon = () => {
    switch (icon) {
      case 'github':
        return <GitHubIcon />;
      case 'cloud':
        return <CloudIcon />;
      case 'notebook':
        return <MenuBookIcon />;
      case 'file':
        return <CreateNewFolder />;
      case 'start':
        return <Start />;
      case 'bolt':
        return <Bolt />;
      default:
        return <MenuBookIcon />;
    }
  };

  return (
    <Grid
      item
      xs={12}
      md={showStar ? 12 : 6}
    >
      <Card
        sx={{
          p: 2,
          cursor: 'pointer',
          '&:hover': { bgcolor: 'action.hover' },
        }}
        onClick={() => window.open(link, '_blank')}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{ justifyContent: showStar ? 'space-between' : 'flex-start' }}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
          >
            {getIcon()}
            <Typography variant="h6">{title}</Typography>
          </Stack>
          {showStar && <StarIcon sx={{ color: 'gold' }} />}
        </Stack>
      </Card>
    </Grid>
  );
};

const ActionCard = ({ title, icon, onClick }) => {
  return (
    <Grid
      item
      xs={12}
      md={4}
    >
      <Card
        sx={{
          p: 2,
          cursor: 'pointer',
          '&:hover': { bgcolor: 'action.hover' },
        }}
        onClick={onClick}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          {icon}
          <Typography variant="h6">{title}</Typography>
        </Stack>
      </Card>
    </Grid>
  );
};

export default function Overview() {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { isZendeskOpen, setIsZendeskOpen } = useApp();
  const { selectedOrganisation } = useOrganisation();
  const { data: userData, isLoading: isUserLoading } = useGetSelfQuery({
    input: {
      organisationID: selectedOrganisation,
    },
  });
  const { data: apiKeyData } = useGetApiKeysQuery({
    input: {
      activeOnly: true,
      organisationID: selectedOrganisation,
    },
  });
  const apiKeys = useMemo(
    () => apiKeyData?.getApiKeys?.data || [],
    [apiKeyData],
  );
  const { data: usageData } = useGetUsageQuery(
    {
      input: {
        organisationID: selectedOrganisation,
        stats: true,
        timezone: DateTime.local().zoneName,
      },
    },
    {
      skip: !selectedOrganisation,
      refetchOnMountOrArgChange: true,
    },
  );
  const user = useMemo(() => userData?.getSelf?.user?.[0]?.user, [userData]);
  const organisation = useMemo(
    () => userData?.getSelf?.user?.[0]?.organisation,
    [userData],
  );
  const greeting = useMemo(() => getGreeting(), []);

  const copyWithFeedback = async (
    textToCopy,
    successMessage = 'API Key copied to clipboard!',
  ) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      showSnackbar(successMessage, 'success');
    } catch (error) {
      showSnackbar('Failed to copy to clipboard', 'error');
      console.error('Copy failed:', error);
    }
  };

  const statsConfig = [
    {
      title: 'Files',
      value: usageData?.getUsage?.files || 0,
      managePath: '/content',
    },
    {
      title: 'Memories',
      value: usageData?.getUsage?.graphs || 0,
      managePath: '/memory',
    },
  ];

  const usageConfig = [
    { title: 'Graph Generation Tokens', dataKey: 'tokens' },
    { title: 'Storage', dataKey: 'bytes' },
    { title: 'API Requests', dataKey: 'apiRequests' },
  ];

  const examplesConfig = [
    {
      title: 'Integrate Graph Memory with Pipecat',
      icon: 'github',
      link: 'https://github.com/duohub-ai/examples/tree/main/pipecat',
      type: 'Github',
      showStar: true,
    },
    {
      title: 'Chat Integration with AWS Lambda',
      icon: 'cloud',
      link: 'https://github.com/duohub-ai/examples/tree/main/lambda',
      type: 'Github',
    },
    {
      title: 'Chat Integration with Supabase',
      icon: 'bolt',
      link: 'https://github.com/duohub-ai/examples/tree/main/supabase',
      type: 'Github',
    },
    {
      title: 'Add Files to Content Library with API',
      icon: 'file',
      link: 'https://colab.research.google.com/drive/1ggnROrgJMRcYlrIqscjc2MmhPfPLgmn9#scrollTo=tncaE1ur5PEx',
      type: 'Notebook',
    },
    {
      title: 'Create a Memory and Start Ingestion with API',
      icon: 'start',
      link: 'https://colab.research.google.com/drive/1RO4cGEgFmspIHC3V5DwcXb2FcZQyOd2D#scrollTo=Vk1JBq1v5oL8',
      type: 'Notebook',
    },
  ];

  const showZendeskWidget = () => {
    if (isZendeskOpen) {
      window.zE('messenger', 'close');
      window.zE('messenger', 'hide');
      setIsZendeskOpen(false);
    } else {
      window.zE('messenger', 'show');
      window.zE('messenger', 'open');
      setIsZendeskOpen(true);
    }
  };

  const actionConfig = [
    {
      title: 'Add Team',
      icon: <PeopleIcon />,
      onClick: () => navigate('/settings/team'),
    },
    {
      title: 'Read Docs',
      icon: <MenuBookIcon />,
      onClick: () => window.open('https://docs.duohub.ai', '_blank'),
    },
    {
      title: 'Get Help',
      icon: <HelpIcon />,
      onClick: () => showZendeskWidget(),
    },
  ];

  return (
    <Layout
      startTitle={
        <Typography
          variant="h4"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {isUserLoading ? (
            <Skeleton width={250} />
          ) : (
            <>
              {greeting}, {user?.firstName}
            </>
          )}
        </Typography>
      }
      endAction={
        <Typography
          variant="h4"
          color="text.secondary"
        >
          {organisation?.name}
        </Typography>
      }
    >
      <Grid
        sx={{ mt: 1 }}
        container
        spacing={2}
      >
        {/* Stats Cards */}
        {statsConfig.map((stat) => (
          <StatCard
            key={stat.title}
            {...stat}
          />
        ))}

        {/* Usage Cards */}
        {usageConfig.map((usage) => (
          <UsageCard
            key={usage.title}
            {...usage}
            usageData={usageData}
          />
        ))}
      </Grid>
      <Stack
        direction="column"
        spacing={4}
        sx={{ mt: 3 }}
      >
        <Box>
          <Card>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ p: 2 }}
            >
              <Typography variant="h5">
                API Key - {apiKeys?.[0]?.name}
              </Typography>
              <Button
                size="small"
                sx={{ fontWeight: 'bold' }}
                endIcon={<ArrowForward />}
                onClick={() => {
                  navigate('/developers');
                }}
              >
                API Keys
              </Button>
            </Stack>
            {apiKeys?.[0]?.id && (
              <Box sx={{ p: 2, pt: 0 }}>
                <TextField
                  fullWidth
                  value={`${apiKeys[0].id.slice(0, 6)}...${apiKeys[0].id.slice(-4)}`}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            copyWithFeedback(apiKeys[0].id);
                          }}
                          edge="end"
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  size="small"
                />
              </Box>
            )}
          </Card>
        </Box>

        <Box>
          <Typography
            variant="h4"
            sx={{ mb: 2 }}
          >
            Examples
          </Typography>
          <Grid
            container
            spacing={2}
          >
            {examplesConfig.map((example) => (
              <ExampleCard
                key={example.title}
                {...example}
              />
            ))}
          </Grid>
        </Box>

        <Box>
          <Typography
            variant="h4"
            sx={{ mb: 2 }}
          >
            Quick Actions
          </Typography>
          <Grid
            container
            spacing={2}
          >
            {actionConfig.map((action) => (
              <ActionCard
                key={action.title}
                {...action}
              />
            ))}
          </Grid>
        </Box>
      </Stack>
    </Layout>
  );
}
