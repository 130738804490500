import Layout from 'layouts/index';
import {
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  Divider,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  useGetOrganisationQuery,
  useGetSelfQuery,
  useUpdateOrganisationMutation,
  useSendBillingInboundMutation,
} from 'graphql/generated';
import { useOrganisation } from 'use/organisation';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { OrganisationUserRoleType } from 'types/enums';

export default function Organisation() {
  const [orgName, setOrgName] = useState('Current Organization Name');
  const [isEditing, setIsEditing] = useState(false);
  const { selectedOrganisation } = useOrganisation();
  const [updateOrganisation] = useUpdateOrganisationMutation();
  const { refetch } = useGetSelfQuery({
    input: {},
  });
  const { data: selfData, isLoading: isGettingSelf } = useGetSelfQuery({
    input: {
      organisationID: selectedOrganisation,
    },
  });
  const organisationUser = selfData?.getSelf?.user[0];
  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const [closeMessage, setCloseMessage] = useState('');
  const [sendBillingInbound] = useSendBillingInboundMutation();

  const { data } = useGetOrganisationQuery(
    {
      input: {
        id: selectedOrganisation,
      },
    },
    {
      skip: !selectedOrganisation,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (data?.getOrganisation) {
      setOrgName(data.getOrganisation.data?.name ?? '');
    }
  }, [data]);

  const handleSave = async () => {
    await updateOrganisation({
      input: {
        id: selectedOrganisation,
        name: orgName,
      },
    }).unwrap();
    setIsEditing(false);
    refetch();
  };

  const handleCloseAccountSubmit = async () => {
    try {
      await sendBillingInbound({
        input: {
          content: `[ACCOUNT CLOSURE REQUEST] Organization ID: ${selectedOrganisation}\n\nReason: ${closeMessage}`,
        },
      }).unwrap();
      setCloseMessage('');
      setShowCloseDialog(false);
    } catch (error) {
      console.error('Error sending account closure request:', error);
    }
  };

  const handleEditName = () => {
    if (
      selfData?.getSelf?.user[0]?.roleType === OrganisationUserRoleType.admin
    ) {
      setIsEditing(true);
    }
  };

  return (
    <Layout
      startTitle={<Typography variant="h4">Organisation Settings</Typography>}
    >
      <Box
        sx={{
          maxWidth: 800,
          m: 2,
          p: 3,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <Typography
          variant="h4"
          sx={{ mb: 4 }}
        >
          Account details
        </Typography>

        <Stack spacing={4}>
          <Box>
            <Typography
              variant="subtitle2"
              color="text.secondary"
            >
              Name
            </Typography>
            {isEditing ? (
              <Box
                sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 1 }}
              >
                <TextField
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                  fullWidth
                  variant="standard"
                  autoFocus
                />
                <Button
                  onClick={handleSave}
                  variant="contained"
                  size="small"
                >
                  Save
                </Button>
                <Button
                  onClick={() => setIsEditing(false)}
                  size="small"
                >
                  Cancel
                </Button>
              </Box>
            ) : (
              <Box
                onClick={handleEditName}
                sx={{
                  mt: 1,
                  cursor: 'pointer',
                  '&:hover': { bgcolor: 'action.hover' },
                  p: 1,
                  borderRadius: 1,
                }}
              >
                <Typography variant="h4">{orgName}</Typography>
              </Box>
            )}
          </Box>

          <Box>
            <Typography
              variant="subtitle2"
              color="text.secondary"
            >
              ID
            </Typography>
            <Typography>{selectedOrganisation}</Typography>
          </Box>

          {data?.getOrganisation?.data?.createdAt && (
            <Box>
              <Typography
                variant="subtitle2"
                color="text.secondary"
              >
                Created on
              </Typography>
              <Typography>
                {new Date(
                  data.getOrganisation.data.createdAt,
                ).toLocaleDateString()}
              </Typography>
            </Box>
          )}

          <Box>
            <Button
              disabled={
                isGettingSelf ||
                organisationUser?.roleType !== OrganisationUserRoleType.admin
              }
              sx={{ height: 24 }}
              size="small"
              variant="outlined"
              color="error"
              onClick={() => setShowCloseDialog(true)}
            >
              Close Organisation
            </Button>
          </Box>
        </Stack>
      </Box>

      <Dialog
        open={showCloseDialog}
        onClose={() => setShowCloseDialog(false)}
      >
        <DialogTitle>
          <Typography variant="h4">Close Organisation</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            Please let us know why you'd like to close your organisation. Our
            team will review your request and contact you to confirm that you'd
            like all of your organisation's data deleted.
          </Typography>
          <TextField
            label="Reason for Closing Organisation"
            multiline
            minRows={4}
            style={{ width: '100%', padding: '8px' }}
            value={closeMessage}
            onChange={(e) => setCloseMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Stack
            width="100%"
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              size="small"
              sx={{ height: 24 }}
              onClick={() => setShowCloseDialog(false)}
            >
              Cancel
            </Button>
            <LoadingButton
              onClick={handleCloseAccountSubmit}
              variant="contained"
              color="error"
              size="small"
              sx={{ height: 24 }}
              disabled={!closeMessage.trim()}
            >
              Submit Request
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}
