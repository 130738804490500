import { Box, Typography, Container } from '@mui/material';
import Layout from 'layouts/index';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();

  const is404 = isRouteErrorResponse(error) && error.status === 404;
  const statusText = isRouteErrorResponse(error) ? error.statusText : '';

  return (
    <Layout>
      <Container>
        <Box
          sx={{
            minHeight: '90vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 4,
          }}
        >
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
          >
            {is404 ? '404 - Page Not Found' : 'Oops!'}
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
          >
            {is404
              ? "Sorry, we couldn't find the page you're looking for."
              : 'Sorry, an unexpected error has occurred.'}
          </Typography>
          {statusText && (
            <Typography color="text.secondary">{statusText}</Typography>
          )}
        </Box>
      </Container>
    </Layout>
  );
}
