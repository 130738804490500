import {
  CircularProgress,
  IconButton,
  Stack,
  TableRow,
  Tooltip,
  Chip,
} from '@mui/material';
import { useCallback } from 'react';
import { useDeleteFileMutation } from 'graphql/generated';
import { AppFile } from 'types/app';
import { FileType } from 'types/enums';
import { useSnackbar } from 'use/snackbar';
import { categoryConfig } from '../category-config';
import { Cancel, Layers } from '@mui/icons-material';
import { getStatusIcons } from '../status-icons';

interface RowTableContentProps {
  file: AppFile;
  StyledTableCell: React.ComponentType<any>;
  refetch: () => void;
}

export const RowTableContent = ({
  file,
  StyledTableCell,
  refetch,
}: RowTableContentProps) => {
  const getCategoryIcon = (fileType: FileType) =>
    categoryConfig[fileType]?.icon || null;
  const getCategoryText = (fileType: FileType) =>
    categoryConfig[fileType]?.text || 'Unknown';

  const [deleteFile, { isLoading: isDeletingFile }] = useDeleteFileMutation();
  const { showSnackbar } = useSnackbar();

  const startDeleteFile = useCallback(
    async (fileID: string) => {
      await deleteFile({ input: { id: fileID } }).unwrap();
      showSnackbar(`${file.name} deleted`, 'success');
      refetch();
    },
    [deleteFile, file.name, showSnackbar, refetch],
  );

  return (
    <TableRow key={file?.id}>
      <StyledTableCell width="10%">
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          {file?.fileType === FileType.sitemap && (
            <Tooltip title={(file?.hiddenFilesTotal || 0) + ' pages'}>
              <Chip
                label={file?.hiddenFilesTotal || '-'}
                icon={<Layers />}
                size="small"
              />
            </Tooltip>
          )}
          {file?.fileType === FileType.website_bulk && (
            <Tooltip title={(file?.hiddenFilesTotal || 0) + ' pages'}>
              <Chip
                label={file?.hiddenFilesTotal || '-'}
                icon={<Layers />}
                size="small"
              />
            </Tooltip>
          )}
          {getCategoryIcon(file.fileType as FileType)}
          <span>{getCategoryText(file.fileType as FileType)}</span>
        </Stack>
      </StyledTableCell>
      <StyledTableCell width="50%">
        <Tooltip
          title={file.name}
          key={`tooltip-${file.id}`}
        >
          <span>
            {file.name.length > 50 ? `${file.name.slice(0, 50)}...` : file.name}
          </span>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell width="10%">
        <Stack
          direction="row"
          spacing={1}
        >
          {getStatusIcons(file)}
        </Stack>
      </StyledTableCell>
      <StyledTableCell width="10%">
        {file.words && (
          <Chip
            size="small"
            label={`${file.words.toLocaleString()}`}
          />
        )}
      </StyledTableCell>
      <StyledTableCell width="15%">
        {new Date(file.updatedAt).toLocaleString()}
      </StyledTableCell>
      <StyledTableCell width="5%">
        <Tooltip
          title="Delete Permanently"
          placement="left"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'error.main',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '12px',
              },
            },
            arrow: {
              sx: {
                color: 'error.main',
              },
            },
          }}
        >
          <IconButton
            onClick={() => startDeleteFile(file.id)}
            disabled={isDeletingFile}
          >
            {isDeletingFile ? <CircularProgress size={24} /> : <Cancel />}
          </IconButton>
        </Tooltip>
      </StyledTableCell>
    </TableRow>
  );
};
