import {
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useUpdateMemoryMutation } from 'graphql/generated';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

export default function MemoryNewStepSettings() {
  const { memoryID } = useParams();
  const [extractionMethod, setExtractionMethod] = useState('chunk');
  const [chunkSize, setChunkSize] = useState(250);
  const [chunkOverlap, setChunkOverlap] = useState(10);
  const [updateMemory] = useUpdateMemoryMutation();

  const handleExtractionMethodChange = async (value: string) => {
    setExtractionMethod(value);
    await updateMemory({
      input: {
        id: memoryID,
        factExtraction: value === 'fact',
        chunkSize: value === 'chunk' ? chunkSize : undefined,
        chunkOverlap: value === 'chunk' ? chunkOverlap : undefined,
      },
    });
  };

  const handleChunkSizeChange = async (value: number) => {
    setChunkSize(value);
    await updateMemory({
      input: {
        id: memoryID,
        factExtraction: false,
        chunkSize: value,
        chunkOverlap,
      },
    });
  };

  const handleChunkOverlapChange = async (value: number) => {
    setChunkOverlap(value);
    await updateMemory({
      input: {
        id: memoryID,
        factExtraction: false,
        chunkSize,
        chunkOverlap: value,
      },
    });
  };

  return (
    <Card>
      <CardContent>
        <Typography>Settings</Typography>
        <Divider sx={{ my: 2, opacity: 0.5 }} />
        <Stack
          direction="column"
          spacing={2}
        >
          <Card sx={{ p: 2 }}>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
            >
              Chunking Strategy
            </Typography>
            <RadioGroup
              sx={{ mt: 2 }}
              value={extractionMethod}
              onChange={(e) => handleExtractionMethodChange(e.target.value)}
              name="extraction-method"
            >
              <Stack
                direction="row"
                spacing={2}
              >
                <FormControlLabel
                  value="chunk"
                  control={<Radio />}
                  label="Chunks"
                />
                <FormControlLabel
                  value="fact"
                  control={<Radio />}
                  label="Fact Extraction"
                />
              </Stack>
            </RadioGroup>
          </Card>

          {extractionMethod === 'chunk' && (
            <>
              <Card sx={{ p: 2 }}>
                <Stack spacing={1}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                  >
                    Chunk Size
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  >
                    Number of characters per chunk (10-5000)
                  </Typography>
                  <TextField
                    type="number"
                    value={chunkSize}
                    onChange={(e) =>
                      handleChunkSizeChange(Number(e.target.value))
                    }
                    inputProps={{
                      min: 10,
                      max: 5000,
                    }}
                    size="small"
                  />
                </Stack>
              </Card>

              <Card sx={{ p: 2 }}>
                <Stack spacing={1}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                  >
                    Chunk Overlap
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  >
                    Percentage of overlap between chunks (0-100%)
                  </Typography>
                  <TextField
                    type="number"
                    value={chunkOverlap}
                    onChange={(e) =>
                      handleChunkOverlapChange(Number(e.target.value))
                    }
                    inputProps={{
                      min: 0,
                      max: 100,
                    }}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Card>
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
