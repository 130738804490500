import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useApp } from 'use/app';
import { useNavigate, useLocation } from 'react-router-dom';
import { SideBarItemProps } from 'types/app';
import { useUser } from 'use/user';
import { useGetUserQuery } from 'graphql/generated';

function SidebarItem({
  title,
  iconFilled,
  iconOutlined,
  path,
  disabled,
}: SideBarItemProps): JSX.Element {
  const { user: authUser } = useUser();
  const { data } = useGetUserQuery({
    input: {
      userID: authUser?.userId,
    },
  });
  const user = data?.getUser;
  const navigate = useNavigate();
  const location = useLocation();
  const { drawerOpen } = useApp();

  const currentPath = path(user?.id);

  const isSelected =
    location.pathname === currentPath ||
    (title === 'Users' && location.pathname === '/') ||
    (title === user?.id && location.pathname === `/user/${user?.id}`) ||
    (title === 'Data' && location.pathname.includes('/data')) ||
    (title === 'Playground' && location.pathname.includes('/playground')) ||
    (title === 'Memory' && location.pathname.includes('/memory')) ||
    (title === 'People' && location.pathname.includes('/people/')) ||
    (title === 'Logs' && location.pathname.includes('log')) ||
    (title === 'Voice' && location.pathname.includes('/voice'));

  return (
    <ListItem
      key={title}
      disablePadding
      sx={{ display: 'block' }}
    >
      <ListItemButton
        disabled={disabled}
        onClick={() => navigate(currentPath)}
        selected={isSelected}
        sx={{
          minHeight: 48,
          justifyContent: drawerOpen ? 'initial' : 'center',
          pl: 3,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: drawerOpen ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {isSelected ? iconFilled : iconOutlined}
        </ListItemIcon>
        <ListItemText
          primary={title}
          sx={{ opacity: drawerOpen ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default SidebarItem;
