import { Add, Launch } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { ModelSettingsSection, Loading } from 'components/atoms';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DialogAddMemory from '../dialog-add-memory';
import {
  useGetBotQuery,
  useGetGraphQuery,
  useGetMemoryQuery,
} from 'graphql/generated';
import { ForceDirectedGraph3D } from 'components/organisms/force-directed-graph';

export default function ModelContext() {
  const { botID } = useParams<{ botID: string }>();
  const [openAddContext, setOpenAddContext] = useState(false);
  const { data: botData } = useGetBotQuery({ input: { id: botID } });
  const bot = useMemo(() => botData?.getBot?.data, [botData]);
  const { data: graphData, isLoading: isGraphLoading } = useGetGraphQuery(
    { input: { memoryID: bot?.memoryID } },
    { skip: bot?.memoryID == null },
  );
  const { data: memoryData } = useGetMemoryQuery(
    {
      input: { id: bot?.memoryID },
    },
    { skip: !bot?.memoryID },
  );
  const memory = useMemo(() => memoryData?.getMemory?.data, [memoryData]);
  const navigate = useNavigate();
  const formattedGraphData = useMemo(() => {
    if (!graphData?.getGraph?.data) return null;

    const { nodes, links } = graphData.getGraph.data;
    const nodeIds = new Set(nodes.map((node) => node.id));

    return {
      nodes: nodes.map((node) => ({
        id: node.id,
        name: node.name,
        description: node.description,
        color: node.color,
      })),
      links: links
        .filter((link) => nodeIds.has(link.source) && nodeIds.has(link.target))
        .map((link) => ({
          source: link.source,
          target: link.target,
        })),
    };
  }, [graphData]);

  if (isGraphLoading && bot?.memoryID) {
    return <Loading />;
  }

  return (
    <ModelSettingsSection
      title="Memory"
      subtitle="Add a knowledge graph for your bot to reference in conversations. This will make your bot informed about the topic at hand."
      actionButton={
        <IconButton onClick={() => setOpenAddContext(true)}>
          <Add />
        </IconButton>
      }
    >
      <Box>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Paper
              elevation={3}
              sx={{
                borderRadius: '16px',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  pl: 2,
                  pr: 1,
                  py: 0.5,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="h5">{`${memory?.name || 'Graph Preview'}`}</Typography>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`/memory/${bot?.memoryID}`)}
                  >
                    <Launch fontSize="small" />
                  </IconButton>
                </Stack>
              </Stack>
              {bot?.memoryID && formattedGraphData && (
                <Box
                  sx={{
                    width: '100%',
                    height: '500px',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'black',
                  }}
                >
                  <ForceDirectedGraph3D
                    data={formattedGraphData}
                    height={500}
                  />
                </Box>
              )}
              {!bot?.memoryID && (
                <Box sx={{ py: 8, px: 2 }}>
                  <Alert severity="info">
                    No graph selected. Please select a graph to add memory.
                  </Alert>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <DialogAddMemory
        open={openAddContext}
        setOpen={setOpenAddContext}
      />
    </ModelSettingsSection>
  );
}
