import {
  Box,
  CircularProgress,
  TextField,
  Button,
  Typography,
  Container,
  Stack,
  Divider,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useGetOrganisationUserQuery } from 'graphql/generated';
import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useOrganisation } from 'use/organisation';
import {
  signIn,
  SignInInput,
  signInWithRedirect,
  getCurrentUser,
  confirmSignIn,
} from 'aws-amplify/auth';
import { Logo } from 'components/atoms';
import {
  WavingHand,
  Google as GoogleIcon,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import mixpanel from 'mixpanel-browser';
import { LoadingButton } from '@mui/lab';

export default function Invitation() {
  const { organisationUserID } = useParams();
  const { setSelectedOrganisation } = useOrganisation();
  const navigate = useNavigate();
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const checkAuthState = useCallback(async () => {
    try {
      await getCurrentUser();
      return true;
    } catch {
      return false;
    }
  }, []);

  const { data: organisationUser, isLoading: isLoadingUser } =
    useGetOrganisationUserQuery({
      input: {
        organisationUserID,
      },
    });
  console.log(organisationUser);

  const handlePasswordChange = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');

    try {
      setLoading(true);
      await confirmSignIn({
        challengeResponse: newPassword,
      });

      localStorage.setItem(
        'selectedOrganisation',
        organisationUser.getOrganisationUser.data.organisation.id,
      );
      setSelectedOrganisation(
        organisationUser.getOrganisationUser.data.organisation.id,
      );
      navigate('/');
    } catch (err) {
      console.error('Failed to set new password:', err);
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUnauthorizedUser = useCallback(async () => {
    if (
      !organisationUser?.getOrganisationUser?.data?.user?.email ||
      !organisationUser?.getOrganisationUser?.data?.user?.temporaryPassword
    ) {
      console.error('Missing email or temporary password');
      navigate('/signin');
      return;
    }

    try {
      console.log('Attempting sign in with temporary password');
      const signInInput: SignInInput = {
        username: organisationUser.getOrganisationUser.data.user.email,
        password: organisationUser.getOrganisationUser.data.user
          .temporaryPassword as string,
      };
      const signInResult = await signIn(signInInput);
      console.log('Sign in result:', signInResult);

      if (
        signInResult.nextStep.signInStep ===
        'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
      ) {
        console.log('New password required challenge detected');
        setShowPasswordForm(true);
      }
    } catch (error) {
      console.error('Failed to sign in:', error);
      setError('Failed to sign in. Please try again.');
    }
  }, [organisationUser, navigate]);

  const handleGoogleSignIn = async () => {
    try {
      const isAuthenticated = await checkAuthState();
      if (isAuthenticated) {
        navigate('/', { replace: true });
        return;
      }

      await signInWithRedirect({
        provider: 'Google',
      });

      mixpanel.track('sign_in', {
        'Signin Type': 'Google',
      });
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError('Failed to sign in with Google. Please try again.');
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    const handleInvitation = async () => {
      if (!isLoadingUser && organisationUser) {
        const isLoggedIn = await checkAuthState();

        if (isLoggedIn) {
          localStorage.setItem(
            'selectedOrganisation',
            organisationUser?.getOrganisationUser?.data?.organisation?.id,
          );
          setSelectedOrganisation(
            organisationUser?.getOrganisationUser?.data?.organisation?.id,
          );
          navigate('/');
        } else {
          await handleUnauthorizedUser();
        }
      }
    };

    handleInvitation();
  }, [
    isLoadingUser,
    organisationUser,
    organisationUserID,
    checkAuthState,
    setSelectedOrganisation,
    navigate,
    handleUnauthorizedUser,
  ]);

  return (
    <Container maxWidth="xs">
      {showPasswordForm ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              alignItems="center"
            >
              <Logo size="medium" />
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h1"
                  variant="h3"
                >
                  Welcome aboard
                </Typography>
                <WavingHand />
              </Stack>
            </Stack>

            <Stack
              direction="column"
              spacing={2}
              alignItems="center"
              sx={{ mt: 4, width: '100%' }}
            >
              <Button
                fullWidth
                variant="outlined"
                startIcon={<GoogleIcon />}
                onClick={handleGoogleSignIn}
                sx={{ mb: 2 }}
              >
                Sign in with Google
              </Button>

              <Divider sx={{ width: '100%' }}>
                <Typography variant="caption">OR</Typography>
              </Divider>

              <Box
                component="form"
                onSubmit={handlePasswordChange}
                noValidate
                sx={{ width: '100%' }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="new-password"
                  label="New Password"
                  type={showPassword ? 'text' : 'password'}
                  id="new-password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, fontWeight: 'bold' }}
                  size="small"
                  loading={loading}
                >
                  Set Password
                </LoadingButton>
              </Box>
            </Stack>

            {error && (
              <Typography
                color="error"
                sx={{ mt: 2 }}
              >
                {error}
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="90vh"
        >
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
}
