import React, { memo, useState, useCallback, useEffect } from 'react';
import { FileCategory } from 'types/enums';
import {
  DialogContentAddSpeakerSelect,
  DialogContentAddWebsite,
  DialogContentAddYoutube,
} from 'components/molecules/';
import Layout from 'layouts';
import AddContentMenu from './components/startAction';
import FileFilterAndPagination from './components/endAction';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Box,
  Divider,
  Stack,
} from '@mui/material';
import { useGetFilesQuery, useLazyGetUserQuery } from 'graphql/generated';
import { Loading, Pagination, RowTableContent } from 'components/atoms';
import { styled } from '@mui/material/styles';
import { useContent } from 'use/content';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useOrganisation } from 'use/organisation';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: theme.spacing(1, 2),
}));

const StyledTable = styled(Table)(({ theme }) => ({
  tableLayout: 'fixed',
  width: '100%',
}));

function UserContent() {
  const {
    state,
    handleFileUpload,
    handleSpeakerSelection,
    handleSaveSpeakers,
    processNextFile,
    handleCloseSpeakerDialog,
    handleCancelCurrentFile,
    handleResetContent,
  } = useContent();
  const { selectedOrganisation } = useOrganisation();
  const [status, setStatus] = useState<FileCategory | 'all'>('all');
  const [websiteModalOpen, setWebsiteModalOpen] = useState(false);
  const [youtubeModalOpen, setYoutubeModalOpen] = useState(false);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [tokenStack, setTokenStack] = useState<(string | null)[]>([null]);
  const [totalCount, setTotalCount] = useState(0);
  const [refetchUser] = useLazyGetUserQuery();

  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: filesData,
    isLoading,
    refetch,
  } = useGetFilesQuery(
    {
      input: {
        organisationID: selectedOrganisation,
        nextToken: tokenStack[currentPage],
        category: status === 'all' ? null : status,
        limit: 20,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const files = filesData?.getFiles.data;

  useEffect(() => {
    const pathToStatus: Record<string, FileCategory | 'all'> = {
      '/content': 'all',
      '/content/video': FileCategory.video,
      '/content/audio': FileCategory.audio,
      '/content/webpage': FileCategory.web,
      '/content/document': FileCategory.document,
    };
    setStatus(pathToStatus[location.pathname] || 'all');
  }, [location.pathname]);

  const handleStatusChange = (
    event: React.MouseEvent<HTMLElement>,
    fileCategory: FileCategory | null,
  ) => {
    const categoryToPath: Record<FileCategory | 'all', string> = {
      all: '/content',
      [FileCategory.video]: '/content/video',
      [FileCategory.audio]: '/content/audio',
      [FileCategory.web]: '/content/webpage',
      [FileCategory.document]: '/content/document',
      [FileCategory.other]: '/content/other',
    };

    const newCategory = fileCategory || 'all';
    setStatus(newCategory);
    navigate(categoryToPath[newCategory]);
  };

  const handleYoutubeClick = () => {
    setYoutubeModalOpen(true);
  };

  const handleFileAdded = useCallback(() => {
    refetch();
    setCurrentPage(1);
    setTokenStack([null]);
  }, [refetch]);

  const handlePageChange = useCallback(
    async (token: string): Promise<{ items: unknown[]; nextToken: string }> => {
      setCurrentPage((prevPage) => {
        const newPage = token === null ? 1 : prevPage + 1;
        setTokenStack((prevStack) => {
          const newStack = [...prevStack];
          newStack[newPage] = token;
          return newStack;
        });
        return newPage;
      });
      const result = await refetch();
      if (result.data && result.data.getFiles && result.data.getFiles.data) {
        return {
          items: result.data.getFiles.data,
          nextToken: result.data.getFiles.nextToken || '',
        };
      }
      throw new Error('Failed to fetch files');
    },
    [refetch],
  );

  useEffect(() => {
    if (state.isProcessing && state.queue.length > 0) {
      processNextFile();
    }
  }, [state.isProcessing, state.queue, state.currentIndex, processNextFile]);

  useEffect(() => {
    if (filesData?.getFiles) {
      setTotalCount(filesData.getFiles.totalCount || 0);
      setNextToken(filesData.getFiles.nextToken || null);
    }
  }, [filesData]);

  const handleRefresh = useCallback(async () => {
    refetch();
    refetchUser({ input: {} });
  }, [refetch, refetchUser]);

  useEffect(() => {
    // Only start auto-refresh if there are files
    if (files && files.length > 0) {
      const intervalId = setInterval(() => {
        handleRefresh();
      }, 5000); // 5 seconds

      // Cleanup function that runs when component unmounts
      return () => clearInterval(intervalId);
    }
  }, [handleRefresh, files]);

  return (
    <Layout
      backArrow
      startTitle={
        <AddContentMenu
          onYoutubeClick={handleYoutubeClick}
          onWebsiteClick={() => setWebsiteModalOpen(true)}
          onVideoClick={() => handleFileUpload(FileCategory.video)}
          onAudioClick={() => handleFileUpload(FileCategory.audio)}
          onDocumentClick={() => handleFileUpload(FileCategory.document)}
          onRefresh={handleRefresh}
          isLoading={isLoading}
        />
      }
      endAction={
        <FileFilterAndPagination
          status={status}
          handleStatusChange={handleStatusChange}
          totalCount={totalCount}
          nextToken={nextToken}
          handlePageChange={handlePageChange}
        />
      }
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Box sx={{ overflowX: 'auto', width: '100%' }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ py: 1 }}
          >
            <Pagination
              totalCount={totalCount}
              itemsPerPage={20}
              nextToken={nextToken}
              onPageChange={handlePageChange}
            />
          </Stack>
          <Divider sx={{ opacity: 0.2 }} />
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell width="10%">Type</StyledTableCell>
                <StyledTableCell width="30%">Name</StyledTableCell>
                <StyledTableCell width="10%">Status</StyledTableCell>
                <StyledTableCell width="10%">Words</StyledTableCell>
                <StyledTableCell width="15%">Updated At</StyledTableCell>
                <StyledTableCell width="5%"> </StyledTableCell>
              </TableRow>
            </TableHead>
            <Routes>
              <Route
                path="/"
                element={
                  <TableBody>
                    {files?.map((file, index) => (
                      <RowTableContent
                        key={file.id || `file-${index}`}
                        file={file}
                        StyledTableCell={StyledTableCell}
                        refetch={refetch}
                      />
                    ))}
                  </TableBody>
                }
              />
              <Route
                path="/video"
                element={
                  <TableBody>
                    {files
                      ?.filter((file) => file.category === FileCategory.video)
                      .map((file, index) => (
                        <RowTableContent
                          key={file.id || `file-${index}`}
                          file={file}
                          StyledTableCell={StyledTableCell}
                          refetch={refetch}
                        />
                      ))}
                  </TableBody>
                }
              />
              <Route
                path="/audio"
                element={
                  <TableBody>
                    {files
                      ?.filter((file) => file.category === FileCategory.audio)
                      .map((file, index) => (
                        <RowTableContent
                          key={file.id || `file-${index}`}
                          file={file}
                          StyledTableCell={StyledTableCell}
                          refetch={refetch}
                        />
                      ))}
                  </TableBody>
                }
              />
              <Route
                path="/webpage"
                element={
                  <TableBody>
                    {files
                      ?.filter((file) => file.category === FileCategory.web)
                      .map((file, index) => (
                        <RowTableContent
                          key={file.id || `file-${index}`}
                          file={file}
                          StyledTableCell={StyledTableCell}
                          refetch={refetch}
                        />
                      ))}
                  </TableBody>
                }
              />
              <Route
                path="/document"
                element={
                  <TableBody>
                    {files
                      ?.filter(
                        (file) => file.category === FileCategory.document,
                      )
                      .map((file, index) => (
                        <RowTableContent
                          key={file.id || `file-${index}`}
                          file={file}
                          StyledTableCell={StyledTableCell}
                          refetch={refetch}
                        />
                      ))}
                  </TableBody>
                }
              />
            </Routes>
          </StyledTable>
        </Box>
      )}
      <DialogContentAddYoutube
        open={youtubeModalOpen}
        onClose={() => setYoutubeModalOpen(false)}
        onFileAdded={handleFileAdded}
      />
      <DialogContentAddWebsite
        open={websiteModalOpen}
        onClose={() => setWebsiteModalOpen(false)}
        onFileAdded={handleFileAdded}
      />
      <DialogContentAddSpeakerSelect
        open={state.speakerDialogOpen}
        onClose={handleCloseSpeakerDialog}
        selectedFile={state.queue[state.currentIndex]?.file || null}
        onSave={handleSaveSpeakers}
        onCancel={handleCancelCurrentFile}
        onCloseAndCancelAll={handleResetContent}
        numSpeakers={state.tempNumSpeakers}
        setNumSpeakers={handleSpeakerSelection}
      />
    </Layout>
  );
}

export default memo(UserContent);
