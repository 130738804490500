import { UsageDate } from '@duohub/types';
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Box, Paper, Typography } from '@mui/material';

interface ChartProps {
  data: UsageDate[];
  color?: string;
}

const formatDate = (dateString: string) => {
  if (!dateString) return '';

  // Parse DD/MM/YYYY format
  const [day, month, year] = dateString.split('/');
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
};

const formatBytes = (bytes: number) => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${(bytes / Math.pow(k, i)).toFixed(2)} ${sizes[i]}`;
};

const CustomTooltip = ({ active, payload, label, usageKey }: any) => {
  if (!active || !payload || !payload[0]) return null;

  const value = payload[0].payload?.value;
  if (value === undefined) return null;

  const getFormattedValue = (value: number, key: string) => {
    const lowerKey = key?.toLowerCase();
    if (lowerKey.includes('byte')) {
      return formatBytes(value);
    } else if (lowerKey.includes('token')) {
      return `${value.toLocaleString()} tokens`;
    } else {
      return `${value.toLocaleString()} requests`;
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{ p: 2, border: '1px solid #2a2a2a' }}
    >
      <Typography variant="body2">{formatDate(label)}</Typography>
      <Typography variant="body2">
        {getFormattedValue(value, usageKey)}
      </Typography>
    </Paper>
  );
};

export default function Chart({ data, color = '#8884d8' }: ChartProps) {
  const usageKey = Object.keys(data[0]?.usage || {})[0];

  // Flatten the data structure
  const flattenedData = data.map((item) => ({
    date: item.date,
    value: item.usage?.[usageKey] || 0,
  }));

  return (
    <Box sx={{ height: 170 }}>
      <ResponsiveContainer
        width="100%"
        height="100%"
      >
        <AreaChart
          data={flattenedData}
          margin={{ top: 5, right: 0, left: -40, bottom: 5 }}
        >
          <XAxis
            dataKey="date"
            tick={{ fontSize: 10 }}
            tickFormatter={formatDate}
          />
          <Tooltip content={<CustomTooltip usageKey={usageKey} />} />
          <defs>
            <linearGradient
              id={`color${usageKey}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="5%"
                stopColor={color}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={color}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="value"
            stroke={color}
            fillOpacity={1}
            fill={`url(#color${usageKey})`}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
}
