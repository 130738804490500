import { getCurrentUser } from 'aws-amplify/auth';
import { Loading } from 'components/atoms';
import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Hub } from '@aws-amplify/core';

interface AuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(
    null,
  );
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    checkAuthState();
  }, []);

  async function checkAuthState() {
    try {
      await getCurrentUser();
      setIsAuthenticated(true);
    } catch {
      setIsAuthenticated(false);
    }
  }

  useEffect(() => {
    const hubListener = Hub.listen('auth', ({ payload }) => {
      console.log('payload', payload);
      const { event } = payload;
      console.log('event', event);

      if (event === 'customOAuthState') {
        console.log('payload', payload?.data);
        window.location.href = payload?.data;
      }
    });

    return () => {
      hubListener();
    };
  }, [navigate]);

  if (isAuthenticated === null) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    const currentPath = location.pathname;
    if (!currentPath.includes('/signin') && !currentPath.includes('/signup')) {
      console.log('Saving redirect URL:', currentPath);
      localStorage.setItem('redirectUrl', currentPath);
    }

    return (
      <Navigate
        to="/signin"
        state={{ from: location }}
        replace
      />
    );
  }

  return <>{children}</>;
};

export default AuthWrapper;
