import { UsageDate } from '@duohub/types';
import { AreaChart, Area, ResponsiveContainer, XAxis } from 'recharts';
import { Box } from '@mui/material';
import { green, blue, yellow } from '@mui/material/colors';

interface ChartMiniProps {
  data: UsageDate[];
  dataKey: 'tokens' | 'apiRequests' | 'bytes';
}

export default function ChartMini({ data, dataKey }: ChartMiniProps) {
  const getColor = () => {
    switch (dataKey) {
      case 'tokens':
        return green[500];
      case 'bytes':
        return blue[500];
      case 'apiRequests':
        return yellow[700];
      default:
        return '#8884d8';
    }
  };

  const color = getColor();
  const flattenedData = data.map((item) => ({
    date: item.date,
    value: item.usage?.[dataKey] || 0,
  }));

  return (
    <Box sx={{ height: 150 }}>
      <ResponsiveContainer
        width="100%"
        height="100%"
      >
        <AreaChart
          data={flattenedData}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          style={{ cursor: 'pointer' }}
        >
          <XAxis
            dataKey="date"
            tick={false}
          />
          <defs>
            <linearGradient
              id={`colorMini${dataKey}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="5%"
                stopColor={color}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={color}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="value"
            stroke={color}
            fillOpacity={1}
            fill={`url(#colorMini${dataKey})`}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
}
