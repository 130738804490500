import Layout from 'layouts';
import { Route, Routes } from 'react-router-dom';
import Memory from './memory';
import { Stack, Tooltip, Typography, IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { NewMemory } from 'components/molecules';
import { useState } from 'react';
import { Add } from '@mui/icons-material';

export default function MemoryIndex() {
  const location = useLocation();

  const newMemory = location.pathname.includes('new');
  const index =
    !location.pathname.includes('library') &&
    !location.pathname.includes('new');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  return (
    <Layout
      backArrow
      startTitle={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography variant="h4">
              {newMemory ? 'New Graph' : 'Graphs'}
            </Typography>
            {index && (
              <>
                <Tooltip
                  title="Add Graph"
                  placement="bottom"
                >
                  <IconButton onClick={handleOpenDialog}>
                    <Add />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Stack>
        </Stack>
      }
    >
      <NewMemory
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
      />
      <Routes>
        <Route
          path="/"
          element={<Memory />}
        />
      </Routes>
    </Layout>
  );
}
