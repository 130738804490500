import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import {
  AIModels,
  AIProvider,
  AIType,
  Languages,
  PromptPosition,
  PromptRoles,
} from 'types/enums';
import { createPrompt } from './prompts';
import { useGetUserQuery } from 'graphql/generated';
import { useOrganisation } from 'use/organisation';

export const useDefaultTemplate = ({
  botName,
  botDescription,
}: {
  botName: string;
  botDescription: string;
}) => {
  const { selectedOrganisation } = useOrganisation();
  const { data: userData } = useGetUserQuery({
    input: {},
  });
  const user = userData?.getUser;

  const botID = uuid();

  const startConversationPromptInput = useMemo(
    () => ({
      id: uuid(),
      name: 'First Message',
      description: 'Starts the conversation',
      content: createPrompt({
        firstName: user?.firstName,
        lastName: user?.lastName,
        position: PromptPosition.START,
        botName,
        botDescription,
      }),
      userID: user?.id,
      botID,
      organisationID: selectedOrganisation,
      role: PromptRoles.SYSTEM,
      position: PromptPosition.START,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }),
    [
      user?.firstName,
      user?.lastName,
      user?.id,
      botName,
      botDescription,
      botID,
      selectedOrganisation,
    ],
  );

  const mainSystemPromptInput = useMemo(
    () => ({
      id: uuid(),
      name: 'Main System Prompt',
      description: 'Main system prompt',
      content: createPrompt({
        firstName: user?.firstName,
        lastName: user?.lastName,
        position: PromptPosition.MAIN,
        botName,
        botDescription,
      }),
      userID: user?.id,
      botID,
      organisationID: selectedOrganisation,
      role: PromptRoles.SYSTEM,
      position: PromptPosition.MAIN,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }),
    [
      user?.firstName,
      user?.lastName,
      user?.id,
      botName,
      botDescription,
      botID,
      selectedOrganisation,
    ],
  );

  const llmInput = useMemo(
    () => ({
      id: uuid(),
      name: 'OpenAI',
      description: 'Main LLM - OpenAI GPT-4o',
      provider: AIProvider.OPENAI,
      model: AIModels.GPT_4_O,
      type: AIType.LLM,
      options: {
        temperature: 0.2,
        maxTokens: 512,
      },
      userID: user?.id,
      botID,
      organisationID: selectedOrganisation,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }),
    [user, botID, selectedOrganisation],
  );

  const ttsInput = useMemo(
    () => ({
      id: uuid(),
      name: 'Cartesta',
      description: 'Standard voice',
      provider: AIProvider.CARTESIA,
      model: AIModels.SONIC,
      voiceID: '421b3369-f63f-4b03-8980-37a44df1d4e8',
      type: AIType.TTS,
      options: {
        stability: 0.9,
        clarity: 0.2,
        exaggeration: 0.1,
        streamingLatency: 0,
        useSpeakerBoost: false,
        aggregatorOn: false,
        aggregatorCharacters: 100,
        aggregatorTimer: 500,
      },
      userID: user?.id,
      botID,
      organisationID: selectedOrganisation,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }),
    [user, botID, selectedOrganisation],
  );

  const asrInput = useMemo(
    () => ({
      id: uuid(),
      name: 'DeepGram',
      description: 'Streaming ASR',
      provider: AIProvider.DEEPGRAM,
      model: AIModels.NOVA_2,
      type: AIType.ASR,
      language: Languages.EN,
      currentVersion: 1,
      userID: user?.id,
      botID,
      organisationID: selectedOrganisation,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }),
    [user, botID, selectedOrganisation],
  );

  return {
    botID,
    template: {
      startPrompt: startConversationPromptInput,
      mainPrompt: mainSystemPromptInput,
      llm: llmInput,
      tts: ttsInput,
      asr: asrInput,
    },
  };
};
