import { ReactNode } from 'react';
import { RecoilRoot } from 'recoil';
import { UserContextProvider } from './user/state';
import { AppContextProvider } from './app';
import { SnackbarProvider } from './snackbar';
import { CallContextProvider } from './call';
import { ProContextProvider } from './pro';
import UploadContextProvider from './upload';
import { MemoryContextProvider } from './memory';
import { ContentContextProvider } from './content';
import { OrganisationContextProvider } from './organisation';

interface ContextProviderProps {
  children: ReactNode;
}

function ContextProvider({ children }: ContextProviderProps): JSX.Element {
  return (
    <RecoilRoot>
      <OrganisationContextProvider>
        <AppContextProvider>
          <UserContextProvider>
            <CallContextProvider>
              <ProContextProvider>
                <UploadContextProvider>
                  <MemoryContextProvider>
                    <ContentContextProvider>
                      <SnackbarProvider>{children}</SnackbarProvider>
                    </ContentContextProvider>
                  </MemoryContextProvider>
                </UploadContextProvider>
              </ProContextProvider>
            </CallContextProvider>
          </UserContextProvider>
        </AppContextProvider>
      </OrganisationContextProvider>
    </RecoilRoot>
  );
}

export default ContextProvider;

export { useContentReducer as useContent } from './content';
