import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Stack,
  Button,
} from '@mui/material';
import { useGetInvoicePreviewQuery } from 'graphql/generated';
import { useNavigate } from 'react-router-dom';
import { useOrganisation } from 'use/organisation';

interface InvoicePreviewProps {
  goToBilling?: boolean;
}

export default function InvoicePreview({ goToBilling }: InvoicePreviewProps) {
  const { selectedOrganisation } = useOrganisation();
  const { data, isLoading, error } = useGetInvoicePreviewQuery({
    input: { organisationID: selectedOrganisation },
  });
  const navigate = useNavigate();

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error)
    return <Typography color="error">Error loading invoice preview</Typography>;

  const preview = data?.getInvoicePreview?.preview;

  // Format amount helper (converts cents to dollars/currency)
  const formatAmount = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: preview?.currency || 'USD',
    }).format(amount / 100);
  };

  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography variant="h4">Upcoming Invoice</Typography>
        {goToBilling && (
          <Button
            size="small"
            endIcon={<ArrowForward />}
            color="primary"
            sx={{ height: '24px' }}
            onClick={() => navigate('/settings/billing')}
          >
            Billing
          </Button>
        )}
      </Stack>
      <Divider sx={{ my: 2, opacity: 0.5 }} />
      {/* Date Range */}
      <Typography
        variant="body2"
        sx={{ mb: 2 }}
      >
        Period:{' '}
        {new Date(preview?.periodStart).toLocaleDateString('en-US', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })}{' '}
        -{' '}
        {new Date(preview?.periodEnd).toLocaleDateString('en-US', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })}
      </Typography>

      {/* Line Items Table */}
      <Paper sx={{ mb: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {preview?.lineItems?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.description}</TableCell>
                <TableCell align="right">{formatAmount(item.amount)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      {/* Total */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography variant="h6">
          Total USD: {formatAmount(preview?.totalAmount || 0)}
        </Typography>
      </Box>
    </Box>
  );
}
