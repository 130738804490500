import { DailyProvider } from '@daily-co/daily-react';
import { Box, CircularProgress, IconButton } from '@mui/material';
import {
  STATE_ERROR,
  STATE_HAIRCHECK,
  STATE_IDLE,
  STATE_JOINED,
  STATE_CREATING,
  STATE_LEAVING,
} from 'common/call';
import { Audio } from 'components/molecules';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DailyIframe, { DailyCall, DailyEvent } from '@daily-co/daily-js';
import call from 'interface/call';
import { ApiResponse } from 'types/app';
import { CallEnd, Call as CallIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'use/snackbar';
import { useParams } from 'react-router-dom';
import { useUser } from 'use/user';
import { useGetApiKeysQuery, useGetBotQuery } from 'graphql/generated';
import { ApiKey } from '@duohub/types';
import { useOrganisation } from 'use/organisation';

interface CallProps {
  disabled?: boolean;
  variant?: 'button' | 'icon';
  apiKey?: ApiKey;
}
export function Call({ disabled, variant = 'button', apiKey }: CallProps) {
  const [appState, setAppState] = useState<string>(STATE_IDLE);
  const [roomUrl, setRoomUrl] = useState<string | undefined>();
  const [callObject, setCallObject] = useState<DailyCall | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { botID } = useParams<{ botID: string }>();
  const { data: botData } = useGetBotQuery({
    input: { id: botID },
  });
  const { showSnackbar } = useSnackbar();
  const { user } = useUser();
  const { selectedOrganisation } = useOrganisation();
  const { data: apiKeyData, refetch } = useGetApiKeysQuery({
    input: { activeOnly: true, organisationID: selectedOrganisation },
  });
  const apiKeys = useMemo(
    () => apiKeyData?.getApiKeys?.data || [],
    [apiKeyData],
  );

  type Room = {
    url: string;
  };

  const createCall = useCallback(async () => {
    setAppState(STATE_CREATING);
    try {
      const room: Room = (await call.createRoom()) as Room;
      setRoomUrl(room.url);
      return room.url;
    } catch (error) {
      setRoomUrl(undefined);
      setAppState(STATE_IDLE);
      return undefined;
    }
  }, []);

  const startHairCheck = useCallback(
    async (url: string | undefined) => {
      try {
        const newCallObject: DailyCall = DailyIframe.createCallObject();
        window.call = newCallObject;
        setCallObject(newCallObject);
        setAppState(STATE_HAIRCHECK);
        await newCallObject.preAuth({ url: url || roomUrl });
        return newCallObject;
      } catch (e) {
        showSnackbar('Error starting hair check', 'error');
        return undefined;
      }
    },
    [roomUrl, showSnackbar],
  );

  useEffect(() => {
    if (!callObject) return;
    const events: DailyEvent[] = ['joined-meeting', 'left-meeting', 'error'];
    const handleNewMeetingState = () => {
      switch (callObject.meetingState()) {
        case 'joined-meeting':
          setAppState(STATE_JOINED);
          break;
        case 'left-meeting':
          callObject.destroy().then(() => {
            setRoomUrl(undefined);
            setCallObject(null);
            setAppState(STATE_IDLE);
          });
          break;
        case 'error':
          setAppState(STATE_ERROR);
          break;
        default:
          break;
      }
    };

    handleNewMeetingState();

    events.forEach((event) => {
      callObject.on(event, handleNewMeetingState);
    });
  }, [callObject]);

  const startLeavingCall = useCallback(() => {
    if (!callObject) return;
    setLoading(true);
    try {
      if (appState === STATE_ERROR) {
        callObject.destroy().then(() => {
          setRoomUrl(undefined);
          setCallObject(null);
          setAppState(STATE_IDLE);
        });
      } else {
        setAppState(STATE_LEAVING);
        callObject.leave();
      }
    } catch (e) {
      showSnackbar('Error leaving call', 'error');
    } finally {
      setLoading(false);
    }
  }, [callObject, appState, showSnackbar]);

  const inviteBot = useCallback(
    async (url?: string, retryCount = 0): Promise<void> => {
      try {
        console.log('roomUrl', roomUrl);
        if (!roomUrl && !url) {
          showSnackbar('No room URL provided', 'error');
          return;
        }
        console.log('apiKeys', apiKeys);
        console.log('apiKey', apiKey);
        if (apiKeys?.length === 0 && !apiKey) {
          await refetch();
        }
        if (apiKeys.length === 0 && !apiKey) {
          showSnackbar('Twin owner does not have an active API key', 'error');
          return;
        }

        const body = JSON.stringify({
          model: 'basic',
          room: url || roomUrl,
          config: {
            bot_id: botID,
            bot_owner_id: botData?.getBot?.data?.userID,
            participant_id: user?.userId || 'unknown',
          },
        });
        const response = await fetch(
          `${process.env.REACT_APP_DUOHUB_API}/call/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'X-API-Key': apiKey?.id || apiKeys[0].id,
            },
            body,
          },
        );
        console.log(
          ' apiKey?.id || apiKeys[0].id,',
          apiKey?.id || apiKeys[0].id,
        );
        if (!response.ok) {
          if (response.status === 401) {
            const errorData = await response.json();
            console.error('Authentication error:', errorData.detail);

            if (retryCount < 3) {
              console.log(`Retrying... Attempt ${retryCount + 1} of 3`);
              return inviteBot(url, retryCount + 1);
            } else {
              showSnackbar(
                `Authentication failed: ${errorData.detail}`,
                'error',
              );
              return;
            }
          }

          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = (await response.json()) as ApiResponse;
        console.log('Bot invited successfully:', result);
        showSnackbar('Call connected', 'success');
      } catch (error) {
        if (error instanceof Error) {
          showSnackbar(`Error starting call: ${error.message}`, 'error');
        } else {
          showSnackbar('Error starting call', 'error');
        }
      }
    },
    [
      roomUrl,
      apiKeys,
      apiKey,
      botID,
      botData?.getBot?.data?.userID,
      user?.userId,
      showSnackbar,
      refetch,
    ],
  );

  const joinCall = useCallback(
    async (url?: string, newCallObject?: DailyCall) => {
      if (callObject !== null || newCallObject !== null) {
        const callToJoin = newCallObject || callObject;
        await callToJoin!.join({
          url: url || roomUrl,
          startVideoOff: true,
        });
      } else {
        showSnackbar('No call object', 'error');
      }
    },
    [callObject, roomUrl, showSnackbar],
  );

  const initCall = useCallback(async () => {
    try {
      setLoading(true);
      const url = await createCall();
      const newCallObject = await startHairCheck(url);
      await joinCall(url, newCallObject);
      await inviteBot(url);
    } catch (e) {
      showSnackbar('Error initializing call', 'error');
    } finally {
      setLoading(false);
    }
  }, [createCall, startHairCheck, joinCall, inviteBot, showSnackbar]);

  const startCall = useCallback(() => {
    if (apiKeys.length === 0 && !apiKey) {
      showSnackbar('You must have an active API key', 'error');
      return;
    }
    try {
      initCall();
    } catch (e) {
      showSnackbar('Error starting call', 'error');
    }
  }, [initCall, showSnackbar, apiKeys, apiKey]);

  return (
    <Box>
      <DailyProvider callObject={callObject}>
        <Audio />
      </DailyProvider>
      {variant === 'button' && (
        <LoadingButton
          size="small"
          disabled={disabled}
          sx={{ fontWeight: 800, borderRadius: 40, height: 28 }}
          loading={loading}
          onClick={roomUrl ? startLeavingCall : startCall}
          variant="contained"
          color={roomUrl ? 'error' : 'success'}
          startIcon={roomUrl ? <CallEnd /> : <CallIcon />}
        >
          {roomUrl ? 'End' : 'Start'} Call
        </LoadingButton>
      )}
      {variant === 'icon' && (
        <IconButton
          disabled={disabled}
          onClick={roomUrl ? startLeavingCall : startCall}
          size="large"
          color={roomUrl ? 'error' : 'default'}
          sx={{ height: 75, width: 75, border: '1px solid grey' }}
        >
          {loading ? (
            <CircularProgress size={50} />
          ) : roomUrl ? (
            <CallEnd fontSize="large" />
          ) : (
            <CallIcon fontSize="large" />
          )}
        </IconButton>
      )}
    </Box>
  );
}
