import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Stack,
  Link,
  Divider,
  Alert,
  AlertTitle,
} from '@mui/material';
import {
  signUp,
  signIn,
  SignUpInput,
  signInWithRedirect,
  getCurrentUser,
} from 'aws-amplify/auth';
import { Logo } from 'components/atoms';
import { Google as GoogleIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import mixpanel from 'mixpanel-browser';

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const checkAuthState = useCallback(async () => {
    try {
      await getCurrentUser();
      return true;
    } catch {
      return false;
    }
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      const isAuthenticated = await checkAuthState();
      if (isAuthenticated) {
        navigate('/', { replace: true });
      }
    };

    checkAuth();
  }, [checkAuthState, navigate]);

  const handleSignUp = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');
    setLoading(true);
    try {
      const isAuthenticated = await checkAuthState();
      if (isAuthenticated) {
        navigate('/', { replace: true });
        return;
      }
      const signUpInput: SignUpInput = {
        username: email,
        password,
        options: {
          userAttributes: {
            email,
            given_name: givenName,
            family_name: familyName,
          },
        },
      };
      const { isSignUpComplete, userId, nextStep } = await signUp(signUpInput);

      console.log('Sign up successful', { isSignUpComplete, userId, nextStep });
      if (isSignUpComplete) {
        try {
          await signIn({ username: email, password });
          mixpanel.track('sign_up', {
            'Signup Type': 'Email',
          });
          navigate('/', { replace: true });
        } catch (signInError) {
          console.error('Auto sign-in failed:', signInError);
          navigate('/signin');
        }
      } else {
        navigate('/confirm-signup', { state: { username: email, password } });
      }
    } catch (err) {
      console.error('Sign up error:', err);
      if (err instanceof Error) {
        setError(`${err.name} - ${err.message}`);
      } else {
        setError('Failed to sign up. An unknown error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const isAuthenticated = await checkAuthState();
      if (isAuthenticated) {
        navigate('/', { replace: true });
        return;
      }

      // Get and clear redirectUrl from localStorage
      const redirectUrl = localStorage.getItem('redirectUrl');
      localStorage.removeItem('redirectUrl');

      await signInWithRedirect({
        provider: 'Google',
        customState: redirectUrl || null,
      });

      mixpanel.track('sign_up', {
        'Signup Type': 'Google',
      });
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError('Failed to sign in with Google. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
      }}
    >
      <Container maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
          >
            <Logo size="medium" />
          </Stack>
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            sx={{ mt: 4, width: '100%' }}
          >
            <Button
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
              sx={{ mb: 2 }}
            >
              Sign up with Google
            </Button>
            <Divider sx={{ width: '100%' }}>
              <Typography variant="caption">OR</Typography>
            </Divider>
            <Box
              component="form"
              onSubmit={handleSignUp}
              noValidate
              sx={{ mt: 1, width: '100%' }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="givenName"
                label="First Name"
                name="givenName"
                autoComplete="given-name"
                value={givenName}
                onChange={(e) => setGivenName(e.target.value)}
                size="small"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="familyName"
                label="Last Name"
                name="familyName"
                autoComplete="family-name"
                value={familyName}
                onChange={(e) => setFamilyName(e.target.value)}
                size="small"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="small"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                size="small"
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, fontWeight: 'bold' }}
                size="small"
                loading={loading}
              >
                Sign Up
              </LoadingButton>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 1 }}
                spacing={2}
              >
                <Typography variant="body2">
                  Already have an account?
                </Typography>
                <Link
                  href="/signin"
                  variant="body2"
                >
                  Sign in
                </Link>
              </Stack>
            </Box>
          </Stack>
        </Box>
        {error && (
          <Alert
            severity="error"
            sx={{ mt: 2 }}
          >
            <AlertTitle>Failed to sign up</AlertTitle>
            {error}
          </Alert>
        )}
      </Container>
    </Box>
  );
};

export default SignUp;
