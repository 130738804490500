import {
  Box,
  Card,
  CardActionArea,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { Billing as BillingComponent } from '../components';
import Layout from 'layouts/index';
import { Button } from '@mui/material';
import {
  useGetSelfQuery,
  useLazyGetCustomerPortalQuery,
  useUpdateOrganisationMutation,
  useSendBillingInboundMutation,
} from 'graphql/generated';
import { useOrganisation } from 'use/organisation';
import { useEffect, useMemo, useState } from 'react';
import { OrganisationPaymentStatus } from 'types/enums';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'use/snackbar';
import { InvoicePreview } from 'components/organisms';

function AddonCard({
  title,
  price,
  description,
  loading,
  onGetStarted,
}: {
  title: string;
  price: string;
  description: string;
  loading: boolean;
  onGetStarted: () => void;
}) {
  return (
    <Card
      sx={{ p: 4, height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Typography
        variant="h4"
        gutterBottom
      >
        {title}
      </Typography>
      <Typography
        variant="h5"
        color="primary"
        gutterBottom
      >
        {price}
      </Typography>
      <Typography
        variant="body2"
        sx={{ mb: 2, flex: 1 }}
      >
        {description}
      </Typography>
      <LoadingButton
        variant="outlined"
        size="small"
        sx={{ height: 24 }}
        loading={loading}
        onClick={onGetStarted}
      >
        Get Started
      </LoadingButton>
    </Card>
  );
}

export default function Billing() {
  const { selectedOrganisation } = useOrganisation();
  const [getCustomerPortal, { isLoading: isLoadingCustomerPortal }] =
    useLazyGetCustomerPortalQuery();
  const [updateOrganisation, { isLoading: isLoadingUpdateOrganisation }] =
    useUpdateOrganisationMutation();
  const { data: selfData, refetch: refetchSelf } = useGetSelfQuery(
    {
      input: {
        organisationID: selectedOrganisation,
      },
    },
    {
      skip: !selectedOrganisation,
      refetchOnMountOrArgChange: true,
    },
  );
  const organisation = useMemo(
    () => selfData?.getSelf?.user[0]?.organisation,
    [selfData],
  );
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [newBillingEmail, setNewBillingEmail] = useState('');
  const { showSnackbar } = useSnackbar();
  const [sendBillingInbound] = useSendBillingInboundMutation();
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(
    {},
  );

  useEffect(() => {
    if (organisation) {
      setNewBillingEmail(organisation.billingEmail);
    }
  }, [organisation]);

  const handleGetCustomerPortal = async () => {
    try {
      const { getCustomerPortal: customerPortal } = await getCustomerPortal({
        input: {
          organisationID: selectedOrganisation,
        },
      }).unwrap();
      console.log(JSON.stringify(customerPortal, null, 2));
      window.location.href = customerPortal.url;
    } catch (error) {
      console.error('Error fetching customer portal data:', error);
    }
  };

  const handleUpdateBillingEmail = async () => {
    try {
      await updateOrganisation({
        input: {
          id: selectedOrganisation,
          billingEmail: newBillingEmail,
        },
      }).unwrap();
      await refetchSelf();
      setOpenEmailDialog(false);
      setNewBillingEmail('');
    } catch (error) {
      console.error('Error updating billing email:', error);
    }
  };

  const handleGetStarted = async (product: string) => {
    setLoadingStates((prev) => ({ ...prev, [product]: true }));
    try {
      await sendBillingInbound({
        input: {
          content: `Organisation ${selfData?.getSelf?.user[0]?.organisation?.name} (${selfData?.getSelf?.user[0]?.organisation?.id}) \nis interested in ${product}`,
        },
      });

      showSnackbar(`We'll be in touch about ${product} shortly`, 'success');
    } catch (error) {
      console.error('Error sending billing inbound:', error);
      showSnackbar('Something went wrong', 'error');
    } finally {
      setLoadingStates((prev) => ({ ...prev, [product]: false }));
    }
  };

  return (
    <Layout startTitle={<Typography variant="h4">Billing</Typography>}>
      <Box mb={10}>
        <BillingComponent />

        {organisation?.paymentStatus === OrganisationPaymentStatus.active && (
          <Box
            mt={4}
            mb={8}
          >
            <Typography variant="h4">Details</Typography>
            <Divider sx={{ my: 2, opacity: 0.5 }} />
            <Stack
              direction="column"
              spacing={2}
            >
              <Card>
                <CardActionArea
                  sx={{ p: 2 }}
                  onClick={handleGetCustomerPortal}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack
                      spacing={2}
                      direction="row"
                    >
                      <Typography variant="h5">Payment Method</Typography>
                      {isLoadingCustomerPortal && (
                        <CircularProgress size={20} />
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                    >
                      <Typography variant="body1">
                        {organisation?.paymentMethodLast4}
                      </Typography>
                      <Typography variant="body1">
                        {organisation?.paymentMethodExpiryMonth} /{' '}
                        {organisation?.paymentMethodExpiryYear}
                      </Typography>
                    </Stack>
                  </Stack>
                </CardActionArea>
              </Card>
              <Card>
                <CardActionArea
                  sx={{ p: 2 }}
                  onClick={() => setOpenEmailDialog(true)}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack direction="row">
                      <Typography variant="h5">Billing Email</Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                    >
                      <Typography variant="body1">
                        {organisation?.billingEmail}
                      </Typography>
                    </Stack>
                  </Stack>
                </CardActionArea>
              </Card>
            </Stack>
          </Box>
        )}
        {organisation?.paymentStatus === OrganisationPaymentStatus.active && (
          <InvoicePreview />
        )}
        {/* Compliance Section */}
        <Box
          mt={4}
          mb={8}
        >
          <Typography
            variant="h4"
            gutterBottom
          >
            Compliance & Security
          </Typography>
          <Divider sx={{ my: 2, opacity: 0.5 }} />
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <AddonCard
                title="HIPAA Add-On"
                price="$99/month"
                description="For healthcare compliance, get a BAA, certificates of security controls, and assistance with implementation"
                loading={loadingStates['HIPAA Add-On']}
                onGetStarted={() => handleGetStarted('HIPAA Add-On')}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Performance Section */}
        <Box
          mt={4}
          mb={8}
        >
          <Typography
            variant="h4"
            gutterBottom
          >
            Performance & Infrastructure
          </Typography>
          <Divider sx={{ my: 2, opacity: 0.5 }} />
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <AddonCard
                title="Dedicated Graph Generation Deployment"
                price="$800/month"
                description="Unlimited graph generation, with no metering, in an always-on environment"
                loading={loadingStates['Dedicated Graph Generation Deployment']}
                onGetStarted={() =>
                  handleGetStarted('Dedicated Graph Generation Deployment')
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <AddonCard
                title="Faster Ingestion"
                price="$300/month"
                description="Get up to 4x faster ingestion with priority queuing and more compute available to your organisation"
                loading={loadingStates['Faster Ingestion']}
                onGetStarted={() => handleGetStarted('Faster Ingestion')}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Support Section */}
        <Box
          mt={4}
          mb={8}
        >
          <Typography
            variant="h4"
            gutterBottom
          >
            Support Options
          </Typography>
          <Divider sx={{ my: 2, opacity: 0.5 }} />
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <AddonCard
                title="Priority Support"
                price="$50/month"
                description="Get an answer to your queries within 12 hours, 365 days a week, up to 10 hours set-up assistance"
                loading={loadingStates['Priority Support']}
                onGetStarted={() => handleGetStarted('Priority Support')}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <AddonCard
                title="VIP Support"
                price="$500/month"
                description="First reply to your queries within 2 hours, 365 days a week, up to 100 hours set-up assistance"
                loading={loadingStates['VIP Support']}
                onGetStarted={() => handleGetStarted('VIP Support')}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Pricing Section */}
        <Box my={4}>
          <Typography
            variant="h4"
            gutterBottom
          >
            Pricing Options
          </Typography>
          <Divider sx={{ my: 2, opacity: 0.5 }} />
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <AddonCard
                title="Provisioned Pricing"
                price="Variable"
                description="Pay up front and get cheaper pricing and complete certainty over your bills"
                loading={loadingStates['Provisioned Pricing']}
                onGetStarted={() => handleGetStarted('Provisioned Pricing')}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <AddonCard
                title="On-Premise"
                price="Variable"
                description="Run your own infrastructure, with no metering, in your own data centre. Can include on-premise ingestion engine or use our API with on-premise storage and retrieval. "
                loading={loadingStates['On-Premise']}
                onGetStarted={() => handleGetStarted('On-Premise')}
              />
            </Grid>
          </Grid>
        </Box>

        <Dialog
          open={openEmailDialog}
          onClose={() => setOpenEmailDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            <Typography variant="h4">Update Billing Email</Typography>
          </DialogTitle>
          <Divider sx={{ my: 0, opacity: 0.5 }} />
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="New Billing Email"
              type="email"
              fullWidth
              value={newBillingEmail}
              onChange={(e) => setNewBillingEmail(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Button
                size="small"
                sx={{ height: 24 }}
                onClick={() => setOpenEmailDialog(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isLoadingUpdateOrganisation}
                size="small"
                variant="contained"
                sx={{ height: 24 }}
                onClick={handleUpdateBillingEmail}
                disabled={isLoadingUpdateOrganisation || !newBillingEmail}
              >
                Update
              </LoadingButton>
            </Stack>
          </DialogActions>
        </Dialog>
      </Box>
    </Layout>
  );
}
