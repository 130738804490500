import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogContent,
  Typography,
  Stack,
  IconButton,
  Divider,
  TextField,
  Box,
} from '@mui/material';
import { SelectOrganisation } from 'components/atoms';
import {
  useCreateOrganisationMutation,
  useGetSelfQuery,
} from 'graphql/generated';
import { useState } from 'react';
import { useOrganisation } from 'use/organisation';

export const SelectDialogAddOrganisation = (): JSX.Element => {
  const {
    openDialogAddOrganisation,
    setOpenDialogAddOrganisation,
    setSelectedOrganisation,
  } = useOrganisation();
  const [organisationName, setOrganisationName] = useState<string>('');
  const [createOrganisation] = useCreateOrganisationMutation();
  const { refetch } = useGetSelfQuery({ input: {} });
  const [isCreatingOrganisation, setIsCreatingOrganisation] = useState(false);

  const handleCreateOrganisation = async () => {
    try {
      setIsCreatingOrganisation(true);
      const { createOrganisation: res } = await createOrganisation({
        input: {
          name: organisationName,
        },
      }).unwrap();
      setSelectedOrganisation(res.data?.id);
      setOrganisationName('');
      await refetch();
      setOpenDialogAddOrganisation(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsCreatingOrganisation(false);
    }
  };

  return (
    <Box>
      <SelectOrganisation />
      <Dialog
        fullWidth
        open={openDialogAddOrganisation}
        onClose={() => setOpenDialogAddOrganisation(false)}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ px: 2, py: 1 }}
        >
          <Typography variant="h4">New Organisation</Typography>
          <IconButton onClick={() => setOpenDialogAddOrganisation(false)}>
            <Close />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Organisation Name"
              value={organisationName}
              onChange={(e) => setOrganisationName(e.target.value)}
            />
            <LoadingButton
              loading={isCreatingOrganisation}
              onClick={handleCreateOrganisation}
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              sx={{ fontWeight: 600 }}
            >
              Create
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
