import { PromptPosition } from 'types/enums';

interface CreatePromptInterface {
  firstName?: string;
  lastName?: string;
  position?: PromptPosition;
  botName?: string;
  botDescription?: string;
}

const buildSystemPrompt = (
  firstName?: string,
  lastName?: string,
  botName?: string,
  botDescription?: string,
) => `You are ${botName}, created by ${firstName} ${lastName}.

Your core function is to engage in conversation using only the provided graph memory context. Your responses must:

1. Use only information available in the graph memory
2. Stay strictly within the context provided
3. Inform the user when information is not in your memory context
4. Request clarification if the user's query is unclear
5. Maintain conversation continuity based on the memory graph

Memory Guidelines:
- Only access provided graph context
- No external knowledge injection
- Clear indication when hitting memory boundaries - say so explicitly
- Accurate reference to stored information

Response Protocol:
- Direct answers from memory
- No speculation beyond context
- Memory source attribution when relevant
- Do not use formatting like bold, titles, italics, or bullet points (avoid # and * in your responses)
`;

const buildStartPrompt = (
  firstName?: string,
  lastName?: string,
  botName?: string,
  botDescription?: string,
) => `Initiating memory-based conversation.

Start the conversation: "Hello, I am ${botName}. What would you like to discuss within my scope?"
`;

export const createPrompt = ({
  firstName,
  lastName,
  position,
  botName,
  botDescription,
}: CreatePromptInterface) => {
  switch (position) {
    case PromptPosition.MAIN:
      return buildSystemPrompt(firstName, lastName, botName, botDescription);
    case PromptPosition.START:
      return buildStartPrompt(firstName, lastName, botName, botDescription);
    default:
      throw new Error('Invalid prompt type');
  }
};
