import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
} from 'react';
import { useContentReducer, State, Action, QueueItem } from './index';
import { FileCategory, FileType } from 'types/enums';
import { useUploadQueue } from 'use/upload';
import { getAcceptedFiles } from 'common/utils';
import { useOrganisation } from 'use/organisation';

interface ContentContextType {
  state: State;
  dispatch: React.Dispatch<Action>;
  addFileToUploadQueue: (item: QueueItem) => void;
  processNextFile: () => void;
  handleFileUpload: (fileCategory: FileCategory) => void;
  handleSpeakerSelection: (speakers: number) => void;
  handleSaveSpeakers: () => void;
  handleCloseSpeakerDialog: () => void;
  handleCancelCurrentFile: () => void;
  handleResetContent: () => void;
  handleProcessSelectedFiles: (
    files: File[],
    fileCategory: FileCategory,
    memoryID?: string,
  ) => void;
}

const ContentContext = createContext<ContentContextType | undefined>(undefined);

export function ContentContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useContentReducer();
  const { addToQueue } = useUploadQueue();
  const { selectedOrganisation } = useOrganisation();

  const addFileToUploadQueue = useCallback(
    (item: QueueItem) => {
      console.log('Adding file to queue:', item);
      addToQueue([
        {
          file: item.file,
          appFile: {
            id: item.id,
            name: item.file.name,
            extension: item.file.name.split('.').pop() || '',
            category: item.category,
            organisationID: selectedOrganisation,
            fileType:
              item.category === FileCategory.audio
                ? FileType.audio_upload
                : item.category === FileCategory.video
                  ? FileType.video_upload
                  : item.category === FileCategory.web
                    ? FileType.website_bulk
                    : FileType.document,
            numSpeakers: item.numSpeakers,
            memoryID: item.memoryID,
          },
          uploadStatus: 'pending',
          progress: 0,
          sampleIndexID: item.memoryID || null,
          createSample: item.memoryID ? true : false,
          sampleType: item.memoryID ? 'memory' : 'voice',
        },
      ]);
    },
    [addToQueue, selectedOrganisation],
  );

  const processNextFile = useCallback(() => {
    console.log(
      'Processing next file, queue:',
      state.queue,
      'currentIndex:',
      state.currentIndex,
    );
    const currentItem = state.queue[state.currentIndex];

    if (currentItem) {
      console.log('Current item:', currentItem);
      if (
        currentItem.category === FileCategory.audio ||
        currentItem.category === FileCategory.video
      ) {
        console.log('Prompting for speaker selection:', currentItem.file.name);
        dispatch({ type: 'PROMPT_SPEAKER_SELECTION' });
      } else {
        console.log(
          'Adding non-audio/video file to upload queue:',
          currentItem.file.name,
        );
        addFileToUploadQueue(currentItem);
        dispatch({ type: 'NEXT_FILE' });
      }
    } else {
      console.log('All files processed, final queue state:', state.queue);
    }
  }, [state.queue, state.currentIndex, dispatch, addFileToUploadQueue]);

  const handleFileUpload = useCallback(
    (fileCategory: FileCategory) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = Object.keys(getAcceptedFiles(fileCategory)).join(',');
      input.multiple = true;
      input.onchange = (event) => {
        const files = (event.target as HTMLInputElement).files;
        if (files) {
          console.log(
            'Files selected:',
            Array.from(files).map((f) => f.name),
          );
          dispatch({
            type: 'ADD_FILES',
            payload: { files: Array.from(files), category: fileCategory },
          });
          console.log('Queue after adding files:', state.queue);
          processNextFile();
        }
      };
      input.click();
    },
    [dispatch, processNextFile, state.queue],
  );

  const handleProcessSelectedFiles = useCallback(
    (files: File[], fileCategory: FileCategory, memoryID?: string) => {
      console.log(
        'Processing selected files:',
        Array.from(files).map((f) => f.name),
      );
      dispatch({
        type: 'ADD_FILES',
        payload: { files: Array.from(files), category: fileCategory, memoryID },
      });
      console.log('Queue after adding files:', state.queue);
      processNextFile();
    },
    [dispatch, processNextFile, state.queue],
  );

  const handleSpeakerSelection = useCallback(
    (speakers: number) => {
      console.log('Temporary speaker selection:', speakers);
      dispatch({
        type: 'SET_TEMP_SPEAKERS',
        payload: { numSpeakers: speakers },
      });
    },
    [dispatch],
  );

  const handleSaveSpeakers = useCallback(() => {
    console.log('Saving speaker selection');
    dispatch({ type: 'SAVE_SPEAKERS' });

    const currentItem = state.queue[state.currentIndex];
    if (currentItem) {
      console.log('Adding file to upload queue:', currentItem.file.name);
      addFileToUploadQueue({
        ...currentItem,
        numSpeakers: state.tempNumSpeakers,
      });
      dispatch({ type: 'NEXT_FILE' });
    }
  }, [
    dispatch,
    state.queue,
    state.currentIndex,
    state.tempNumSpeakers,
    addFileToUploadQueue,
  ]);

  const handleCloseSpeakerDialog = useCallback(() => {
    dispatch({ type: 'CLOSE_SPEAKER_DIALOG' });
  }, [dispatch]);

  const handleCancelCurrentFile = useCallback(() => {
    dispatch({ type: 'CANCEL_CURRENT_FILE' });
  }, [dispatch]);

  const handleResetContent = useCallback(() => {
    dispatch({ type: 'RESET' });
  }, [dispatch]);

  const contextValue = {
    state,
    dispatch,
    addFileToUploadQueue,
    processNextFile,
    handleFileUpload,
    handleSpeakerSelection,
    handleSaveSpeakers,
    handleCloseSpeakerDialog,
    handleCancelCurrentFile,
    handleResetContent,
    handleProcessSelectedFiles,
  };

  return (
    <ContentContext.Provider value={contextValue}>
      {children}
    </ContentContext.Provider>
  );
}

export function useContent() {
  const context = useContext(ContentContext);
  if (context === undefined) {
    throw new Error('useContent must be used within a ContentProvider');
  }
  return context;
}
