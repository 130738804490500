import { createContext, useContext, useState, ReactNode, useMemo } from 'react';

interface OrganisationContextState {
  selectedOrganisation: string;
  setSelectedOrganisation: (selectedOrganisation: string) => void;
  openDialogAddOrganisation: boolean;
  setOpenDialogAddOrganisation: (openDialogAddOrganisation: boolean) => void;
}

const OrganisationContext = createContext<OrganisationContextState | undefined>(
  undefined,
);

export const useOrganisationContext = () => {
  const context = useContext(OrganisationContext);
  if (!context) {
    throw new Error(
      'useOrganisationContext must be used within a OrganisationContextProvider',
    );
  }
  return context;
};

interface OrganistionContextProviderProps {
  children: ReactNode;
}

export function OrganisationContextProvider({
  children,
}: OrganistionContextProviderProps): JSX.Element {
  const [selectedOrganisation, setSelectedOrganisation] = useState<string>('');
  const [openDialogAddOrganisation, setOpenDialogAddOrganisation] =
    useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      setSelectedOrganisation,
      selectedOrganisation,
      openDialogAddOrganisation,
      setOpenDialogAddOrganisation,
    }),
    [selectedOrganisation, openDialogAddOrganisation],
  );

  return (
    <OrganisationContext.Provider value={contextValue}>
      {children}
    </OrganisationContext.Provider>
  );
}
