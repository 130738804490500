import { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  List,
  Toolbar,
  Box,
  CssBaseline,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Tooltip,
  Collapse,
  Stack,
  Skeleton,
  Typography,
  CircularProgress,
} from '@mui/material';
import {
  ExpandMore,
  ExpandLess,
  Logout,
  HelpCenter,
  MonetizationOn,
  WorkspacesOutlined,
  AccountBalanceOutlined,
  AccountBalance,
  Workspaces,
  Work,
  WorkOutlineOutlined,
} from '@mui/icons-material';
import { useApp } from 'use/app';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import { useUser } from 'use/user';
import { v4 as uuid } from 'uuid';
import { ColorVariant, SideBarItemProps } from 'types/app';
import { MainSideBarItems } from 'common/nav';
import { useSnackbar } from 'use/snackbar';
import { AppBar, Drawer, DrawerHeader } from './components/skeleton';
import UserTitle from './components/usertitle';
import SidebarItem from './components/sidebaritem';
import { ButtonBack, IconAlert, Logo } from 'components/atoms';
import { yellow } from '@mui/material/colors';
import {
  OrganisationPaymentStatus,
  OrganisationUserRoleType,
} from 'types/enums';
import { useDispatch } from 'react-redux';
import { resetState } from 'store';
import { Loading } from 'components/atoms';
import { useGetSelfQuery, useLazyGetCheckoutQuery } from 'graphql/generated';
import mixpanel from 'mixpanel-browser';
import { SelectDialogAddOrganisation } from 'components/molecules';
import { useOrganisation } from 'use/organisation';

interface AppDrawerProps {
  children: ReactNode;
  endAction?: ReactNode;
  startTitle?: ReactNode;
  backArrow?: boolean;
  backArrowPath?: string;
  middleAction?: ReactNode;
  noticeBanner?: string;
  noticeBannerSeverity?: ColorVariant;
  noticeBannerCTA?: ReactNode;
}

export default function AppDrawer({
  endAction,
  startTitle,
  children,
  backArrow,
  backArrowPath,
  middleAction,
  noticeBanner,
  noticeBannerSeverity,
  noticeBannerCTA,
}: AppDrawerProps) {
  const { selectedOrganisation } = useOrganisation();
  const { data: selfData } = useGetSelfQuery(
    {
      input: { organisationID: selectedOrganisation },
    },
    { skip: !selectedOrganisation, refetchOnMountOrArgChange: true },
  );
  const {
    isZendeskOpen,
    setIsZendeskOpen,
    drawerOpen,
    accountSettingsOpen,
    setAccountSettingsOpen,
  } = useApp();
  const { showSnackbar } = useSnackbar();
  const { getUser } = useUser();
  const dispatch = useDispatch();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [getCheckout, { isLoading: isGettingCheckout }] =
    useLazyGetCheckoutQuery();

  const user = selfData?.getSelf?.user[0]?.user;
  const organisationUser = selfData?.getSelf?.user[0];
  const organisation = selfData?.getSelf?.user[0]?.organisation;

  useEffect(() => {
    const fetchUser = async () => {
      await getUser();
    };
    fetchUser();
  }, [getUser]);

  const { firstName, lastName } = user ?? {};

  const navigate = useNavigate();
  const location = useLocation();

  const handleGetCheckout = async () => {
    const result = await getCheckout({
      input: {
        organisationID: selectedOrganisation,
      },
    });
    mixpanel.track('get_checkout');
    window.location.href = result.data?.getCheckout.url;
  };

  const checkAuthState = useCallback(async () => {
    try {
      await getCurrentUser();
      return true;
    } catch {
      return false;
    }
  }, []);

  const handleSignOut = async () => {
    try {
      const isAuthenticated = await checkAuthState();
      if (!isAuthenticated) {
        navigate('/signin');
        return;
      }
      setIsLoggingOut(true);
      if (window.zE) {
        window?.zE('messenger', 'logoutUser');
        setIsZendeskOpen(false);
      }
      await signOut();
      dispatch(resetState());
      navigate('/signin');
    } catch (err) {
      console.error('signing out error', err);
      showSnackbar('Failed to sign out', 'error');
    } finally {
      setIsLoggingOut(false);
    }
  };

  const handleSignOutClick = () => {
    handleSignOut();
  };

  const showZendeskWidget = () => {
    if (isZendeskOpen) {
      window.zE('messenger', 'close');
      window.zE('messenger', 'hide');
      setIsZendeskOpen(false);
    } else {
      window.zE('messenger', 'show');
      window.zE('messenger', 'open');
      setIsZendeskOpen(true);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        elevation={1}
        position="fixed"
        open={drawerOpen}
        sx={{ minHeight: '48px!important' }}
      >
        {/* {noticeBanner && ( */}
        {/* <Stack
          direction="row"
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: (theme) => theme.palette.warning.dark,
            color: 'white',
            padding: 1,
            pr: 2,
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <IconAlert severity={'warning'} />
            <Typography>
              Due to an issue with one of our infrastructure partners, some
              GPU-dependent services are hanging while attempting to start. Our
              partners are investigating the issue. We apologize for the
              inconvenience.
            </Typography>
          </Stack>
          {noticeBannerCTA}
        </Stack> */}
        {/* )} */}
        <Toolbar
          disableGutters
          sx={{
            minHeight: '48px!important',
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)',
            borderBottom: '0.1px solid #2e2e2e',
          }}
        >
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={backArrow ? 2 : 4}
          >
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
            >
              {backArrow && <ButtonBack backArrowPath={backArrowPath} />}
              {startTitle ? startTitle : <UserTitle />}
            </Stack>
            {middleAction && middleAction}
            {endAction && endAction}
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        elevation={0}
        variant="permanent"
        open={drawerOpen}
      >
        <DrawerHeader sx={{ mt: 1 }}>
          <Box sx={{ ml: 1.5 }}>
            <Logo size="drawer" />
          </Box>
        </DrawerHeader>
        <Box
          mt={1}
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          height="100%"
        >
          <Box>
            <SelectDialogAddOrganisation />
            <List>
              {MainSideBarItems.map((item: SideBarItemProps) => {
                return (
                  <SidebarItem
                    key={uuid()}
                    title={item.title}
                    iconFilled={item.iconFilled}
                    iconOutlined={item.iconOutlined}
                    path={item.path}
                  />
                );
              })}
            </List>
          </Box>
          <Box>
            <List>
              {organisation?.paymentStatus?.includes(
                OrganisationPaymentStatus.free,
              ) &&
              organisationUser?.roleType === OrganisationUserRoleType.admin ? (
                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                >
                  <Tooltip
                    title="Add a credit card to get $10 Free"
                    placement="right"
                  >
                    <ListItemButton
                      onClick={() => handleGetCheckout()}
                      sx={{
                        pl: 3,
                      }}
                    >
                      <ListItemIcon>
                        {isGettingCheckout ? (
                          <CircularProgress size={20} />
                        ) : (
                          <MonetizationOn sx={{ color: yellow[300] }} />
                        )}
                      </ListItemIcon>
                      <ListItemText primary="Get $10 Free" />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              ) : null}
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
              >
                <Tooltip
                  title="Help"
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 3 }}
                    onClick={showZendeskWidget}
                  >
                    <ListItemIcon>
                      <HelpCenter />
                    </ListItemIcon>
                    <ListItemText primary="Help" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            </List>
            <Collapse in={accountSettingsOpen}>
              <List>
                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                >
                  <ListItemButton
                    onClick={() => navigate('/settings/organisation')}
                    selected={location.pathname === '/settings/organisation'}
                    sx={{ pl: 3 }}
                  >
                    <ListItemIcon>
                      {location.pathname === '/settings/organisation' ? (
                        <Work />
                      ) : (
                        <WorkOutlineOutlined />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Organisation" />
                  </ListItemButton>
                </ListItem>

                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                >
                  <ListItemButton
                    onClick={() => navigate('/settings/team')}
                    selected={location.pathname === '/settings/team'}
                    sx={{ pl: 3 }}
                  >
                    <ListItemIcon>
                      {location.pathname === '/settings/team' ? (
                        <Workspaces />
                      ) : (
                        <WorkspacesOutlined />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Team" />
                  </ListItemButton>
                </ListItem>
                {organisationUser?.roleType ===
                  OrganisationUserRoleType.admin && (
                  <ListItem
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      onClick={() => navigate('/settings/billing')}
                      selected={location.pathname === '/settings/billing'}
                      sx={{ pl: 3 }}
                    >
                      <ListItemIcon>
                        {location.pathname === '/settings/billing' ? (
                          <AccountBalance />
                        ) : (
                          <AccountBalanceOutlined />
                        )}
                      </ListItemIcon>
                      <ListItemText primary="Billing" />
                    </ListItemButton>
                  </ListItem>
                )}
                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                >
                  <ListItemButton
                    sx={{ pl: 3 }}
                    onClick={handleSignOutClick}
                  >
                    <ListItemIcon>
                      <Logout />
                    </ListItemIcon>
                    <ListItemText primary="Log Out" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
            <List>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
              >
                <Tooltip
                  title={'Account'}
                  placement="right"
                >
                  <ListItemButton
                    onClick={() => setAccountSettingsOpen(!accountSettingsOpen)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: drawerOpen ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Avatar
                        sx={{
                          background:
                            'linear-gradient(45deg, #2C3539, #1C2124)',
                          opacity: 0.5,
                        }}
                      >
                        {firstName ? (
                          <Typography
                            variant="h3"
                            color="white"
                          >
                            {firstName.charAt(0)}
                          </Typography>
                        ) : (
                          <Skeleton
                            height={20}
                            width={20}
                          />
                        )}
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        firstName ? (
                          `${firstName || ''} ${lastName || ''}`
                        ) : (
                          <Skeleton width={100} />
                        )
                      }
                      primaryTypographyProps={{
                        noWrap: true,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    />
                    {accountSettingsOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, pt: 0 }}
      >
        <DrawerHeader />
        {isLoggingOut ? <Loading /> : children}
      </Box>
    </Box>
  );
}
