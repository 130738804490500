import {
  Container,
  Button,
  Paper,
  Stack,
  Typography,
  LinearProgress,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useOrganisation } from 'use/organisation';
import { useGetSelfQuery } from 'graphql/generated';

export default function SuccessPage() {
  const navigate = useNavigate();
  const { selectedOrganisation } = useOrganisation();
  const { data: selfData, refetch: refetchSelf } = useGetSelfQuery(
    {
      input: {
        organisationID: selectedOrganisation,
      },
    },
    { skip: !selectedOrganisation, refetchOnMountOrArgChange: true },
  );
  const user = selfData?.getSelf.user[0].user;

  const exit = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const [progress, setProgress] = useState(0);

  const handleCompletion = useCallback(async () => {
    if (selectedOrganisation) {
      await refetchSelf();
      exit();
    } else {
      exit();
    }
  }, [exit, refetchSelf, selectedOrganisation]);

  useEffect(() => {
    const totalDuration = 5000;
    const interval = 50;
    const increment = (interval / totalDuration) * 100;

    const timer = setInterval(async () => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(timer);
          handleCompletion();
          return 100;
        }
        return prevProgress + increment;
      });
    }, interval);

    return () => clearInterval(timer);
  }, [exit, handleCompletion, refetchSelf]);

  return (
    <Paper
      sx={{
        width: '100vw',
        height: '100vh',
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{
          width: '100%',
        }}
      >
        <img
          src="/logos/icon-white.svg"
          alt="duohub"
          style={{ width: 32, height: 32 }}
        />
        <Button onClick={exit}>Skip</Button>
      </Stack>
      <Container
        maxWidth="md"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          spacing={4}
          width="100%"
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h1">
              You're in good company
              {user?.firstName ? `, ${user?.firstName}` : ''}!
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={progress}
          />
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            spacing={4}
          >
            {' '}
            <img
              src="/logos/daily.svg"
              alt="duohub"
              style={{ width: 'auto', height: 17 }}
            />
            <img
              src="/logos/lockheed.svg"
              alt="duohub"
              style={{ width: 'auto', height: 28 }}
            />
            <img
              src="/logos/elevenlabs-logo-white.svg"
              alt="duohub"
              style={{ width: 'auto', height: 16, paddingBottom: 2 }}
            />
          </Stack>
        </Stack>
      </Container>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      ></Stack>
    </Paper>
  );
}
