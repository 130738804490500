import { LoadingButton } from '@mui/lab';
import {
  Box,
  Stack,
  TextField,
  Typography,
  Dialog,
  Divider,
} from '@mui/material';
import {
  useCreateOrganisationMutation,
  useGetSelfQuery,
} from 'graphql/generated';
import { useState } from 'react';
import { useOrganisation } from 'use/organisation';

interface ModalOnboardingProps {
  open: boolean;
  onClose: () => void;
  disableEscapeKeyDown?: boolean;
}

export default function ModalOnboarding({
  open,
  onClose,
  disableEscapeKeyDown = false,
}: ModalOnboardingProps) {
  const [orgName, setOrgName] = useState('');
  const [createOrganisation, { isLoading: isCreatingOrganisation }] =
    useCreateOrganisationMutation();
  const { setSelectedOrganisation } = useOrganisation();
  const { refetch: refetchSelf } = useGetSelfQuery({ input: {} });

  const handleSubmit = async () => {
    try {
      const res = await createOrganisation({
        input: {
          name: orgName,
        },
      }).unwrap();
      console.log(res);
      const organisationID = res.createOrganisation.data.id;
      setSelectedOrganisation(organisationID);
      await refetchSelf();
      onClose();
    } catch (error) {
      console.error('Failed to create organisation:', error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <Stack
        spacing={4}
        sx={{ p: 2 }}
      >
        <Typography variant="h4">Create an organization</Typography>
      </Stack>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <Stack
          spacing={3}
          sx={{ width: '100%' }}
        >
          <TextField
            label="Organization Name"
            value={orgName}
            onChange={(e) => setOrgName(e.target.value)}
            fullWidth
            helperText="An organization is a distinct entity that can own content and memory resources like graphs, can have users, and has its own billing method."
          />
          <LoadingButton
            size="small"
            variant="contained"
            onClick={handleSubmit}
            disabled={!orgName}
            loading={isCreatingOrganisation}
            sx={{ fontWeight: 'bold', height: 24 }}
          >
            Create Organization
          </LoadingButton>
        </Stack>
      </Box>
    </Dialog>
  );
}
