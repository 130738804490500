import { UsageDate } from '@duohub/types';
import { Box, Card, Typography } from '@mui/material';
import { Chart } from 'components/molecules';
import { green, blue, yellow } from '@mui/material/colors';

interface ChartUsageProps {
  usageData: UsageDate[];
  fromDate: string;
  toDate: string;
  onFromDateChange: (date: string) => void;
  onToDateChange: (date: string) => void;
}

export default function ChartUsage({ usageData }: ChartUsageProps) {
  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <Box p={2}>
          <Typography variant="h5">Graph Generation Tokens</Typography>
          <Chart
            data={usageData.map((item) => ({
              ...item,
              usage: { tokens: item.usage.tokens },
            }))}
            color={green[500]}
          />
        </Box>
      </Card>
      <Card sx={{ mt: 2 }}>
        <Box p={2}>
          <Typography variant="h5">Storage</Typography>
          <Chart
            data={usageData.map((item) => ({
              ...item,
              usage: { bytes: item.usage.bytes },
            }))}
            color={blue[500]}
          />
        </Box>
      </Card>
      <Card sx={{ mt: 2 }}>
        <Box p={2}>
          <Typography variant="h5">API Requests</Typography>
          <Chart
            data={usageData.map((item) => ({
              ...item,
              usage: { apiRequests: item.usage.apiRequests },
            }))}
            color={yellow[700]}
          />
        </Box>
      </Card>
    </Box>
  );
}
