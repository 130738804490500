import { useMemo, useState } from 'react';
import {
  Stack,
  Typography,
  Grid,
  Box,
  Divider,
  Card,
  CardContent,
  CardActionArea,
  Button,
} from '@mui/material';
import { ArrowForward, ControlPoint } from '@mui/icons-material';
import { NewBot, CardBot } from 'components/molecules';
import { useGetBotsQuery } from 'graphql/generated';
import { Loading } from 'components/atoms';
import UsageCall from 'components/molecules/usage-call';
import { useNavigate } from 'react-router-dom';
import { useOrganisation } from 'use/organisation';

export default function StudioIndex() {
  const [openNewBot, setOpenNewBot] = useState(false);
  const { selectedOrganisation } = useOrganisation();

  const navigate = useNavigate();
  const {
    data,
    isLoading: isBotLoading,
    refetch,
  } = useGetBotsQuery({
    input: {
      organisationID: selectedOrganisation,
      limit: 4,
    },
  });

  const bots = useMemo(() => data?.getBots.data, [data]);
  const handleRefresh = async () => {
    await refetch();
  };

  return (
    <Box>
      <NewBot
        title="New Voice AI Bot"
        open={openNewBot}
        setOpen={setOpenNewBot}
        onUpdate={() => {
          handleRefresh();
        }}
      />
      <Box sx={{ mt: 3 }}>
        {isBotLoading ? (
          <Loading />
        ) : (
          <Stack
            direction="column"
            spacing={2}
          >
            <UsageCall />
            <Box>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h4">Voice Bots</Typography>
                <Button
                  size="small"
                  endIcon={<ArrowForward />}
                  onClick={() => navigate('/playground/bot')}
                >
                  All
                </Button>
              </Stack>
              <Divider sx={{ my: 1, borderBottom: '0.1px solid #2e2e2e' }} />
              <Grid
                sx={{ mt: 1 }}
                container
                spacing={2}
              >
                {bots?.length === 0 && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <Card>
                      <CardActionArea
                        onClick={() => setOpenNewBot(true)}
                        sx={{ height: 125 }}
                      >
                        <CardContent>
                          <Stack
                            direction="column"
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                            sx={{ height: '100%' }}
                          >
                            <ControlPoint />
                            <Typography variant="h4">New voice bot</Typography>
                          </Stack>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )}
                {bots?.map((bot) => (
                  <CardBot
                    bot={bot}
                    key={bot.id}
                  />
                ))}
              </Grid>
            </Box>
          </Stack>
        )}
      </Box>
    </Box>
  );
}
