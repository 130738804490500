/* eslint-disable no-shadow */

export enum PromptRole {
  SYSTEM = 'system',
  USER = 'user',
  ASSISTANT = 'assistant',
}

export enum MemoryType {
  vector = 'vector',
  graph = 'graph',
}

export enum OrganisationPaymentStatus {
  free = 'free',
  trialing = 'trialing',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  active = 'active',
  past_due = 'past_due',
  canceled = 'canceled',
  unpaid = 'unpaid',
  paused = 'paused',
}

export enum OrganisationUserStatus {
  active = 'active',
  inactive = 'inactive',
  invited = 'invited',
}

export enum OrganisationUserRoleType {
  admin = 'admin',
  user = 'user',
}

export enum AIType {
  LLM = 'llm',
  TTS = 'tts',
  ASR = 'asr',
}

export enum BillingStatus {
  trial = 'trial',
  active = 'active',
  overdue = 'overdue',
  cancelled = 'cancelled',
  expired = 'expired',
}

export enum AIProvider {
  OPENAI = 'openai',
  DEEPGRAM = 'deepgram',
  ELEVENLABS = 'elevenlabs',
  CARTESIA = 'cartesia',
  PLAYHT = 'playht',
  UNKNOWN = 'unknown',
}

export enum PlanType {
  free = 'free',
  starter = 'starter',
  pro = 'pro',
  enterprise = 'enterprise',
  custom = 'custom',
}

export enum AIModels {
  GPT_3_5_TURBO = 'gpt_3_5_turbo',
  GPT_4_O = 'gpt_4_o',
  GPT_4 = 'gpt_4',
  ELEVEN_TURBO_V2 = 'eleven_turbo_v2',
  ELEVEN_MULTILINGUAL_V2 = 'eleven_multilingual_v2',
  ELEVEN_MULTILINGUAL_V1 = 'eleven_multilingual_v1',
  ELEVEN_MONOLINGUAL_V1 = 'eleven_monolingual_v1',
  NOVA_2 = 'nova_2',
  NOVA_2_GENERAL = 'nova_2_general',
  NOVA_2_MEDICAL = 'nova_2_medical',
  NOVA_2_LEGAL = 'nova_2_legal',
  NOVA_2_FINANCIAL = 'nova_2_financial',
  NOVA_2_MEETING = 'nova_2_meeting',
  NOVA_2_PHONECALL = 'nova_2_phonecall',
  NOVA_2_CONVERSATIONALAI = 'nova_2_conversationalai',
  NOVA_2_VIDEO = 'nova_2_video',
  NOVA_2_DRIVETHRU = 'nova_2_drivethru',
  NOVA_2_AUTOMOTIVE = 'nova_2_automotive',
  PLAY_HT_2_TURBO = 'play_ht_2_turbo',
  PLAY_HT_2 = 'play_ht_2',
  PLAY_HT_1 = 'play_ht_1',
  SONIC = 'sonic',
}

interface ModelProviders {
  [AIType.TTS]: {
    [AIProvider.ELEVENLABS]: AIModels[];
    [AIProvider.PLAYHT]: AIModels[];
    [AIProvider.CARTESIA]: AIModels[];
  };
  [AIType.LLM]: {
    [AIProvider.OPENAI]: AIModels[];
  };
  [AIType.ASR]: {
    [AIProvider.DEEPGRAM]: AIModels[];
  };
}

export const MODEL_PROVIDERS: ModelProviders = {
  [AIType.TTS]: {
    [AIProvider.ELEVENLABS]: [
      AIModels.ELEVEN_TURBO_V2,
      AIModels.ELEVEN_MULTILINGUAL_V2,
      AIModels.ELEVEN_MULTILINGUAL_V1,
      AIModels.ELEVEN_MONOLINGUAL_V1,
    ],
    [AIProvider.PLAYHT]: [
      AIModels.PLAY_HT_2_TURBO,
      AIModels.PLAY_HT_2,
      AIModels.PLAY_HT_1,
    ],
    [AIProvider.CARTESIA]: [AIModels.SONIC],
  },
  [AIType.LLM]: {
    [AIProvider.OPENAI]: [
      AIModels.GPT_3_5_TURBO,
      AIModels.GPT_4_O,
      AIModels.GPT_4,
    ],
  },
  [AIType.ASR]: {
    [AIProvider.DEEPGRAM]: [
      AIModels.NOVA_2,
      AIModels.NOVA_2_GENERAL,
      AIModels.NOVA_2_MEDICAL,
      AIModels.NOVA_2_LEGAL,
      AIModels.NOVA_2_FINANCIAL,
      AIModels.NOVA_2_MEETING,
      AIModels.NOVA_2_PHONECALL,
      AIModels.NOVA_2_CONVERSATIONALAI,
      AIModels.NOVA_2_VIDEO,
      AIModels.NOVA_2_DRIVETHRU,
      AIModels.NOVA_2_AUTOMOTIVE,
    ],
  },
};

export enum Languages {
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  DE = 'de',
  ZH = 'zh',
  JA = 'ja',
  KO = 'ko',
  IT = 'it',
  PT = 'pt',
  RU = 'ru',
  AR = 'ar',
  HI = 'hi',
  BN = 'bn',
  UR = 'ur',
  NL = 'nl',
  EL = 'el',
  HE = 'he',
  SV = 'sv',
  TR = 'tr',
  VI = 'vi',
}

export enum PromptPosition {
  MAIN = 'MAIN',
  START = 'START',
}

export enum PromptRoles {
  ASSISTANT = 'assistant',
  SYSTEM = 'system',
  USER = 'user',
}

export enum JobAction {
  video_file_extract_start = 'video_file_extract_start',
  video_file_extract_complete = 'video_file_extract_complete',
  video_youtube_extract_start = 'video_youtube_extract_start',
  video_youtube_extract_complete = 'video_youtube_extract_complete',
  document_website_extract_start = 'document_website_extract_start',
  document_website_extract_complete = 'document_website_extract_complete',
  document_sitemap_parse_start = 'document_sitemap_parse_start',
  document_sitemap_parse_complete = 'document_sitemap_parse_complete',
  audio_transcribe_start = 'audio_transcribe_start',
  interview_process_start = 'interview_process_start',
  tts_select_start = 'tts_select_start',
  tts_train_start = 'tts_train_start',
  tts_sample_start = 'tts_sample_start',
  graph_ingestion_start = 'graph_ingestion_start',
  graph_ingestion_complete = 'graph_ingestion_complete',
  graph_chunk_start = 'graph_chunk_start',
  graph_chunk_complete = 'graph_chunk_complete',
  vector_ingestion_complete = 'vector_ingestion_complete',
  vector_ingestion_start = 'vector_ingestion_start',
  vector_chunk_start = 'vector_chunk_start',
  vector_chunk_complete = 'vector_chunk_complete',
  audio_clean_start = 'audio_clean_start',
  extract_document_text_start = 'extract_document_text_start',
}

export enum BatchType {
  audio_sample_clean = 'audio_sample_clean',
  audio_sample_transcribe = 'audio_sample_transcribe',
  audio_sample_extract = 'audio_sample_extract',
  voice_model_train = 'voice_model_train',
  video_youtube_extract = 'video_youtube_extract',
  video_file_extract = 'video_file_extract',
  document_website_extract = 'document_website_extract',
  document_file_extract = 'document_file_extract',
  graph_document_ingest = 'graph_document_ingest',
  graph_ingest = 'graph_ingest',
  vector_ingest = 'vector_ingest',
}

// FILES

export enum FileStatus {
  submitted = 'submitted',
  pending = 'pending',
  processing = 'processing',
  complete = 'complete',
  failed = 'failed',
}

export enum Status {
  pending = 'pending',
  processing = 'processing',
  success = 'success',
  failed = 'failed',
}

export enum FileStage {
  upload = 'upload',
  process = 'process',
  review = 'review',
  submit = 'submit',
  complete = 'complete',
}

export enum FileCategory {
  all = 'all',
  audio = 'audio',
  video = 'video',
  web = 'web',
  document = 'document',
  other = 'other',
}

export enum FileType {
  youtube = 'youtube',
  website = 'website',
  sitemap = 'sitemap',
  audio = 'audio',
  video = 'video',
  website_bulk = 'website_bulk',
  document = 'document',
  text = 'text',
  image = 'image',
  audio_recording = 'audio_recording',
  audio_upload = 'audio_upload',
  video_upload = 'video_upload',
  other = 'other',
}

// VOICE

export enum VoiceType {
  instant = 'instant',
}

export enum VoiceStep {
  select = 'select',
  clean = 'clean',
  review = 'review',
  train = 'train',
  complete = 'complete',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum JobStatus {
  submitted = 'submitted',
  pending = 'pending',
  starting = 'starting',
  running = 'running',
  complete = 'complete',
  failed = 'failed',
  unknown = 'unknown',
}

export enum FileSource {
  record = 'record',
  upload = 'upload',
  content = 'content',
  sample = 'sample',
}

export enum CallFactsAction {
  getmessages = 'getmessages',
  getfacts = 'getfacts',
  getsummary = 'getsummary',
  getall = 'getall',
}

export enum AudioSource {
  RECORD = 'record',
  UPLOAD = 'upload',
  CONTENT = 'content',
}

export enum ObjectType {
  CONTENT = 'CONTENT',
  CONTENT_AUDIO = 'CONTENT_AUDIO',
  CONTENT_VIDEO = 'CONTENT_VIDEO',
  CONTENT_IMAGE = 'CONTENT_IMAGE',
  CONTENT_DOCUMENT = 'CONTENT_DOCUMENT',
  CONTENT_TEXT = 'CONTENT_TEXT',
}

export enum UploadStatus {
  pending = 'pending',
  uploading = 'uploading',
  completed = 'completed',
  error = 'error',
  canceled = 'canceled',
}

export enum SampleType {
  human = 'human',
  ai = 'ai',
  document = 'document',
}

export enum ContextType {
  editor = 'editor',
  upload = 'upload',
  content = 'content',
  website = 'website',
  sitemap = 'sitemap',
}

export enum MemoryStep {
  select = 'select',
  ontology = 'ontology',
  ingest = 'ingest',
  review = 'review',
  complete = 'complete',
}

export enum EmbeddingModel {
  cohere_english_v3 = 'cohere.embed-english-v3',
}

export enum AdapterRepo {
  cultural_rdf_graph = 'cultural_rdf_graph',
  essay_rdf_graph = 'essay_rdf_graph',
  customer_support_rdf_graph = 'customer_support_rdf_graph',
}
