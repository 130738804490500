import {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { UploadQueueComponent } from './components/queue';
import { AppFile, FileWithMetadata } from 'types/app';
import { uploadFileToS3 } from 'interface/upload';
import { SampleType, UploadStatus } from 'types/enums';
import {
  UpdateSampleInput,
  useUpdateFileMutation,
  useUpdateSampleMutation,
} from 'graphql/generated';
import { useUser } from 'use/user';
import { v4 as uuidv4 } from 'uuid';
import { omit } from 'lodash';
import { useGetFilesQuery } from 'graphql/generated';
import mixpanel from 'mixpanel-browser';
import { useOrganisation } from 'use/organisation';

export const UploadQueueContext = createContext({
  queue: [] as FileWithMetadata[],
  removeFromQueue: (index: number) => {},
  uploadingIndex: -1,
  addToQueue: (files: FileWithMetadata[]) => {},
  isVisible: false,
  isExpanded: true,
  setIsVisible: (isVisible: boolean) => {},
  setIsExpanded: (isExpanded: boolean) => {},
  clearCompletedUploads: () => {},
  stopAndClearQueue: () => {},
});

export const UploadQueueProvider = ({ children }) => {
  const [queue, setQueue] = useState<FileWithMetadata[]>([]);
  const [uploadingIndex, setUploadingIndex] = useState(-1);
  const [isVisible, setIsVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const isProcessing = useRef(false);
  const [updateFile] = useUpdateFileMutation();
  const [updateSample] = useUpdateSampleMutation();
  const { getUser } = useUser();
  const { selectedOrganisation } = useOrganisation();
  const abortControllerRef = useRef<AbortController | null>(null);
  const shouldStopRef = useRef(false);
  const { refetch: refetchFiles } = useGetFilesQuery({
    input: {
      nextToken: null,
      category: null,
      limit: 10,
    },
  });

  const addToQueue = (files: FileWithMetadata[]) => {
    const extendedFiles = files.map((file) => ({
      ...file,
      uploadStatus: 'pending' as const,
      progress: 0,
      createSample: file.createSample || false,
      sampleIndexID: file.sampleIndexID || undefined,
      sampleType: file.sampleType || undefined,
    }));
    setQueue((prevQueue) => [...prevQueue, ...extendedFiles]);
    shouldStopRef.current = false;
  };

  const removeFromQueue = (index: number) => {
    setQueue((prevQueue) => prevQueue.filter((_, i) => i !== index));
  };

  const clearCompletedUploads = () => {
    setQueue((prevQueue) =>
      prevQueue.filter((file) => file.uploadStatus !== 'completed'),
    );
  };

  const stopAndClearQueue = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    shouldStopRef.current = true;
    setQueue([]);
    setUploadingIndex(-1);
    isProcessing.current = false;
  }, []);

  const handleUpload = useCallback(
    async (fileWithMetadata: FileWithMetadata) => {
      if (shouldStopRef.current) {
        return false;
      }
      try {
        const user = await getUser();
        const { file, appFile, createSample, sampleIndexID, sampleType } =
          fileWithMetadata;
        const key = `${selectedOrganisation}/${appFile.id}.${appFile.extension}`;
        const bucketName = `duohub-raw-${process.env.REACT_APP_STAGE}-${process.env.REACT_APP_AWS_REGION}`;

        abortControllerRef.current = new AbortController();
        const status = await uploadFileToS3({
          file: file,
          onProgress: (percentage) => {
            if (shouldStopRef.current) {
              abortControllerRef.current?.abort();
              return;
            }
            setQueue((prevQueue) => {
              const newQueue = [...prevQueue];
              const index = newQueue.findIndex(
                (item) => item.appFile.id === appFile.id,
              );
              if (index !== -1) {
                newQueue[index] = { ...newQueue[index], progress: percentage };
              }
              return newQueue;
            });
          },
          key,
          bucketName,
        });

        if (shouldStopRef.current) {
          return false;
        }
        console.log('status', status);
        console.log('key', key);
        console.log('appFile', appFile);
        if (status.success) {
          const updatedFile: AppFile = {
            ...appFile,
            bronzeKey: key,
            originRegion: process.env.REACT_APP_AWS_REGION || 'ap-southeast-2',
          };
          const res = await updateFile({
            input: omit(updatedFile, ['status']),
          }).unwrap();
          console.log('res', res);

          mixpanel.track('add_file', {
            'File Extension': appFile.extension,
          });

          await refetchFiles();

          if (createSample && sampleIndexID) {
            const sampleInput: UpdateSampleInput = {
              id: uuidv4(),
              fileID: appFile.id,
              userID: user.userId,
              organisationID: selectedOrganisation,
              type: fileWithMetadata.isDocument
                ? SampleType.document
                : SampleType.human,
            };
            if (sampleType !== 'voice') {
              sampleInput.memoryID = sampleIndexID;
            } else {
              sampleInput.voiceID = sampleIndexID;
            }

            await updateSample({ input: sampleInput });
          }

          setQueue((prevQueue) => {
            const newQueue = [...prevQueue];
            const index = newQueue.findIndex(
              (item) => item.appFile.id === appFile.id,
            );
            if (index !== -1) {
              newQueue[index] = {
                ...newQueue[index],
                uploadStatus: 'completed',
                progress: 100,
              };
            }
            return newQueue;
          });

          return true;
        } else {
          setQueue((prevQueue) => {
            const newQueue = [...prevQueue];
            const index = newQueue.findIndex(
              (item) => item.appFile.id === appFile.id,
            );
            if (index !== -1) {
              newQueue[index] = { ...newQueue[index], uploadStatus: 'error' };
            }
            return newQueue;
          });
          return false;
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Upload aborted');
        } else {
          console.error(`Error uploading file:`, error);
          setQueue((prevQueue) => {
            const newQueue = [...prevQueue];
            const index = newQueue.findIndex(
              (item) => item.appFile.id === fileWithMetadata.appFile.id,
            );
            if (index !== -1) {
              newQueue[index] = { ...newQueue[index], uploadStatus: 'error' };
            }
            return newQueue;
          });
          return false;
        }
      }
    },
    [getUser, selectedOrganisation, updateFile, refetchFiles, updateSample],
  );

  const processQueue = useCallback(async () => {
    if (
      isProcessing.current ||
      uploadingIndex >= queue.length ||
      shouldStopRef.current
    )
      return;

    isProcessing.current = true;
    shouldStopRef.current = false;

    for (let i = uploadingIndex + 1; i < queue.length; i++) {
      if (shouldStopRef.current) {
        break;
      }
      if (queue[i].uploadStatus === UploadStatus.pending) {
        setUploadingIndex(i);
        const result = await handleUpload(queue[i]);
        if (!result) break;
      }
    }
    setUploadingIndex(-1);
    isProcessing.current = false;
  }, [queue, uploadingIndex, handleUpload]);

  useEffect(() => {
    if (
      !isProcessing.current &&
      uploadingIndex === -1 &&
      queue.some((file) => file.uploadStatus === 'pending') &&
      !shouldStopRef.current
    ) {
      processQueue();
    }
  }, [queue, uploadingIndex, processQueue]);

  return (
    <UploadQueueContext.Provider
      value={{
        queue,
        addToQueue,
        removeFromQueue,
        uploadingIndex,
        isVisible,
        isExpanded,
        setIsVisible,
        setIsExpanded,
        clearCompletedUploads,
        stopAndClearQueue,
      }}
    >
      {children}
      <UploadQueueComponent />
    </UploadQueueContext.Provider>
  );
};

export const useUploadQueue = () => {
  const context = useContext(UploadQueueContext);
  if (context === undefined) {
    throw new Error('useUploadQueue must be used within a UploadQueueProvider');
  }
  return context;
};

export default UploadQueueProvider;
