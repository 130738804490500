import {
  BubbleChart,
  BubbleChartOutlined,
  Code,
  CodeOutlined,
  Deblur,
  DeblurOutlined,
  Grain,
  GrainOutlined,
  LocalLibrary,
  LocalLibraryOutlined,
  PanoramaPhotosphereOutlined,
  PanoramaPhotosphereSelect,
} from '@mui/icons-material';
import { SideBarItemProps } from 'types/app';

export const MainSideBarItems: SideBarItemProps[] = [
  {
    title: 'Overview',
    iconOutlined: <PanoramaPhotosphereOutlined />,
    iconFilled: <PanoramaPhotosphereSelect />,
    path: () => '/',
  },
  {
    title: 'Content',
    iconOutlined: <LocalLibraryOutlined />,
    iconFilled: <LocalLibrary />,
    path: () => '/content',
  },
  {
    title: 'Memory',
    iconOutlined: <GrainOutlined />,
    iconFilled: <Grain />,
    path: () => '/memory',
  },
  {
    title: 'Playground',
    iconOutlined: <DeblurOutlined />,
    iconFilled: <Deblur />,
    path: () => '/playground',
  },
  {
    title: 'Developers',
    iconOutlined: <CodeOutlined />,
    iconFilled: <Code />,
    path: () => '/developers',
  },
  {
    title: 'Usage',
    iconOutlined: <BubbleChartOutlined />,
    iconFilled: <BubbleChart />,
    path: () => '/usage',
  },
];
