import { Grid } from '@mui/material';
import { Loading } from 'components/atoms';
import { MemoryPreview } from 'components/molecules';
import { useGetMemoriesQuery } from 'graphql/generated';
import { useOrganisation } from 'use/organisation';

export default function Memory() {
  const { selectedOrganisation } = useOrganisation();
  const { data, isLoading: isGettingMemories } = useGetMemoriesQuery(
    {
      input: {
        organisationID: selectedOrganisation,
      },
    },
    { skip: !selectedOrganisation, refetchOnMountOrArgChange: true },
  );
  const memories = data?.getMemories?.data;

  return isGettingMemories ? (
    <Loading />
  ) : (
    <Grid
      container
      spacing={2}
      my={1}
    >
      {memories?.map((memory) => (
        <MemoryPreview
          key={memory?.id}
          memory={memory}
        />
      ))}
    </Grid>
  );
}
