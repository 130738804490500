import { OrganisationUser } from '@duohub/types';
import { Add, Edit, Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  CardContent,
  Typography,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Divider,
  Tooltip,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  useCreateOrganisationUserMutation,
  useDeleteOrganisationUserMutation,
  useGetOrganisationUsersQuery,
  useGetSelfQuery,
  useUpdateOrganisationUserMutation,
} from 'graphql/generated';
import Layout from 'layouts/index';
import { useMemo, useState } from 'react';
import { OrganisationUserRoleType } from 'types/enums';
import { useOrganisation } from 'use/organisation';
import { isValidEmail } from 'utils/validation';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: theme.spacing(1, 2),
}));

export default function Team() {
  const [open, setOpen] = useState(false);
  const [editRoleOpen, setEditRoleOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<OrganisationUser | null>(
    null,
  );
  const [settingRole, setSettingRole] = useState(false);
  const { selectedOrganisation } = useOrganisation();
  const [updateOrganisationUser] = useUpdateOrganisationUserMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteOrganisationUser, { isLoading: deleteOrganisationUserLoading }] =
    useDeleteOrganisationUserMutation();
  const { data: selfData } = useGetSelfQuery({
    input: {
      organisationID: selectedOrganisation,
    },
  });
  const organisationUser = useMemo(
    () => selfData?.getSelf?.user[0],
    [selfData],
  );
  const { data: organisationUsers, refetch: refetchOrganisationUsers } =
    useGetOrganisationUsersQuery(
      {
        input: {
          organisationID: selectedOrganisation,
        },
      },
      { skip: !selectedOrganisation, refetchOnMountOrArgChange: true },
    );
  const [createOrganisationUser] = useCreateOrganisationUserMutation();
  console.log(organisationUsers);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    roleType: '',
  });
  const [newRole, setNewRole] = useState<OrganisationUserRoleType>(
    OrganisationUserRoleType.user,
  );
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    roleType: false,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent,
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name as string]: value,
    }));

    // Clear the error for the changed field
    setFormErrors((prev) => ({
      ...prev,
      [name as string]: false,
    }));
  };

  const handleEditRole = (user: OrganisationUser) => {
    setSelectedUser(user);
    setNewRole(user.roleType as OrganisationUserRoleType);
    setEditRoleOpen(true);
  };

  const handleDeleteConfirm = (user: OrganisationUser) => {
    setSelectedUser(user);
    setDeleteConfirmOpen(true);
  };

  // Check if user is the only admin
  const isOnlyAdmin = (user: OrganisationUser) => {
    if (user?.roleType !== OrganisationUserRoleType.admin) return false;

    const adminCount =
      organisationUsers?.getOrganisationUsers?.data?.filter(
        (u) => u?.roleType === OrganisationUserRoleType.admin,
      ).length ?? 0;

    return adminCount === 1;
  };

  const handleSubmit = async () => {
    // Check if email already exists in organisation
    const emailExists = organisationUsers?.getOrganisationUsers?.data?.some(
      (user) => user?.user.email.toLowerCase() === formData.email.toLowerCase(),
    );

    // Validate all fields
    const errors = {
      firstName: !formData.firstName,
      lastName: !formData.lastName,
      email:
        !formData.email ||
        !isValidEmail(formData.email) ||
        formData.email === selfData?.getSelf?.user[0]?.user?.email ||
        emailExists,
      roleType: !formData.roleType,
    };

    setFormErrors(errors);

    // Check if any errors exist
    if (Object.values(errors).some((error) => error)) {
      return;
    }

    try {
      setIsLoading(true);
      const input = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        organisationID: selectedOrganisation,
        roleType: formData.roleType as OrganisationUserRoleType,
      };
      await createOrganisationUser({
        input,
      }).unwrap();
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        roleType: '',
      });
      await refetchOrganisationUsers();
      handleClose();
    } catch (error) {
      console.error('Error creating user:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      if (
        selfData?.getSelf?.user[0]?.roleType === OrganisationUserRoleType.admin
      ) {
        await deleteOrganisationUser({
          input: {
            organisationID: selectedOrganisation,
            userID: selectedUser.userID,
          },
        }).unwrap();
        await refetchOrganisationUsers();
        setDeleteConfirmOpen(false);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleChangeRole = async () => {
    try {
      setSettingRole(true);
      if (
        selfData?.getSelf?.user[0]?.roleType === OrganisationUserRoleType.admin
      ) {
        await updateOrganisationUser({
          input: {
            organisationID: selectedOrganisation,
            userID: selectedUser.userID,
            roleType: newRole,
          },
        }).unwrap();
        await refetchOrganisationUsers();
        setEditRoleOpen(false);
      }
    } catch (error) {
      console.error('Error changing role:', error);
    } finally {
      setSettingRole(false);
    }
  };

  const handleDialogClose = () => {
    setFormErrors({
      firstName: false,
      lastName: false,
      email: false,
      roleType: false,
    });
    handleClose();
  };

  console.log({ selectedUser });

  return (
    <Layout
      startTitle={
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <Typography variant="h4">Team</Typography>
          <IconButton onClick={handleOpen}>
            <Add />
          </IconButton>
        </Stack>
      }
    >
      <Box
        sx={{
          overflowX: 'auto',
          width: '100%',
        }}
      >
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell width="20%">Name</StyledTableCell>
                  <StyledTableCell width="25%">Email</StyledTableCell>
                  <StyledTableCell width="15%">Role</StyledTableCell>
                  <StyledTableCell width="15%">Status</StyledTableCell>
                  <StyledTableCell width="15%">Created</StyledTableCell>
                  {organisationUser?.roleType ===
                    OrganisationUserRoleType.admin && (
                    <StyledTableCell width="10%">Actions</StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {organisationUsers?.getOrganisationUsers?.data?.map(
                  (orgUser) => (
                    <TableRow key={orgUser.id}>
                      <StyledTableCell>
                        {`${orgUser.user.firstName} ${orgUser.user.lastName}`}
                      </StyledTableCell>
                      <StyledTableCell>{orgUser.user.email}</StyledTableCell>
                      <StyledTableCell>
                        <Chip
                          label={
                            orgUser.roleType === OrganisationUserRoleType.admin
                              ? 'Admin'
                              : 'Developer'
                          }
                          size="small"
                        />
                      </StyledTableCell>
                      <StyledTableCell sx={{ textTransform: 'capitalize' }}>
                        <Chip
                          label={orgUser.status}
                          size="small"
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        {new Date(orgUser.createdAt).toLocaleDateString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {organisationUser?.roleType ===
                        OrganisationUserRoleType.admin ? (
                          <Stack
                            direction="row"
                            spacing={1}
                          >
                            <Tooltip
                              title={
                                isOnlyAdmin(orgUser)
                                  ? 'Cannot change role: At least one admin is required'
                                  : 'Change Role'
                              }
                            >
                              <span>
                                {' '}
                                <IconButton
                                  size="small"
                                  onClick={() => handleEditRole(orgUser)}
                                  disabled={isOnlyAdmin(orgUser)}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip
                              title={
                                isOnlyAdmin(orgUser)
                                  ? 'Cannot remove: At least one admin is required'
                                  : 'Remove'
                              }
                            >
                              <span>
                                <IconButton
                                  size="small"
                                  onClick={() => handleDeleteConfirm(orgUser)}
                                  disabled={isOnlyAdmin(orgUser)}
                                >
                                  <Cancel
                                    fontSize="small"
                                    color="error"
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </Stack>
                        ) : (
                          <Box
                            height={40}
                            width={20}
                          />
                        )}
                      </StyledTableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Box>

      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h4">Add Team Member</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <TextField
                size="small"
                name="firstName"
                label="First Name"
                fullWidth
                value={formData.firstName}
                onChange={handleChange}
                error={formErrors.firstName}
                helperText={
                  formErrors.firstName ? 'First name is required' : ''
                }
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                size="small"
                name="lastName"
                label="Last Name"
                fullWidth
                value={formData.lastName}
                onChange={handleChange}
                error={formErrors.lastName}
                helperText={formErrors.lastName ? 'Last name is required' : ''}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                size="small"
                name="email"
                label="Email"
                fullWidth
                value={formData.email}
                onChange={handleChange}
                type="email"
                error={formErrors.email}
                helperText={
                  formErrors.email
                    ? formData.email === selfData?.getSelf?.user[0]?.user?.email
                      ? 'You cannot add your own email'
                      : organisationUsers?.getOrganisationUsers?.data?.some(
                            (user) =>
                              user?.user.email.toLowerCase() ===
                              formData.email.toLowerCase(),
                          )
                        ? 'This email is already a member of the organisation'
                        : !isValidEmail(formData.email)
                          ? 'Please enter a valid email address'
                          : 'Email is required'
                    : ''
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FormControl
                size="small"
                fullWidth
                error={formErrors.roleType}
              >
                <InputLabel>Role</InputLabel>
                <Select
                  name="roleType"
                  value={formData.roleType}
                  label="Role"
                  size="small"
                  onChange={handleChange}
                >
                  <MenuItem value={OrganisationUserRoleType.admin}>
                    <Stack spacing={0.5}>
                      Admin
                      <Typography
                        variant="caption"
                        color="text.secondary"
                      >
                        Full access to all features, billing and team management
                      </Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem value={OrganisationUserRoleType.user}>
                    <Stack spacing={0.5}>
                      Developer
                      <Typography
                        variant="caption"
                        color="text.secondary"
                      >
                        Access to development features and resources only
                      </Typography>
                    </Stack>
                  </MenuItem>
                </Select>
                {formErrors.roleType && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ mt: 1 }}
                  >
                    Role is required
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Button onClick={handleDialogClose}>Cancel</Button>
            <LoadingButton
              variant="contained"
              onClick={handleSubmit}
              loading={isLoading}
              size="small"
              sx={{ height: 24, fontWeight: 600 }}
            >
              Add Member
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>

      {/* Edit Role Dialog */}
      <Dialog
        open={editRoleOpen}
        onClose={() => setEditRoleOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h4">Change Role</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={3}>
            <Box>
              <Typography>
                {selectedUser?.user.firstName} {selectedUser?.user.lastName}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
              >
                {selectedUser?.user.email}
              </Typography>
            </Box>
            <FormControl
              size="small"
              fullWidth
            >
              <InputLabel>Role</InputLabel>
              <Select
                value={newRole}
                label="Role"
                onChange={(e: SelectChangeEvent) =>
                  setNewRole(e.target.value as OrganisationUserRoleType)
                }
                disabled={isOnlyAdmin(selectedUser)}
              >
                <MenuItem value={OrganisationUserRoleType.admin}>
                  <Stack spacing={0.5}>
                    Admin
                    <Typography
                      variant="caption"
                      color="text.secondary"
                    >
                      Full access to all features, billing and team management
                    </Typography>
                  </Stack>
                </MenuItem>
                <MenuItem value={OrganisationUserRoleType.user}>
                  <Stack spacing={0.5}>
                    Developer
                    <Typography
                      variant="caption"
                      color="text.secondary"
                    >
                      Access to development features and resources only
                    </Typography>
                  </Stack>
                </MenuItem>
              </Select>
              {isOnlyAdmin(selectedUser) && (
                <Typography
                  variant="caption"
                  color="error"
                  sx={{ mt: 1 }}
                >
                  Cannot change role: At least one admin is required
                </Typography>
              )}
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Button onClick={() => setEditRoleOpen(false)}>Cancel</Button>
            <LoadingButton
              variant="contained"
              size="small"
              onClick={handleChangeRole}
              loading={settingRole}
              sx={{ height: 24, fontWeight: 600 }}
            >
              Update Role
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h4">Remove Team Member</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <Typography>
              Are you sure you want to remove this team member?
            </Typography>
            <Box>
              <Typography>
                {selectedUser?.user.firstName} {selectedUser?.user.lastName}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
              >
                {selectedUser?.user.email}
              </Typography>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
            <LoadingButton
              variant="contained"
              loading={deleteOrganisationUserLoading}
              onClick={handleDelete}
              color="error"
              size="small"
              sx={{ height: 24, fontWeight: 600 }}
            >
              Remove
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}
