import {
  Book,
  RadioButtonChecked,
  RadioButtonUnchecked,
  SupportAgent,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography,
  Grid,
  Stack,
} from '@mui/material';
import { useUpdateMemoryMutation, useGetMemoryQuery } from 'graphql/generated';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AdapterRepo } from 'types/enums';

interface OntologyOption {
  adapterRepo: AdapterRepo;
  adapterVersion: number;
  name: string;
  icon?: React.ReactNode;
  description: string;
}

export default function MemoryNewStepOntology() {
  const { memoryID } = useParams<{ memoryID: string }>();
  const [updateMemory] = useUpdateMemoryMutation();
  const { data: memoryData } = useGetMemoryQuery({
    input: { id: memoryID },
  });
  const memory = useMemo(() => {
    if (!memoryData) return null;
    return memoryData.getMemory.data;
  }, [memoryData]);
  const [selectedOntology, setSelectedOntology] = useState(
    memory?.adapterRepo || AdapterRepo.cultural_rdf_graph,
  );

  const ontologies: OntologyOption[] = [
    {
      adapterRepo: AdapterRepo.cultural_rdf_graph,
      adapterVersion: 1,
      icon: null,
      name: 'Default Ontology',
      description:
        'An ontology focused on cultural events, history, and general knowledge based on Wikipedia and cultural data.',
    },
    {
      adapterRepo: AdapterRepo.essays_rdf_graph,
      adapterVersion: 1,
      name: 'Essay / Blog Post Ontology',
      icon: <Book />,
      description:
        'An ontology specialized in essay structure, blog posts, articles and academic content organization.',
    },
    {
      adapterRepo: AdapterRepo.customer_support_rdf_graph,
      adapterVersion: 1,
      name: 'Customer Support Ontology',
      icon: <SupportAgent />,
      description:
        'An ontology specialized in customer support emails and tweets, including complaints, troubleshooting, and customer service interactions.',
    },
  ];

  const handleOntologySelect = (adapterRepo: AdapterRepo) => {
    setSelectedOntology(adapterRepo);
    updateMemory({
      input: {
        id: memoryID,
        adapterRepo: adapterRepo || AdapterRepo.cultural_rdf_graph,
        adapterVersion: 1,
      },
    });
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Ontology</Typography>
        <Divider sx={{ my: 2, opacity: 0.5 }} />
        <Grid
          container
          spacing={2}
        >
          {ontologies.map((ontology) => (
            <Grid
              item
              xs={12}
              md={4}
              key={ontology.adapterRepo}
            >
              <Card
                elevation={
                  selectedOntology === ontology.adapterRepo ? 0 : undefined
                }
                sx={{
                  p: 2,
                  cursor: 'pointer',
                }}
                onClick={() => handleOntologySelect(ontology.adapterRepo)}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                >
                  <IconButton size="small">
                    {selectedOntology === ontology.adapterRepo ? (
                      ontology.icon ? (
                        ontology.icon
                      ) : (
                        <RadioButtonChecked color="primary" />
                      )
                    ) : (
                      <RadioButtonUnchecked color="disabled" />
                    )}
                  </IconButton>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold' }}
                  >
                    {ontology.name}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                >
                  <Box
                    width={80}
                    height={80}
                  />
                  <Typography variant="body2">
                    {ontology.description}
                  </Typography>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
