import { OrganisationContextProvider, useOrganisationContext } from './state';

export const useOrganisation = () => {
  const context = useOrganisationContext();

  return {
    ...context,
  };
};

export { OrganisationContextProvider };
