import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Stack,
  Link,
  Divider,
} from '@mui/material';
import {
  signIn,
  SignInInput,
  resetPassword,
  confirmResetPassword,
  signInWithRedirect,
  getCurrentUser,
} from 'aws-amplify/auth';
import { Logo } from 'components/atoms';
import { Google as GoogleIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'use/snackbar';
import mixpanel from 'mixpanel-browser';

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [resetStep, setResetStep] = useState(0);
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const checkAuthState = useCallback(async () => {
    try {
      await getCurrentUser();
      return true;
    } catch {
      return false;
    }
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      const isAuthenticated = await checkAuthState();
      if (isAuthenticated) {
        navigate('/', { replace: true });
      }
    };

    checkAuth();
  }, [checkAuthState, navigate]);

  const handleSignIn = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');
    setLoading(true);
    try {
      const isAuthenticated = await checkAuthState();
      if (isAuthenticated) {
        navigate('/', { replace: true });
        return;
      }
      const signInInput: SignInInput = {
        username: email,
        password,
      };
      const user = await signIn(signInInput);
      console.log('Sign in successful', user);
      mixpanel.track('sign_in', {
        'Signin Type': 'Email',
      });
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    } catch (err) {
      setError('Failed to sign in. Please check your credentials.');
      console.error('Sign in error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const isAuthenticated = await checkAuthState();
      if (isAuthenticated) {
        navigate('/', { replace: true });
        return;
      }

      // Get and clear redirectUrl from localStorage
      const redirectUrl = localStorage.getItem('redirectUrl');
      localStorage.removeItem('redirectUrl');

      await signInWithRedirect({
        provider: 'Google',
        customState: redirectUrl || null,
      });

      mixpanel.track('sign_in', {
        'Signin Type': 'Google',
      });
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError('Failed to sign in with Google. Please try again.');
    }
  };

  const handleResetPassword = async () => {
    setError('');
    if (!email) {
      setError('Please enter your email address.');
      return;
    }

    try {
      await resetPassword({ username: email });
      setResetStep(1);
    } catch (err) {
      console.error('Password reset error:', err);
      if (err instanceof Error) {
        switch (err.name) {
          case 'LimitExceededException':
            setError('Attempt limit exceeded. Please try again later.');
            break;
          default:
            setError(`Failed to initiate password reset: ${err.message}`);
        }
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    }
  };

  const handleConfirmResetPassword = async () => {
    setError('');
    if (!email || !resetCode || !newPassword) {
      setError(
        'Please enter your email, the reset code, and your new password.',
      );
      return;
    }

    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: resetCode,
        newPassword,
      });
      showSnackbar(
        'Password reset successful. Please sign in with your new password.',
        'success',
      );
      setIsResettingPassword(false);
      setResetStep(0);
    } catch (err) {
      console.error('Confirm password reset error:', err);
      if (err instanceof Error) {
        switch (err.name) {
          case 'CodeMismatchException':
            setError('Invalid reset code. Please try again.');
            break;
          case 'ExpiredCodeException':
            setError('Reset code has expired. Please request a new one.');
            break;
          case 'LimitExceededException':
            setError('Attempt limit exceeded. Please try again later.');
            break;
          default:
            setError('Failed to reset password. Please try again.');
        }
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    }
  };

  const renderResetPasswordForm = () => {
    switch (resetStep) {
      case 0:
        return (
          <Box sx={{ width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="reset-email"
              label="Email"
              name="reset-email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="small"
            />
            <Button
              fullWidth
              variant="contained"
              onClick={handleResetPassword}
              sx={{ mt: 2, mb: 2, fontWeight: 'bold' }}
              size="small"
            >
              Send Reset Code
            </Button>
          </Box>
        );
      case 1:
        return (
          <Box>
            <TextField
              margin="normal"
              required
              fullWidth
              id="reset-code"
              label="Reset Code"
              name="reset-code"
              value={resetCode}
              onChange={(e) => setResetCode(e.target.value)}
              size="small"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="new-password"
              label="New Password"
              type="password"
              id="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              size="small"
            />
            <Button
              fullWidth
              variant="contained"
              onClick={handleConfirmResetPassword}
              sx={{ mt: 2, mb: 2, fontWeight: 'bold' }}
              size="small"
            >
              Reset Password
            </Button>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
      }}
    >
      <Container maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
          >
            <Logo size="medium" />
          </Stack>
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            sx={{ mt: 4, width: '100%' }}
          >
            {!isResettingPassword && (
              <>
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<GoogleIcon />}
                  onClick={handleGoogleSignIn}
                  sx={{ mb: 2 }}
                >
                  Sign in with Google
                </Button>
                <Divider sx={{ width: '100%' }}>
                  <Typography variant="caption">OR</Typography>
                </Divider>
              </>
            )}
            {!isResettingPassword ? (
              <Box
                component="form"
                onSubmit={handleSignIn}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  size="small"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  size="small"
                />
                <Link
                  component="button"
                  variant="body2"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent form submission
                    setIsResettingPassword(true);
                  }}
                  type="button"
                >
                  Forgot password?
                </Link>
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, fontWeight: 'bold' }}
                  size="small"
                  loading={loading}
                >
                  Sign In
                </LoadingButton>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 1 }}
                >
                  <Link
                    href="/signup"
                    variant="body2"
                  >
                    Sign up
                  </Link>
                </Stack>
              </Box>
            ) : (
              <Box sx={{ mt: 1, width: '100%' }}>
                <Divider
                  sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
                >
                  <Typography variant="h6">Reset Password</Typography>
                </Divider>
                {renderResetPasswordForm()}
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    setIsResettingPassword(false);
                    setResetStep(0);
                  }}
                  sx={{ display: 'block', textAlign: 'center', mt: 2 }}
                >
                  Back to Sign In
                </Link>
              </Box>
            )}
          </Stack>
        </Box>
        {error && (
          <Typography
            color="error"
            align="center"
            sx={{ mt: 2 }}
          >
            {error}
          </Typography>
        )}
      </Container>
    </Box>
  );
};

export default SignIn;
