import React, { useEffect, useMemo, useState } from 'react';
import {
  Typography,
  TextField,
  Paper,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Tabs,
  Tab,
} from '@mui/material';
import Layout from 'layouts/index';
import {
  DeleteOutline,
  MoreVert,
  ToggleOffOutlined,
  ToggleOnOutlined,
} from '@mui/icons-material';
import BotActivity from './activity';
import BotConfig from './config';
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom';
import {
  useGetBotQuery,
  useGetBotsQuery,
  useUpdateBotMutation,
} from 'graphql/generated';
import { useDeleteBotMutation } from 'graphql/generated';
import BotHome from './home';
import { DialogAvatar } from 'components/organisms';
import { AvatarBot } from 'components/atoms';
import Deployment from './deployment';
import { useOrganisation } from 'use/organisation';

export default function StudioBot() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [openAvatarDialog, setOpenAvatarDialog] = useState(false);
  const { botID } = useParams<{ botID: string }>();
  const { selectedOrganisation } = useOrganisation();
  const { refetch: refetchBots } = useGetBotsQuery({
    input: {
      organisationID: selectedOrganisation,
    },
  });
  const { data } = useGetBotQuery({ input: { id: botID } });
  const bot = useMemo(() => data?.getBot.data, [data]);
  const [text, setText] = useState(bot?.name);
  const [deleteBot, { isLoading: isDeleting }] = useDeleteBotMutation();
  const [updateBot, { isLoading: isUpdating }] = useUpdateBotMutation();

  useEffect(() => {
    setText(bot?.name);
  }, [bot]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    if (event.target.value?.length <= 70) {
      setText(event.target.value);
    }
  };

  const handleSave = async () => {
    if (text.trim() !== '') {
      setIsEditing(false);
      await updateBot({ input: { id: botID, name: text } }).unwrap();
      console.log('Saved:', text);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSave();
    }
  };

  const location = useLocation();
  const [value, setValue] = useState(
    location.pathname.includes('config')
      ? '/config'
      : location.pathname.includes('activity')
        ? '/activity'
        : '/',
  );
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    navigate(`/playground/bot/${botID}${newValue}`);
  };

  const handleBotDelete = async () => {
    await deleteBot({ input: { id: botID } }).unwrap();
    await refetchBots();
    await navigate('/playground/bot');
  };

  const handleToggleActive = async () => {
    await updateBot({ input: { id: botID, active: !bot?.active } });
  };

  const handleOpenAvatarDialog = () => {
    setOpenAvatarDialog(true);
  };

  return (
    <Layout
      backArrow
      startTitle={
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <IconButton onClick={handleOpenAvatarDialog}>
            <AvatarBot
              size={36}
              bot={bot}
            />
          </IconButton>
          {isEditing ? (
            <Paper
              elevation={3}
              style={{ display: 'inline-block' }}
            >
              <TextField
                placeholder="Bot name"
                value={text}
                onChange={handleInputChange}
                onBlur={handleSave}
                onKeyDown={handleKeyDown}
                autoFocus
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                }}
                size="small"
                inputProps={{
                  style: {
                    fontSize: 'inherit',
                    padding: '6px 10px',
                    border: 'none',
                  },
                  maxLength: 70,
                }}
              />
            </Paper>
          ) : (
            <Typography
              variant="h4"
              onClick={handleTextClick}
              style={{ cursor: 'pointer' }}
            >
              {text}
            </Typography>
          )}
        </Stack>
      }
      endAction={
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            sx={{ minHeight: 28 }}
          >
            <Tab
              value={`/`}
              label="Overview"
              sx={{
                minHeight: 28,
                px: 1.5,
                minWidth: 'auto',
                textTransform: 'none',
              }}
            />
            <Tab
              label="Activity"
              value={`/activity`}
              sx={{
                minHeight: 28,
                px: 1.5,
                textTransform: 'none',
              }}
            />
            <Tab
              label="Config"
              value={`/config`}
              sx={{
                minHeight: 28,
                px: 1.5,
                textTransform: 'none',
              }}
            />
            <Tab
              label="Deployment"
              value={`/deployment`}
              sx={{
                minHeight: 28,
                px: 1.5,
                textTransform: 'none',
              }}
            />
          </Tabs>
          <IconButton onClick={handleMenuOpen}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            slotProps={{
              paper: {
                sx: {
                  minWidth: 140,
                },
              },
            }}
          >
            <MenuItem onClick={handleToggleActive}>
              <ListItemIcon>
                {isUpdating ? (
                  <CircularProgress size={20} />
                ) : bot?.active ? (
                  <ToggleOnOutlined fontSize="small" />
                ) : (
                  <ToggleOffOutlined fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText
                secondary={bot?.active ? 'Deactivate' : 'Activate'}
              />
            </MenuItem>
            <MenuItem onClick={handleBotDelete}>
              <ListItemIcon>
                {isDeleting ? (
                  <CircularProgress size={20} />
                ) : (
                  <DeleteOutline
                    color="error"
                    fontSize="small"
                  />
                )}
              </ListItemIcon>
              <ListItemText
                secondaryTypographyProps={{ color: 'error' }}
                secondary="Delete"
              />
            </MenuItem>
          </Menu>
        </Stack>
      }
    >
      <DialogAvatar
        open={openAvatarDialog}
        setOpen={setOpenAvatarDialog}
        bot={bot}
      />
      <Routes>
        <Route
          path="/"
          element={<BotHome />}
        />
        <Route
          path="/activity"
          element={<BotActivity />}
        />
        <Route
          path="/config"
          element={<BotConfig />}
        />
        <Route
          path="/deployment"
          element={<Deployment />}
        />
      </Routes>
    </Layout>
  );
}
