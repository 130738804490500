import { Box, Typography, Stack } from '@mui/material';
import Layout from 'layouts';
import { useMemo, useState } from 'react';
import { useGetSelfQuery, useGetUsageQuery } from 'graphql/generated';
import { DateTime } from 'luxon';
import { ChartUsage, InvoicePreview } from 'components/organisms';
import dayjs, { Dayjs } from 'dayjs';
import { useOrganisation } from 'use/organisation';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { OrganisationPaymentStatus } from 'types/enums';

export default function Usage() {
  const { selectedOrganisation } = useOrganisation();
  const [fromDate, setFromDate] = useState<string>(
    DateTime.local().minus({ days: 30 }).toISO(),
  );
  const { data: selfData } = useGetSelfQuery(
    {
      input: {
        organisationID: selectedOrganisation,
      },
    },
    {
      skip: !selectedOrganisation,
      refetchOnMountOrArgChange: true,
    },
  );
  const organisation = useMemo(
    () => selfData?.getSelf?.user[0]?.organisation,
    [selfData],
  );
  const [toDate, setToDate] = useState<string>(DateTime.local().toISO());
  const { data: usageData, refetch: refetchUsage } = useGetUsageQuery(
    {
      input: {
        organisationID: selectedOrganisation,
        fromDate: DateTime.fromISO(fromDate)
          .setZone(DateTime.local().zoneName)
          .startOf('day')
          .toUTC()
          .toISO(),
        toDate: DateTime.fromISO(toDate)
          .setZone(DateTime.local().zoneName)
          .endOf('day')
          .toUTC()
          .toISO(),
        timezone: DateTime.local().zoneName,
      },
    },
    {
      skip: !selectedOrganisation,
      refetchOnMountOrArgChange: true,
    },
  );

  const handleFromDateChange = (newValue: Dayjs | null) => {
    if (!newValue) return;
    const newFromDate = newValue.format('YYYY-MM-DD');
    const daysDiff = dayjs(toDate).diff(newFromDate, 'days');

    if (daysDiff > 90) {
      // If new from date would make range > 90 days, adjust to date
      const adjustedToDate = newValue.add(90, 'days').format('YYYY-MM-DD');
      setToDate(adjustedToDate);
    }
    setFromDate(newFromDate);
    refetchUsage();
  };

  const handleToDateChange = (newValue: Dayjs | null) => {
    if (!newValue) return;
    const newToDate = newValue.format('YYYY-MM-DD');
    const daysDiff = dayjs(newToDate).diff(fromDate, 'days');

    if (daysDiff > 90) {
      // If new to date would make range > 90 days, adjust from date
      const adjustedFromDate = dayjs(newToDate)
        .subtract(90, 'days')
        .format('YYYY-MM-DD');
      setFromDate(adjustedFromDate);
    }
    setToDate(newToDate);
    refetchUsage();
  };

  return (
    <Layout
      startTitle={
        <Stack
          direction="row"
          spacing={1}
          sx={{ alignItems: 'center' }}
        >
          <Typography
            variant="h4"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            Usage
          </Typography>
        </Stack>
      }
      endAction={
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
              direction="row"
              spacing={4}
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography variant="caption">From</Typography>
                <DatePicker
                  value={dayjs(fromDate)}
                  onChange={handleFromDateChange}
                  maxDate={dayjs(toDate)}
                  slotProps={{
                    textField: {
                      size: 'small',
                      sx: { width: '150px' },
                    },
                  }}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography variant="caption">To</Typography>
                <DatePicker
                  value={dayjs(toDate)}
                  onChange={handleToDateChange}
                  minDate={dayjs(fromDate)}
                  slotProps={{
                    textField: {
                      size: 'small',
                      sx: { width: '150px' },
                    },
                  }}
                />
              </Stack>
            </Stack>
          </LocalizationProvider>
        </>
      }
    >
      <Box>
        <ChartUsage
          fromDate={fromDate}
          toDate={toDate}
          onFromDateChange={setFromDate}
          onToDateChange={setToDate}
          usageData={usageData?.getUsage.data || []}
        />
      </Box>
      <Box sx={{ mb: 4, mt: 2 }}>
        <Typography variant="caption">
          Daily usage is accumulated from 0000 to 2359 GMT, but may be shown in
          your local timezone.
        </Typography>
      </Box>
      {organisation?.paymentStatus === OrganisationPaymentStatus.active && (
        <InvoicePreview goToBilling />
      )}
    </Layout>
  );
}
