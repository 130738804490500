import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface DialogAddApiKeyProps {
  isDialogOpen: boolean;
  handleCloseDialog: () => void;
  handleCreateApiKey: () => void;
  isCreatingApiKey: boolean;
  newKeyName: string;
  setNewKeyName: (name: string) => void;
}

export default function DialogAddApiKey({
  isDialogOpen,
  handleCloseDialog,
  handleCreateApiKey,
  isCreatingApiKey,
  newKeyName,
  setNewKeyName,
}: DialogAddApiKeyProps) {
  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="sm"
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ py: 1, px: 2 }}
        justifyContent="space-between"
      >
        <Typography variant="h4">Create New API Key</Typography>
        <IconButton onClick={handleCloseDialog}>
          <Close />
        </IconButton>
      </Stack>
      <Divider sx={{ opacity: 0.5 }} />
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          size="small"
          label="API Key Name"
          type="text"
          fullWidth
          variant="outlined"
          value={newKeyName}
          onChange={(e) => setNewKeyName(e.target.value)}
          required
        />
      </DialogContent>
      <DialogActions sx={{ pb: 2, px: 2 }}>
        <LoadingButton
          onClick={handleCreateApiKey}
          disabled={isCreatingApiKey}
          sx={{ height: 28, fontWeight: 'bold' }}
          variant="contained"
        >
          Create API Key
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
