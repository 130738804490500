import React, { useState, useCallback, useEffect } from 'react';
import { useGetFilesQuery, useGetSamplesQuery } from 'graphql/generated';
import { InterfaceDocumentContent } from 'components/molecules';
import { Box, Chip, Divider, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Loading, Pagination } from 'components/atoms';
import { useOrganisation } from 'use/organisation';

const DocumentAdd: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [tokenStack, setTokenStack] = useState<(string | null)[]>([null]);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const { memoryID } = useParams<{ memoryID: string }>();
  const { selectedOrganisation } = useOrganisation();
  const {
    data: filesData,
    isLoading,
    refetch,
  } = useGetFilesQuery({
    input: {
      organisationID: selectedOrganisation,
      nextToken: tokenStack[currentPage],
      limit: 20,
    },
  });

  useEffect(() => {
    if (filesData?.getFiles) {
      setTotalCount(filesData.getFiles.totalCount || 0);
      setNextToken(filesData.getFiles.nextToken || null);
    }
  }, [filesData]);

  const handlePageChange = useCallback(
    async (token: string): Promise<{ items: unknown[]; nextToken: string }> => {
      setCurrentPage((prevPage) => {
        const newPage = token === null ? 1 : prevPage + 1;
        setTokenStack((prevStack) => {
          const newStack = [...prevStack];
          newStack[newPage] = token;
          return newStack;
        });
        return newPage;
      });

      const result = await refetch();
      if (result.data?.getFiles?.data) {
        return {
          items: result.data.getFiles.data,
          nextToken: result.data.getFiles.nextToken || '',
        };
      }
      throw new Error('Failed to fetch files');
    },
    [refetch],
  );

  const { data: sampleData } = useGetSamplesQuery(
    {
      input: {
        memoryID,
        organisationID: selectedOrganisation,
      },
    },
    {
      skip: !memoryID,
    },
  );
  const samples = sampleData?.getSamples?.data;

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ py: 1 }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
              >
                <Typography variant="h6">Samples</Typography>
                <Chip
                  size="small"
                  label={`${samples?.length || 0}`}
                />
              </Stack>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ opacity: 0.3 }}
              />
            </Stack>
            <Pagination
              totalCount={totalCount}
              itemsPerPage={20}
              nextToken={nextToken}
              onPageChange={handlePageChange}
            />
          </Stack>
          <Divider sx={{ mb: 2, opacity: 0.3 }} />
          <InterfaceDocumentContent files={filesData?.getFiles?.data || []} />
        </>
      )}
    </Box>
  );
};

export default DocumentAdd;
