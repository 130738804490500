import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useGetApiKeysQuery } from 'graphql/generated';
import {
  Box,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Divider,
} from '@mui/material';
import { ApiKeys } from 'components/organisms';
import { useCreateApiKeyMutation } from 'graphql/generated';
import Layout from 'layouts/index';
import { useState } from 'react';
import { useOrganisation } from 'use/organisation';

export default function DevelopersPage() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [createApiKey] = useCreateApiKeyMutation();
  const { selectedOrganisation } = useOrganisation();
  const [loading, setLoading] = useState(false);
  const { refetch } = useGetApiKeysQuery({
    input: { organisationID: selectedOrganisation },
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await createApiKey({
        input: { name, organisationID: selectedOrganisation },
      });
      setOpen(false);
      setName('');
      await refetch();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setOpen(false);
    setName('');
  };

  return (
    <Layout
      startTitle={
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
        >
          <Typography variant="h4">API Keys</Typography>
          <Tooltip title="Create API Key">
            <IconButton onClick={() => setOpen(true)}>
              <Add />
            </IconButton>
          </Tooltip>
        </Stack>
      }
    >
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <Typography variant="h4">Create New API Key</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="API Key Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            spacing={2}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              size="small"
              sx={{ height: '24px', fontWeight: 'bold' }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <LoadingButton
              onClick={handleSubmit}
              loading={loading}
              variant="contained"
              size="small"
              sx={{ height: '24px', fontWeight: 'bold' }}
            >
              Create
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
      <Box>
        <ApiKeys />
      </Box>
    </Layout>
  );
}
