import { Box, Container } from '@mui/material';
import MiniDrawer from '../components/organisms/drawer';
import { useGetSelfQuery, useGetZendeskTokenQuery } from 'graphql/generated';
import { ModalOnboarding } from 'components/organisms';
import React from 'react';

interface LayoutProps {
  children: React.ReactNode;
  endAction?: React.ReactNode;
  startTitle?: React.ReactNode;
  backArrow?: boolean;
  backArrowPath?: string;
  middleAction?: React.ReactNode;
}

export default function Layout({
  children,
  startTitle,
  endAction,
  backArrow,
  backArrowPath,
  middleAction,
}: LayoutProps) {
  const {
    data: selfData,
    isLoading: selfLoading,
    refetch: refetchSelf,
  } = useGetSelfQuery({
    input: {},
  });
  const { data: zendeskToken } = useGetZendeskTokenQuery({
    input: {},
  });
  const availableOrgs =
    selfData?.getSelf.user?.map((ou) => ou.organisation?.id) || [];

  const refetch = async () => {
    await refetchSelf();
  };

  React.useEffect(() => {
    if (zendeskToken?.getZendeskToken?.token) {
      window?.zE(function () {
        window?.zE(
          'messenger',
          'loginUser',
          async function (callback: (token: string) => void) {
            try {
              await callback(zendeskToken?.getZendeskToken?.token);
            } catch (error) {
              console.error('Failed to get Zendesk token:', error);
            }
          },
          function (error: any) {
            console.log('Zendesk login response:', {
              success: !error,
              error: error,
              fullResponse: this,
              arguments: arguments,
            });
            if (error) {
              console.error('Zendesk login failed:', error);
            }
          },
        );
      });
    }
  }, [selfData, zendeskToken, zendeskToken?.getZendeskToken?.token]);

  return (
    <Box>
      <ModalOnboarding
        open={availableOrgs.length === 0 && !selfLoading}
        onClose={() => {
          refetch();
        }}
        disableEscapeKeyDown={true}
      />
      <MiniDrawer
        backArrow={backArrow}
        backArrowPath={backArrowPath}
        startTitle={startTitle}
        middleAction={middleAction}
        endAction={endAction}
      >
        <Container
          sx={{ mb: 4 }}
          maxWidth="xl"
        >
          {children}
        </Container>
      </MiniDrawer>
    </Box>
  );
}
