import {
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Layout from 'layouts/index';
import { useCallback, useMemo, useState } from 'react';
import { Add, SmartToy } from '@mui/icons-material';
import StudioIndex from './studio';
import StudioBots from './bots';
import { NewBot } from 'components/molecules';
import { useGetBotsQuery } from 'graphql/generated';
import { useOrganisation } from 'use/organisation';

export default function Studio() {
  const navigate = useNavigate();
  const location = useLocation();
  const [openNewBot, setOpenNewBot] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { selectedOrganisation } = useOrganisation();
  const { refetch: refetchBots } = useGetBotsQuery({
    input: {
      organisationID: selectedOrganisation,
      limit: 2,
    },
  });

  const currentTab = useMemo(() => {
    if (location.pathname.includes('/playground/bot')) return 'bot';
    return 'all';
  }, [location.pathname]);

  const handleStatusChange = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    console.log('newValue', newValue);
    if (newValue === 'bot') {
      navigate('/playground/bot');
    } else {
      navigate('/playground');
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [isCreating, setIsCreating] = useState(false);

  const handleCreate = useCallback(
    async (type?: 'bot' | 'voice') => {
      setIsCreating(true);
      try {
        if (location.pathname === '/playground' && type) {
          if (type === 'bot') {
            setOpenNewBot(true);
          }
        } else {
          if (location.pathname === '/playground/bot') {
            setOpenNewBot(true);
          }
        }
      } finally {
        setIsCreating(false);
        handleMenuClose();
      }
    },
    [location.pathname],
  );

  const getTitle = useMemo(() => {
    switch (currentTab) {
      case 'bot':
      default:
        return ' AI Playground';
    }
  }, [currentTab]);

  const getTooltipTitle = useMemo(() => {
    switch (location.pathname) {
      case '/playground':
        return 'Create';
      case '/playground/bot':
        return 'Create Bot';
      default:
        return 'Create';
    }
  }, [location.pathname]);

  return (
    <Layout
      backArrow
      startTitle={
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Typography variant="h4">Voice {getTitle}</Typography>
          <Tooltip
            arrow
            title={getTooltipTitle}
          >
            <span>
              <IconButton
                disabled={isCreating}
                onClick={
                  location.pathname === '/playground'
                    ? handleMenuClick
                    : () => handleCreate()
                }
              >
                {isCreating ? <CircularProgress size={24} /> : <Add />}
              </IconButton>
            </span>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleCreate('bot')}>
              <SmartToy
                fontSize="small"
                sx={{ mr: 1 }}
              />
              Voice Bot
            </MenuItem>
          </Menu>
        </Stack>
      }
      endAction={
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <Tabs
            value={currentTab}
            onChange={handleStatusChange}
            textColor="primary"
            indicatorColor="primary"
            sx={{ minHeight: 28 }}
          >
            <Tab
              label="Overview"
              value="all"
              sx={{
                minHeight: 28,

                px: 1.5,
                textTransform: 'none',
              }}
            />
            <Tab
              label="Voice Bots"
              value="bot"
              sx={{
                minHeight: 28,

                px: 1.5,
                textTransform: 'none',
              }}
            />
          </Tabs>
        </Stack>
      }
    >
      <NewBot
        title="New Voice Bot"
        open={openNewBot}
        setOpen={setOpenNewBot}
        onUpdate={() => {
          refetchBots();
        }}
      />

      <Routes>
        <Route
          path="/"
          element={<StudioIndex />}
        />
        <Route
          path="/bot"
          element={<StudioBots />}
        />
      </Routes>
    </Layout>
  );
}
