import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import {
  useGetMemoriesQuery,
  useGetBotQuery,
  useUpdateBotMutation,
} from 'graphql/generated';
import { RowMiniMemory } from 'components/atoms';
import { LoadingButton } from '@mui/lab';
import { getMemoryStepindex } from 'common/utils';
import { MemoryStep } from 'types/enums';
import { useParams } from 'react-router-dom';
import { useOrganisation } from 'use/organisation';

interface DialogAddMemoryProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function DialogAddMemory({
  open,
  setOpen,
}: DialogAddMemoryProps) {
  const [selectedID, setSelectedID] = useState<string | null>(null);
  const { botID } = useParams<{ botID: string }>();
  const { data: botData, refetch: refetchBot } = useGetBotQuery(
    {
      input: { id: botID },
    },
    { skip: !open, refetchOnMountOrArgChange: true },
  );

  const bot = useMemo(() => botData?.getBot?.data, [botData]);
  const { selectedOrganisation } = useOrganisation();
  const { data: memoryData } = useGetMemoriesQuery(
    {
      input: {
        organisationID: selectedOrganisation,
      },
    },
    { skip: !open, refetchOnMountOrArgChange: true },
  );

  const [updateBot, { isLoading: isUpdatingBot }] = useUpdateBotMutation();
  const memories = useMemo(() => {
    const unsortedMemories = memoryData?.getMemories.data;
    return (
      unsortedMemories
        ?.slice()
        .sort(
          (a, b) =>
            getMemoryStepindex(b.step as MemoryStep) -
            getMemoryStepindex(a.step as MemoryStep),
        ) || []
    );
  }, [memoryData]);

  const handleSelectMemory = (id: string) => {
    setSelectedID(id);
  };

  const handleSaveMemory = async () => {
    const input = {
      id: botID,
      memoryID: selectedID,
    };

    await updateBot({
      input,
    });

    await refetchBot();
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{ px: 2, py: 1 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">Add Memory</Typography>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Stack>
      <Divider />
      <Stack
        sx={{ p: 2 }}
        spacing={1}
      >
        {memories?.map((memory) => (
          <RowMiniMemory
            key={memory.id}
            selected={memory.id === bot?.memoryID || memory.id === selectedID}
            memory={memory}
            onSelect={handleSelectMemory}
          />
        ))}
      </Stack>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          px: 2,
          pb: 2,
        }}
      >
        <LoadingButton
          variant="contained"
          size="small"
          sx={{ height: 28, fontWeight: 'bold' }}
          loading={isUpdatingBot}
          onClick={handleSaveMemory}
        >
          Save
        </LoadingButton>
      </Box>
    </Dialog>
  );
}
