import {
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  Tooltip,
  Chip,
  Box,
} from '@mui/material';
import {
  useDeleteSampleMutation,
  useGetSamplesQuery,
  useUpdateSampleMutation,
} from 'graphql/generated';
import { AppFile } from 'types/app';
import { SampleType, FileType } from 'types/enums';
import {
  Layers,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';
import { UpdateSampleInput } from '@duohub/types';
import { categoryConfig } from '../category-config';
import { getStatusIcons } from '../status-icons';
import { formatDate } from 'common/utils';
import { useOrganisation } from 'use/organisation';

interface RowMiniFileProps {
  file: AppFile;
  sampleType: SampleType;
  sampleIndex: string;
}

export default function RowMiniFile({
  file,
  sampleType,
  sampleIndex,
}: RowMiniFileProps) {
  const { memoryID } = useParams<{ memoryID: string }>();
  const [updateSample, { isLoading: isLoadingUpdate }] =
    useUpdateSampleMutation();
  const { selectedOrganisation } = useOrganisation();
  const [deleteSample, { isLoading: isLoadingDelete }] =
    useDeleteSampleMutation();
  const { data: sampleData } = useGetSamplesQuery({
    input: {
      memoryID,
      type: sampleType,
    },
  });
  const samples = sampleData?.getSamples?.data;
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    setIsSelected(
      samples?.some(
        (sample) =>
          sample.fileID === file.id &&
          (sampleIndex === sample.memoryID || sampleIndex === sample.voiceID),
      ) ?? false,
    );
  }, [samples, file.id, sampleIndex]);

  const handleSelectFile = async () => {
    if (isSelected) {
      const sampleToDelete = samples?.find(
        (sample) => sample.fileID === file.id,
      );

      if (sampleToDelete) {
        try {
          await deleteSample({
            input: { id: sampleToDelete.id },
          });
        } catch (error) {
          console.error('Delete error:', error);
        }
      }
    } else {
      const input: UpdateSampleInput = {
        id: uuid(),
        fileID: file.id,
        type: sampleType,
        organisationID: selectedOrganisation,
      };
      if (sampleType === SampleType.human) {
        input.voiceID = sampleIndex;
      }
      if (sampleType === SampleType.document) {
        input.memoryID = sampleIndex;
      }

      try {
        await updateSample({
          input,
        }).unwrap();
      } catch (error) {
        console.error('Update error:', error);
      }
    }
  };

  const getCategoryIcon = (fileType: FileType) =>
    categoryConfig[fileType]?.icon || null;
  const getCategoryText = (fileType: FileType) =>
    categoryConfig[fileType]?.text || 'Unknown';

  return (
    <Card elevation={2}>
      <CardContent
        sx={{ px: 2, py: 1 }}
        style={{ paddingBottom: 1 * 8, paddingTop: 1 * 8 }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            width="15%"
          >
            {getCategoryIcon(file.fileType as FileType)}
            {file?.fileType === FileType.sitemap && (
              <Tooltip title={(file?.hiddenFilesTotal || 0) + ' pages'}>
                <Chip
                  label={file?.hiddenFilesTotal || '-'}
                  icon={<Layers />}
                  size="small"
                />
              </Tooltip>
            )}
            {file?.fileType === FileType.website_bulk && (
              <Tooltip title={(file?.hiddenFilesTotal || 0) + '- pages'}>
                <Chip
                  label={file?.hiddenFilesTotal || '-'}
                  icon={<Layers />}
                  size="small"
                />
              </Tooltip>
            )}
            <Typography variant="subtitle2">
              {getCategoryText(file.fileType as FileType)}
            </Typography>
          </Stack>
          <Stack
            width="45%"
            direction="row"
            spacing={1}
          >
            <Tooltip title={file.name}>
              <Typography
                variant="h6"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '50ch',
                }}
              >
                {file.name}
              </Typography>
            </Tooltip>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            sx={{ width: '10%' }}
          >
            {getStatusIcons(file)}
          </Stack>
          <Box width="10%">
            <Chip
              label={file?.words?.toLocaleString() || '-'}
              size="small"
            />
          </Box>
          <Typography
            variant="body2"
            width="15%"
          >
            {formatDate(file?.updatedAt)}
          </Typography>
          <IconButton
            size="small"
            onClick={handleSelectFile}
            disabled={isLoadingDelete || isLoadingUpdate}
          >
            {isLoadingDelete || isLoadingUpdate ? (
              <CircularProgress size={24} />
            ) : isSelected ? (
              <RadioButtonChecked />
            ) : (
              <RadioButtonUnchecked />
            )}
          </IconButton>
        </Stack>
      </CardContent>
    </Card>
  );
}
